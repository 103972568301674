<template>
  <section>
    <div class="row mb-3">
      <div class="col-md-12 d-flex align-items-center justify-content-between">
        <div>
          <h3 class="bis d-flex align-items-center">
            <div class="icon-title d-flex align-items-center justify-content-center">
              <icon width="30"
                height="30"
                name="wine-bottle"></icon>
            </div>
            <div>
              <div class="d-flex">
                <div>{{ wines.length }} vins inscrits en</div>
                <m-form-select :name="$Utils.randomstring('items_years')"
                  :items="$store.state.items_years"
                  style="width:110px"
                  class="ms-2 input-year"
                  :clearable="false"
                  v-model="year"></m-form-select>
                <m-form-combobox :name="$Utils.randomstring('items_centers')"
                  v-model="center"
                  :clearable="false"
                  class="ms-2 input-year"
                  style="width:400px"
                  :store-url="$config.server_url + '/backoffice/1.0/centers'"
                  item-value="ce_id"
                  :item-text="$options.filters.formatCenterName"
                  :store-params="{
                    sort: 'ce_name ASC',
                    onlymine: true,
                  }">
                </m-form-combobox>


              </div>
              <div class="fs-6 fw-light">Liste des vins</div>
            </div>
          </h3>
        </div>
        <div class="dropdown"
          v-if="($store.state.user.us_type = 'superadmin')">
          <button class="btn btn-primary dropdown-toggle ms-4"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false">
            Actions
          </button>
          <ul class="dropdown-menu"
            aria-labelledby="dropdownMenuButton1">
            <li class="w-100">
              <div @click="exportAllFichtech"
                class="dropdown-item pointer">
                Exporter toutes les fiches techniques
              </div>
            </li>
            <li class="w-100">
              <div @click="exportAllWines"
                class="dropdown-item pointer">
                Exporter tous les vins (préparation import des médailles)
              </div>
            </li>
            <li class="w-100">
              <div @click="importMedailles"
                class="dropdown-item pointer">
                Importer les médailles
              </div>
            </li>
            <li class="w-100">
              <div @click="importDetoures"
                class="dropdown-item pointer">
                Importer les images détourées
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between mb-2">
      <div class="frame w-100 me-4 ">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <div class="uppercase fw-bold">Nombre de vins</div>
            <div class="stat-subtext">validés en {{ $store.state.year }}</div>
          </div>
          <div class="stat-percent"
            :class="$store.state.stats.wines.color">
            {{ $store.state.stats.wines.diff }} %
          </div>
        </div>
        <div class="stat-chiffre">{{ $store.state.stats.wines.nb }}</div>
      </div>
      <div class="frame w-100 me-4 ">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <div class="uppercase fw-bold">Nombre de stop</div>
            <div class="stat-subtext">pour les vins validés</div>
          </div>
          <div class="stat-percent"
            :class="$store.state.stats.winesstop.color">
            {{ $store.state.stats.winesstop.diff }} %
          </div>
        </div>
        <div class="stat-chiffre">
          {{ $store.state.stats.winesstop.nb }}
        </div>
      </div>
      <div class=" w-100 me-4 "></div>
      <div class=" w-100 me-4 "></div>
    </div>
    <div class="d-flex align-items-end justify-content-between mb-2">
      <div class="d-flex align-items-end">
        <m-form-text label="Recherche textuelle"
          v-model="filterText"
          :name="$Utils.randomstring('search')"
          autocomplete
          @input="loadDelayWines()"></m-form-text>
        <m-form-checkbox class="ms-2"
          :label="getlabel('medaille')"
          :name="$Utils.randomstring('filterMedailles')"
          v-model="filterMedailles"
          @input="loadDelayWines()"></m-form-checkbox>
        <m-form-checkbox class="ms-2"
          :label="getlabel('valid')"
          :name="$Utils.randomstring('filterValid')"
          v-model="filterValid"
          @input="loadDelayWines()"></m-form-checkbox>
        <m-form-checkbox class="ms-2"
          :label="getlabel('stop')"
          :name="$Utils.randomstring('filterStop')"
          v-model="filterStop"
          @input="loadDelayWines()"></m-form-checkbox>
      </div>

      <div>
        <button :disabled="$dayjs().isAfter($store.state.yearObj.yp_results_date, 'day')
          "
          class="btn btn-primary"
          type="button"
          @click="addWine">
          Nouveau
        </button>
      </div>
    </div>
    <div class="frame">
      <m-list-simple tableClass="table-hover table-striped"
        ref="wineslist"
        :dynamic="true"
        :items="wines"
        item-value="wi_id"
        :item-height="30"
        :limit="wines_limit"
        :skip="wines_skip"
        :total="wines_total"
        :cursorOnRows="true"
        :urlExportCSV="`${this.$config.server_url}/backoffice/1.0/wines/exportcsv?token=${this.$store.state.accesstoken}&origin=${this.$config.backoffice_url}`
          "
        @itemclick="editWine">
        <template v-slot:ths="{ }">
          <th @click="sortTable(['participation.pa_society'])">
            <span class="pointer">Société</span>
          </th>
          <th @click="sortTable(['wi_name'])">
            <span class="pointer">Nom du vin</span>
          </th>
          <th @click="sortTable(['wi_denomination'])">
            <span class="pointer">Dénomination</span>
          </th>
          <th @click="sortTable(['wi_couleur'])">
            <span class="pointer">Couleur</span>
          </th>
          <th @click="sortTable(['wi_millesime'])">
            <span class="pointer">Millesime</span>
          </th>
          <th @click="sortTable(['wi_medaille'])"
            style="min-width:130px;">
            <span class="pointer">Médaille</span>
          </th>
          <th @click="sortTable(['createdAt'])">
            <span class="pointer">Ajouté le</span>
          </th>
          <th @click="sortTable(['wi_valid'])">
            <span class="pointer">Validé</span>
          </th>
          <th @click="sortTable(['invoice.in_id'])">
            <span class="pointer">Facture</span>
          </th>
          <th style="width:50px;"></th>
          <th v-if="$store.state.user.us_type === 'superadmin'"
            style="width:40px;"></th>
          <!-- <th style="width:50px;"></th> -->
        </template>
        <template v-slot:tds="{ item }">
          <td>
            {{ item.participation.pa_society }}
          </td>
          <td>
            {{ item | formatWineName }}
          </td>
          <td>
            {{
              item.denomination
              ? item.denomination.de_name
              : item.wi_denomination
            }}
          </td>
          <td>
            {{ item.wi_couleur | formatWineColor }}
          </td>
          <td>
            {{ item.wi_millesime | formatWineMillesime }}
          </td>
          <td>
            <img v-if="item.wi_medaille > 0"
              :src="`${$config.server_url
                }/commons/1.0/files/yp_file_macaron_${$options.filters.formatvalue(
                  item.wi_medaille,
                  'items_medailles',
                  'lowercase',
                  'singular'
                )}/${item.wi_year}`
                "
              alt="icon medaille"
              style="width: 18px;" />
            {{
              $options.filters.formatvalue(
                item.wi_medaille,
                "items_medailles",
                "uppercase",
                "singular"
              )
            }}
            <img v-if="item.wi_prixexcellence"
              :src="`${$config.server_url}/commons/1.0/files/yp_file_macaron_excellence/${item.wi_year}`
                "
              alt="icon medaille excellence"
              style="width: 18px;" />
          </td>
          <td>
            {{
              item.createdAt && item.createdAt !== "0000-00-00"
              ? $dayjs(item.createdAt).format("DD/MM/YYYY")
              : " - "
            }}
          </td>
          <td v-html="$options.filters.formatYesNoColored(item.wi_valid)"></td>
          <td>{{ item.invoice.in_num }}</td>
          <td>
            <div class="text-center"
              v-if="!item.wi_revendication || !item.wi_analyse">
              <img class=""
                style="width:50%"
                src="/images/icons/icon-stop.png"
                alt="icon stop" />
            </div>
          </td>

          <td v-if="$store.state.user.us_type === 'superadmin'">
            <div @click.stop="downloadFile(item)"
              v-if="item.wi_medaille > 0 && item.wi_fichetech && !item.detoure">
              <icon color="#617159"
                name="file-export"></icon>
            </div>
            <div v-if="item.detoure">
              <img class="img-fluid"
                :src="`${$config.server_url}/web/1.0/wines/${item.wi_id}/detoure`
                  "
                alt="" />
            </div>
          </td>
          <!-- <td>
            <div @click.stop="confirmDeleteWine(item)">
              <icon color="#950605" name="trash-alt"></icon>
            </div>
          </td> -->
        </template>
      </m-list-simple>
    </div>

    <wine-win-edit v-model="wineWinEdit"
      :wi_id="wineSelected.wi_id"
      @WinEditActions="WinEditActions">
    </wine-win-edit>
    <m-confirm-dialog v-model="confirmdelete"
      text="Souhaitez-vous effacer ce wine ?"
      title="Confirmation"
      @canceled="confirmdelete = false"
      @confirmed="deleteWine()"></m-confirm-dialog>
    <import-win-detoure v-model="importDetoure"
      @emitImportActions="emitImportActions"></import-win-detoure>
    <import-win-wines v-model="importWines"
      @emitImportActions="emitImportActionsWines"
      @canceled="emitImportActionsWines"></import-win-wines>
  </section>
</template>

<script>
export default {
  name: "wine",
  components: {},
  data() {
    return {
      year: this.$store.state.year,
      center: this.$store.state.center,
      wines: [],
      wines_total: 0,
      wines_limit: 1000,
      wines_skip: 0,
      wineSelected: { wi_id: -1 },
      // filters
      filterText: "",
      filterMedailles: false,
      filterValid: false,
      filterStop: false,
      delayFilter: null,
      sortOrder: "asc",
      sortFields: ["participation.pa_society, wi_name"],
      // actions
      wineWinEdit: false,
      wineToDelete: {},
      confirmdelete: false,
      // nombres
      nbFilters: {},
      // import/export
      importDetoure: false,
      importWines: false
    };
  },
  async mounted() {
    if (!this.$Utils.hasRight("canContactsAccess")) {
      this.$router.push("/");
    }
    if (this.$route.name == "winesedit")
      this.editWine({ wi_id: this.$route.params.id * 1 });
    this.loadWines(0);
  },
  watch: {
    $route(to, from) {},
    year: async function (val) {
      await this.$store.dispatch("set_year", val);
      this.loadWines();
      // this.$store.dispatch("loadStats");
      // this.$store.dispatch("loadPreferences");
    },
    center: async function (val) {
      await this.$store.dispatch("set_center", val);
      this.loadWines();
    }

  },
  computed: {
    titre() {
      let titre = this.wines_total + " Wine";
      if (this.wines_total > 1) titre += "s";
      return titre;
    }
  },
  methods: {
    getlabel(type) {
      let label = "Uniquement les ";
      if (type === "stop") label += this.nbFilters.nbStop + " stop";
      if (type === "valid") label += this.nbFilters.nbValid + " validés";
      if (type === "medaille")
        label += this.nbFilters.nbMedaille + " médaillés";
      return label;
    },
    sortTable(fields) {
      if (
        fields.length &&
        this.sortFields.length &&
        fields[0] == this.sortFields[0]
      ) {
        this.sortOrder = this.sortOrder == "asc" ? "desc" : "asc";
      }
      this.sortFields = fields;
      this.loadWines(1);
    },
    editWine(row_wi) {
      this.wineSelected = row_wi;
      this.$router.push("/wines/" + row_wi.wi_id + "/edit").catch(err => {});
      this.wineWinEdit = true;
    },
    loadDelayWines() {
      if (this.delayFilter) {
        clearTimeout(this.delayFilter);
        this.delayFilter = null;
      }
      this.delayFilter = setTimeout(() => {
        this.loadWines();
      }, 300);
    },
    async loadWines(skip = 0) {
      let sort = this.sortFields.reduce((accumulator, field) => {
        if (accumulator) accumulator += ", ";
        accumulator += field + " " + this.sortOrder;
        return accumulator;
      }, "");
      let params = {
        text: this.filterText,
        skip: skip,
        limit: this.wines_limit,
        sort: sort,
        wi_year: this.$store.state.year,
        ce_id: this.$store.state.center.ce_id
      };
      if (this.filterMedailles) params.medailles = true;
      if (this.filterValid) params.wi_valid = 1;
      if (this.filterStop) params.stop = true;
      this.cancelAxiosLoadWines && this.cancelAxiosLoadWines();
      let response = await this.$axios.get(
        this.$config.server_url + "/backoffice/1.0/wines",
        {
          params,
          cancelToken: new this.$axios.CancelToken(c => {
            this.cancelAxiosLoadWines = c;
          })
        }
      );
      if (!response || !response.data) return;
      this.wines = response.data.data;
      //this.wine_total = response.data.meta.total;
      this.wines_total = this.wines.length;
      this.wines_skip = skip;
      this.nbFilters = response.data.meta.chiffres;
      // if (this.$refs.userslist) {
      //   // this.$refs.userslist.update();
      //   // if (skip == 0) this.$refs.userslist.scrollToTop();
      // }
      this.$emit("loaded", this.wines_total);
    },
    addWine() {
      this.editWine({ wi_id: -1 });
    },
    WinEditActions(data) {
      this.$router.push("/wines").catch(err => {});
      // console.log("data.action", data.action);
      if (data.action === "saved" || data.action === "deleted")
        this.loadWines();
      this.$store.dispatch("loadStats");
    },
    downloadFile(item) {
      window.open(
        `${this.$config.server_url}/backoffice/1.0/wines/${item.wi_id}/export/fichestechniques?token=${this.$store.state.accesstoken}&origin=${this.$config.backoffice_url}`,
        "_blank"
      );
    },
    exportAllFichtech() {
      window.open(
        `${this.$config.server_url}/backoffice/1.0/wines/export/fichestechniques/${this.$store.state.year}?token=${this.$store.state.accesstoken}&origin=${this.$config.backoffice_url}`,
        "_blank"
      );
    },
    exportAllWines() {
      window.open(
        `${this.$config.server_url}/backoffice/1.0/wines/export/all/${this.$store.state.year}?token=${this.$store.state.accesstoken}&origin=${this.$config.backoffice_url}`,
        "_blank"
      );
    },
    importDetoures() {
      this.importDetoure = true;
    },
    importMedailles() {
      this.importWines = true;
    },
    emitImportActions(what) {
      this.loadWines();
    },
    emitImportActionsWines(what) {
      this.loadWines();
    },
    confirmDeleteWine(wine) {
      this.wineToDelete = wine;
      this.confirmdelete = true;
    },
    async deleteWine() {
      await this.$axios.put(
        this.$config.server_url +
        "/backoffice/1.0/wines/" +
        this.wineToDelete.wi_id,
        { wi_deleted: true }
      );
      this.wineToDelete = {};
      this.confirmdelete = false;
      this.loadWines();
      this.$store.dispatch("loadStats");
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
