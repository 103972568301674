<template>
  <div>
    <div class="row mb-2">
      <div class="d-flex justify-content-between align-items-end">
        <div>
          <h3 class="mb-0 d-flex align-items-center">
            <div class="icon-title d-flex align-items-center justify-content-center">
              <icon width="30"
                height="30"
                name="user-friends"></icon>
            </div>
            <div class="d-flex">
              <div>
                <div>
                  {{ pa_id > 0 ? row_pa.pa_society : "Création d'un candidat" }}
                </div>
                <div class="fs-6 fw-light"
                  v-if="pa_id > 0">
                  {{ $options.filters.formatContactNameSimple(row_pa, "pa_") }}
                </div>
              </div>
              <m-form-select :name="$Utils.randomstring('items_years')"
                :items="itemsYearCandidat"
                style="width:110px"
                class="ms-3 input-year"
                :clearable="false"
                v-model="year"></m-form-select>
            </div>
          </h3>
        </div>
        <div class="d-flex align-items-end">
          <div class="d-flex">
            <router-link class="nav-item"
              to="/candidats">
              <button class="btn btn-primary">Retour liste</button>
            </router-link>
            <div class="dropdown">
              <button class="btn btn-primary dropdown-toggle ms-2"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                Actions
              </button>
              <ul class="dropdown-menu"
                aria-labelledby="dropdownMenuButton1">
                <li class="w-100">
                  <div @click="sendEmail('inscription')"
                    class="dropdown-item pointer">
                    Envoyer email inscription
                  </div>
                </li>
                <li class="w-100"
                  v-if="row_pa.pa_jure">
                  <div @click="sendEmail('validation')"
                    class="dropdown-item pointer">
                    Envoyer email validation juré
                  </div>
                </li>
                <li>
                  <div @click="sendAllFacture"
                    class="dropdown-item pointer">
                    Envoyer toutes les factures
                  </div>
                </li>
              </ul>
            </div>
            <!-- <button class="btn btn-primary ms-4">Actions</button> -->
          </div>
        </div>
      </div>
    </div>
    <read-candidat-jure :row_pa="row_pa"
      @editCandidatJure="editCandidatJure"></read-candidat-jure>
    <div v-if="row_pa && row_pa.pa_candidat">
      <div class="frame"
        v-if="$dayjs().isBefore($store.state.yearObj.yp_results_date)">
        <h4>Facture en création</h4>
        <m-list-simple tableClass="table-hover table-striped"
          ref="winelist"
          :dynamic="true"
          :items="row_pa.wines"
          item-value="wi_id"
          :item-height="30"
          :skip="0"
          :total="row_pa.wines.length"
          :cursorOnRows="true"
          @itemclick="editWine">
          <template v-slot:ths="{ }">
            <th>
              <span>Ajouté le</span>
            </th>
            <th>
              <span>Nom</span>
            </th>
            <th><span>Dénomination</span></th>
            <th>
              <span>Couleur</span>
            </th>

            <th><span>Millésime</span></th>
            <th><span>Médaille</span></th>
            <th><span>Stop</span></th>
            <th></th>
          </template>
          <template v-slot:tds="{ item }">
            <td>
              {{ item.createdAt | formatDate }}
            </td>
            <td>
              {{ item | formatWineName }}
            </td>
            <td>
              {{
                item.denomination
                ? item.denomination.de_name
                : item.wi_denomination
              }}
            </td>
            <td>
              {{ item.wi_couleur | formatWineColor }}
            </td>

            <td>
              {{ item.wi_millesime | formatWineMillesime }}
            </td>
            <td>
              {{
                $options.filters.formatvalue(
                  item.wi_medaille,
                  "items_medailles",
                  "uppercase",
                  "singular"
                )
              }}
            </td>
            <td>
              <div class="me-3"
                v-if="!item.wi_revendication || !item.wi_analyse">
                <img style="width:20px"
                  src="/images/icons/icon-stop.png"
                  alt="" />
              </div>
            </td>
            <td>
              <div class="d-flex justify-content-end">
                <div @click.stop="editWine(item)"
                  class="me-3">
                  <icon name="edit"></icon>
                </div>
                <div @click.stop="deleteConfirmWine(item)">
                  <icon color="#950605"
                    name="trash-alt"></icon>
                </div>
              </div>
            </td>
          </template>
        </m-list-simple>
        <div class="d-flex justify-content-end">
          <div class="d-flex align-items-center">
            <div>Créer une facture :</div>
            <div class="ms-2">
              <button :disabled="!row_pa.wines.length"
                @click="createFactureConfirm('Chèque')"
                class="btn btn-primary btn-sm">
                par chèque
              </button>
            </div>
            <div class="ms-2">
              <button :disabled="!row_pa.wines.length"
                @click="createFactureConfirm('Virement')"
                class="btn btn-primary btn-sm">
                par virement
              </button>
            </div>
          </div>
          <div class="ms-5">
            <button @click="addWine"
              class="btn btn-primary btn-sm">
              Ajouter un vin >
            </button>
          </div>
        </div>
      </div>
      <div v-if="row_pa.pa_candidat && row_pa.invoices.length">
        <div class="frame"
          v-for="invoice in row_pa.invoices"
          :key="invoice.in_id">
          <frame-invoice :row_in="invoice"
            :row_pa="row_pa"
            :readOnly="true"
            @emitEditWine="editWine"
            @emitInvoicesAction="loadCandidat"
            @emitEditInvoice="editInvoice"
            @emitCreateAvoir="createAvoir"></frame-invoice>
        </div>
      </div>
      <div v-if="row_pa.pa_candidat && row_pa.avoir.length">
        <div class="frame"
          v-for="avoir in row_pa.avoir"
          :key="avoir.in_id">
          <frame-invoice :row_in="avoir"
            :row_pa="row_pa"
            :readOnly="true"
            @emitEditWine="editWine"
            @emitEditInvoice="editInvoice"
            @emitInvoicesAction="loadCandidat"></frame-invoice>
        </div>
      </div>
    </div>
    <m-confirm-dialog v-model="confirmFacture"
      :text="confirmFactureTxt"
      title="Confirmation"
      @canceled="confirmFacture = false"
      @confirmed="createFacture"></m-confirm-dialog>
    <m-confirm-dialog v-model="confirmdelete"
      :text="confirmdeleteTxt"
      title="Confirmation"
      @canceled="confirmdelete = false"
      @confirmed="deleteAction"></m-confirm-dialog>
    <m-message-dialog v-model="dialogErr"
      title="Erreur"
      :text="dialogErrTxt"></m-message-dialog>
    <candidat-jure-win-edit v-if="row_pa"
      v-model="candidatJureWinEdit"
      :pa_id="row_pa.pa_id"
      @WinEditAction="CandidatWinEditAction"></candidat-jure-win-edit>
    <wine-win-edit v-model="wineWinEdit"
      :wi_id="wineToEdit.wi_id"
      :row_pa="row_pa"
      @WinEditActions="WineWinEditActions">
    </wine-win-edit>
    <avoir-win-create :pa_id="row_pa.pa_id"
      :in_id="invoiceIdToEdit"
      :in_date="invoiceIdToEdit.in_date"
      v-model="avoirWinEdit"
      @WinEditActions="AvoirWinEditActions">
    </avoir-win-create>
    <invoice-avoir-win-edit v-model="invoiceWinEdit"
      :in_id="invoiceIdToEdit"
      :routeParent="'/candidats/' + row_pa.pa_id"
      @winEditAction="emitInvoicesWinEdit">
    </invoice-avoir-win-edit>
  </div>
</template>

<script>
export default {
  name: "candidatdetails",
  components: {},
  props: {},
  data() {
    return {
      year: 0,
      itemsYearCandidat: [],
      row_pa: { login: {}, pa_candidat: false, pa_jure: false },
      pa_id: 0,
      dialogErr: false,
      dialogErrTxt: "",
      confirmdelete: false,
      confirmdeleteTxt: "",
      deleteAction: {},
      wineToDelete: {},
      avoirToDelete: {},
      candidatJureWinEdit: false,
      wineWinEdit: false,
      wineToEdit: {},
      avoirWinEdit: false,
      invoiceWinEdit: false,
      invoiceIdToEdit: 0,
      confirmFactureTxt: "",
      confirmFacture: false,
      confirmFacturePaiement: ""
    };
  },
  watch: {
    year: async function (val) {
      val = val * 1;
      if (val && val > 0) {
        let v = this.$_.find(this.itemsYearCandidat, { value: val * 1 });
        if (!v) return;
        this.pa_id = v.pa_id;
        this.$router.push(`/candidats/${this.pa_id}`).catch(err => {});
        await this.loadCandidat();
        // this.$store.dispatch("loadStats");
        this.$store.dispatch("loadPreferences");
      }
      // this.loadStats();
    }
  },
  async mounted() {
    if (!this.$Utils.hasRight("canContactsAccess")) {
      this.$router.push("/");
    }
    this.pa_id = this.$route.params.id;
    await this.loadCandidat();
    if (this.$route.name === "CandidatJureWinEdit") {
      this.editCandidatJure();
    }
    if (this.$route.name === "CandidatDetailsWine") {
      this.addWine();
    }
    if (this.$route.name === "CandidatsInvoicesWinEdit") {
      // console.log("this.$route.params.in_id", this.$route.params.in_id);
      this.editInvoice(this.$route.params.in_id);
    }
  },
  methods: {
    changeCandidat(row_pa) {
      this.row_pa = row_pa;
    },
    async loadCandidat() {
      // console.log("loadCandidat");
      this.cancelAxiosLoadJures && this.cancelAxiosLoadJures();
      let response;
      /*       if (this.row_pa.pa_id && this.$store.state.year !== this.row_pa.pa_year) {
        response = await this.$axios.get(
          this.$config.server_url +
            "/backoffice/1.0/participations/year/" +
            this.$store.state.year +
            "/" +
            this.row_pa.lo_id,
          {
            cancelToken: new this.$axios.CancelToken(c => {
              this.cancelAxiosLoadJures = c;
            })
          }
        );
      } else { */
      response = await this.$axios.get(
        this.$config.server_url +
        "/backoffice/1.0/participations/" +
        this.pa_id,
        {
          cancelToken: new this.$axios.CancelToken(c => {
            this.cancelAxiosLoadJures = c;
          })
        }
      );
      /*  } */
      if (!response || !response.data) return;
      let row_pa = response.data.data;
      let invoices = row_pa.invoices.filter(row => {
        return row.in_type === "FACTURE";
      });
      let avoir = row_pa.invoices.filter(row => {
        return row.in_type === "AVOIR";
      });
      row_pa.invoices = invoices;
      row_pa.avoir = avoir;

      this.row_pa = response.data.data;
      if (!this.row_pa.login) this.row_pa.login = {};
      let itemsYearCandidat = this.row_pa.itemsYearCandidat;
      itemsYearCandidat = itemsYearCandidat.filter(year => {
        if (year.pa_id) return true;
        return false;
      });
      this.itemsYearCandidat = itemsYearCandidat;
      this.year = row_pa.pa_year;
      await this.$store.dispatch("set_year", this.year);

      // let year = this.year;
      // this.year = 0;
      // setTimeout(() => {
      //   this.year = year;
      // }, 100);
    },
    editCandidatJure() {
      this.candidatJureWinEdit = true;
      if (this.$route.name === "candidatdetails")
        this.$router
          .push("/candidats/" + this.row_pa.pa_id + "/edit")
          .catch(err => {});
      if (this.$route.name === "juredetails")
        this.$router
          .push("/jures/" + this.row_pa.pa_id + "/edit")
          .catch(err => {});
    },
    // facture en création
    addWine() {
      this.wineWinEdit = true;
      this.wineToEdit = { wi_id: -1 };
      this.$router
        .push("/candidats/" + this.pa_id + "/wines/-1")
        .catch(err => {});
    },
    deleteConfirmWine(item) {
      this.wineToDelete = item;
      this.confirmdelete = true;
      this.confirmdeleteTxt = "Voulez-vous supprimer ce vin en création ? ";
      this.deleteAction = this.deleteWine;
    },
    async deleteWine() {
      await this.$axios.delete(
        this.$config.server_url +
        "/backoffice/1.0/wines/" +
        this.wineToDelete.wi_id
      );
      this.confirmdelete = false;
      this.confirmdeleteTxt = "";
      this.deleteAction = {};
      this.wineToDelete = {};
      this.loadCandidat();
    },
    createFactureConfirm(paiement) {
      this.confirmFacturePaiement = paiement;
      this.confirmFacture = true;
      this.confirmFactureTxt = `Voulez-vous créer une facture de ${this.row_pa.wines.length} vin(s) par ${paiement} pour le candidat ${this.row_pa.pa_society} ? `;
    },
    async createFacture() {
      let data = {
        in_typepaiement: this.confirmFacturePaiement,
        pa_id: this.row_pa.pa_id
      };
      let response = await this.$axios.post(
        this.$config.server_url + "/backoffice/1.0/invoices",
        data
      );
      if (response.data.data.success) {
        this.$store.dispatch("showToast", {
          title: "Facture crée pour " + response.data.data.pa_society,
          text: "un email a été envoyé au candidat",
          color: "green"
        });
      } else {
        this.$store.dispatch("showToast", {
          title: "Création facture erreur ",
          text: response.data.data.error,
          color: "red"
        });
      }
      this.confirmFacturePaiement = "";
      this.confirmFacture = false;
      this.confirmFactureTxt = "";
      this.loadCandidat();
    },
    editWine(item) {
      this.wineWinEdit = true;
      this.wineToEdit = item;
      this.$router
        .push("/candidats/" + this.pa_id + "/wines/" + item.wi_id)
        .catch(err => {});
    },
    WineWinEditActions(data) {
      this.$router.push("/candidats/" + this.pa_id).catch(err => {});
      if (data.action === "saved") {
        this.loadCandidat();
      }
    },
    createAvoir() {
      this.$router
        .push("/candidats/" + this.row_pa.pa_id + "/avoir")
        .catch(err => {});
      this.avoirWinEdit = true;
      this.invoiceWinEdit = false;
      this.invoiceIdToEdit = 0;
    },
    AvoirWinEditActions(what) {
      if (what.action === "saved") {
        this.loadCandidat();
      }
    },
    editInvoice(id) {
      this.invoiceWinEdit = true;
      this.invoiceIdToEdit = id;
      this.$router
        .push("/candidats/" + this.row_pa.pa_id + "/invoices/" + id)
        .catch(err => {});
    },
    emitInvoicesWinEdit(what) {
      this.$router.push("/candidats/" + this.row_pa.pa_id).catch(err => {});
      this.loadCandidat();
    },

    CandidatWinEditAction(what) {
      // console.log("what", what);
      // console.log(
      //   "this.$route.name, CandidatWinEditAction : ",
      //   this.$route.name
      // );
      let route = "candidats";
      if (this.$route.name === "JureWinEdit") route = "jures";

      this.$router.push("/" + route + "/" + this.row_pa.pa_id).catch(err => {});
      if (what.action === "saved") {
        this.loadCandidat();
      }
      if (what.action === "deleted") {
        this.$router.push("/" + route).catch(err => {});
      }
    },
    async sendEmail(email) {
      // console.log("this.$route", this.$route);
      let type = "candidat";
      if (this.$route.name === "juredetails") type = "jure";
      let response = await this.$axios.get(
        this.$config.server_url +
        "/backoffice/1.0/participations/" +
        this.row_pa.pa_id +
        "/sendemail/" +
        email +
        "/" +
        type
      );
      if (response.data.data.success) {
        this.$store.dispatch("showToast", {
          title: "Juré " + response.data.data.pa_society,
          text: "L'email a bien été envoyé",
          color: "green"
        });
      } else {
        this.$store.dispatch("showToast", {
          title: "Juré " + response.data.data.pa_society,
          text: response.data.data.error,
          color: "red"
        });
      }
    },
    async sendAllFacture() {
      let response = await this.$axios.get(
        this.$config.server_url +
        "/backoffice/1.0/participations/" +
        this.pa_id +
        "/sendinvoices"
      );
      if (response.data.data.success) {
        this.$store.dispatch("showToast", {
          title: "Candidat " + response.data.data.pa_society,
          text: "Les factures ont bien été envoyées",
          color: "green"
        });
      } else {
        this.$store.dispatch("showToast", {
          title: "Candidat " + response.data.data.pa_society,
          text: response.data.data.error,
          color: "red"
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
