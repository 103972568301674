<template>
  <section class="">
    <div class="row mb-2">
      <div class="col-md-12 d-flex align-items-center justify-content-between">
        <div>
          <h3 class="bis d-flex align-items-center">
            <div class="icon-title d-flex align-items-center justify-content-center">
              <icon width="30"
                height="30"
                name="newspaper"></icon>
            </div>
            <div>
              <div class="d-flex">
                <div>Liste des {{ actualites_total }} actualités presse</div>
              </div>
              <div class="fs-6 fw-light">Toutes les actualités</div>
            </div>
          </h3>
        </div>
        <div>
          <button class="btn btn-primary"
            type="button"
            @click="addActualite">
            Nouveau
          </button>
        </div>
      </div>
    </div>
    <div class="d-flex mb-2 align-items-center">
      <m-form-text label="Recherche textuelle"
        v-model="filterText"
        :name="$Utils.randomstring('search')"
        autocomplete
        @input="loadDelayActualites()"></m-form-text>
      <m-form-select class="ms-2"
        style="width:200px;"
        label="En ligne"
        :name="$Utils.randomstring('filterOnline')"
        v-model="filterOnline"
        :items="$store.state.items_boolean"
        @input="loadDelayActualites()"></m-form-select>
    </div>
    <div class="frame">
      <m-list-simple tableClass="table-hover table-striped"
        ref="actualiteslist"
        :dynamic="true"
        :items="actualites"
        item-value="act_id"
        :item-height="30"
        :limit="actualites_limit"
        :skip="actualites_skip"
        :total="actualites_total"
        :cursorOnRows="true"
        :urlExportCSV="`${this.$config.server_url}/backoffice/1.0/actualites/exportcsv?token=${this.$store.state.accesstoken}&origin=${this.$config.backoffice_url}`
          "
        @itemclick="editActualite">
        <template v-slot:ths="{ }">
          <th @click="sortTable(['act_online'])">
            <span class="pointer">En ligne</span>
          </th>

          <th @click="sortTable(['act_date'])">
            <span class="pointer">Date affichée</span>
          </th>
          <th @click="sortTable(['act_title'])">
            <span class="pointer">Titre</span>
          </th>
          <th @click="sortTable(['act_file'])">
            <span class="pointer">Fichier</span>
          </th>
          <th @click="sortTable(['act_image'])">
            <span class="pointer">Image</span>
          </th>
          <th style="width:100px;">&nbsp;</th>
        </template>
        <template v-slot:tds="{ item }">
          <td class="align-middle"
            v-html="$options.filters.formatYesNoColored(item.act_online)"></td>
          <td class="align-middle">
            <span v-if="item.act_type == 1">{{ item.act_date }}</span>
            <span v-else>{{ item.act_datestart.substring(0, 4) }}</span>
          </td>
          <td class="align-middle">
            {{ item.act_title }}
          </td>
          <td class="align-middle">
            <a v-if="item.act_file"
              @click.stop="downloadFile(item)"
              href="">
              Télécharger
            </a>
            <span v-else>-</span>
          </td>
          <td class="align-middle">
            <img :src="`${$config.server_url}/commons/1.0/actualites/${item.act_id}/image?d=${new Date().getTime()}`
              "
              alt=""
              style="max-width:150px; max-height:50px;"
              v-if="item.act_image" />
            <span v-else>-</span>
          </td>
          <td class="align-middle">
            <div @click.stop="deleteConfirm(item)"
              v-tooltip
              data-bs-placement="left"
              title="Effacer l'actualité">
              <icon color="#950605"
                name="trash-alt"></icon>
            </div>
          </td>
        </template>
      </m-list-simple>
    </div>

    <actualite-win-edit v-model="actualiteWinEdit"
      :act_id="actualiteSelected.act_id"
      @WinEditActions="emitWinEditActions">
    </actualite-win-edit>
    <m-confirm-dialog v-model="confirmdelete"
      text="Souhaitez-vous effacer cette actualité ?"
      title="Confirmation"
      @canceled="confirmdelete = false"
      @confirmed="deleteActualite()"></m-confirm-dialog>
  </section>
</template>

<script>
export default {
  name: "actualites",
  components: {},
  data() {
    return {
      actualites: [],
      actualites_limit: 1000,
      actualites_skip: 0,
      actualiteSelected: { act_id: -1 },
      filterText: "",
      filterOnline: null,
      sortOrder: "desc",
      sortFields: ["act_datestart"],
      actualites_total: 0,
      actualiteWinEdit: false,
      delayFilter: null,
      // actions
      confirmdelete: false,
      actuToDelete: {}
    };
  },
  async mounted() {
    if (!this.$Utils.hasRight("canPresseAccess")) {
      this.$router.push("/");
    }
    if (this.$route.name == "ActualitesWinEdit")
      this.editActualite({ act_id: this.$route.params.id * 1 });
    await this.loadActualites(0);
  },
  watch: {
    $route(to, from) {}
  },
  computed: {},
  methods: {
    sortTable(fields) {
      if (
        fields.length &&
        this.sortFields.length &&
        fields[0] == this.sortFields[0]
      ) {
        this.sortOrder = this.sortOrder == "asc" ? "desc" : "asc";
      }
      this.sortFields = fields;
      this.loadActualites();
    },
    editActualite(row_act) {
      this.actualiteSelected = row_act;
      this.$router.push("/actualites/" + row_act.act_id).catch(err => {});
      this.actualiteWinEdit = true;
    },
    loadDelayActualites() {
      if (this.delayFilter) {
        clearTimeout(this.delayFilter);
        this.delayFilter = null;
      }
      this.delayFilter = setTimeout(() => {
        this.loadActualites();
      }, 300);
    },
    async loadActualites(skip = 0) {
      let sort = this.sortFields.reduce((accumulator, field) => {
        if (accumulator) accumulator += ", ";
        accumulator += field + " " + this.sortOrder;
        return accumulator;
      }, "");
      let params = {
        text: this.filterText,
        skip: skip,
        limit: this.actualites_limit,
        sort: sort
      };
      // console.log("this.filterOnline", this.filterOnline);
      if (this.filterOnline !== null) params.act_online = this.filterOnline;
      this.cancelAxiosLoadActualites && this.cancelAxiosLoadActualites();
      let response = await this.$axios.get(
        this.$config.server_url + "/backoffice/1.0/actualites",
        {
          params,
          cancelToken: new this.$axios.CancelToken(c => {
            this.cancelAxiosLoadActualites = c;
          })
        }
      );
      if (!response || !response.data) return;
      this.actualites = response.data.data;
      //this.actualite_total = response.data.meta.total;
      this.actualites_total = this.actualites.length;
      this.actualites_skip = skip;
      // if (this.$refs.userslist) {
      //   // this.$refs.userslist.update();
      //   // if (skip == 0) this.$refs.userslist.scrollToTop();
      // }
      // this.$emit("loaded", this.actualites_total);
    },
    addActualite() {
      this.editActualite({ act_id: -1 });
    },
    downloadFile(item) {
      window.open(
        `${this.$config.server_url}/web/1.0/actualites/${item.act_id}/file`,
        "_blank"
      );
    },
    deleteConfirm(item) {
      this.confirmdelete = true;
      this.actuToDelete = item;
    },
    async deleteActualite() {
      let response = await this.$axios.delete(
        this.$config.server_url +
        "/backoffice/1.0/actualites/" +
        this.actuToDelete.act_id
      );
      this.confirmdelete = false;
      this.actuToDelete = {};
      if (response.data.data.success) {
        this.$store.dispatch("showToast", {
          title: "Actualité",
          text:
            "L'article '" +
            response.data.data.act_title +
            "' a bien été supprimé",
          color: "green"
        });
        this.loadActualites();
      }
    },

    emitWinEditActions(what) {
      // console.log("what", what);
      this.$router.push("/actualites").catch(err => {});
      if (what.action === "saved" || what.action === "deleted")
        this.loadActualites();
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
