<template>
  <div class="modal"
    v-if="value"
    :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'
      "
    tabindex="-1"
    role="dialog"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable"
      role="document">
      <div class="modal-content">
        <div class="modal-header d-flex align-items-center ">
          <div class="w-100 d-flex align-items-center justify-content-between">
            <div>
              <h3 class="mb-0 d-flex align-items-center">
                <div class="icon-title d-flex align-items-center justify-content-center">
                  <icon width="30"
                    height="30"
                    name="pen-fancy"></icon>
                </div>
                <div>
                  <div>{{ in_id ? "Modifier" : "Créer" }} un avoir</div>
                  <div class="fs-6 fw-light">
                    Administrateur
                  </div>
                </div>
              </h3>
            </div>

            <div class="me-3">
              <h4>Cochez les vins que vous souhaitez passer en avoir</h4>
              <div class="fw-bold">
                Puis cliquez sur le bouton "Créer un avoir"
              </div>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <div class="frame bg-gray">
            <div class="row">
              <div class="col-md-6">
                <choice-candidat :row_pa="row_pa"
                  @emitChangeCandidat="emitChangeCandidat"
                  @emitEditCandidatJure="emitEditCandidatJure"></choice-candidat>
              </div>
              <div class="col-md-6">
                <m-form-date label="Date de l'avoir"
                  :name="$Utils.randomstring('wi_medaille')"
                  v-model="avoirDate"
                  :rules="[$Validation.mandatory]"></m-form-date>
              </div>
            </div>
          </div>
          <div v-if="row_pa && row_pa.invoices && row_pa.invoices.length">
            <div class="frame"
              v-for="invoice in row_pa.invoices"
              :key="invoice.in_id">
              <frame-invoice :createAvoir="true"
                :avoirId="in_id"
                :row_in="invoice"
                :row_pa="row_pa"
                @emitEditWine="editWine"
                @emitwinesAvoir="emitwinesAvoir"></frame-invoice>
            </div>
            <form-invoice :row="row_in"
              prefix="in_"
              :keyload="keyloadFormInvoice"
              @changeInput="emitChangeInputInvoice"></form-invoice>
          </div>
        </div>
        <div class="modal-footer d-flex flex-row">
          <!--           <button
            type="button"
            class="btn btn-outline-danger"
            :disabled="row_wi.wi_id ? false : true"
            @click="deleteConfirmWin"
          >
            Supprimer
          </button> -->
          <div class="ms-auto"></div>

          <button type="button"
            class="btn btn-secondary "
            @click="cancelWin">
            Annuler
          </button>
          <button type="button"
            class="btn btn-primary ms-2"
            @click="tryToSaveWin">
            Créer un avoir de {{ totalAvoir / 100 }}€
          </button>
        </div>
      </div>
    </div>
    <wine-win-edit v-model="wineWinEdit"
      :wi_id="wineToEdit.wi_id"
      @WinEditActions="WineWinEditActions">
    </wine-win-edit>
    <m-message-dialog v-model="dialogErr"
      title="Erreur"
      :text="dialogErrTxt"></m-message-dialog>
    <avoir-win-confirm :wines="winesToAvoir"
      :row_pa="row_pa"
      :totalAvoir="totalAvoir"
      :confirmNum="1"
      v-model="avoirConfirm"
      @canceled="avoirConfirm = false"
      @valided="saveWin1()"></avoir-win-confirm>
    <avoir-win-confirm :wines="winesToAvoir"
      :row_pa="row_pa"
      :totalAvoir="totalAvoir"
      :confirmNum="2"
      v-model="avoirConfirm2"
      @canceled="cancelAvoir"
      @confirmed="saveWin2()"></avoir-win-confirm>
  </div>
</template>

<script>
export default {
  name: "AvoirWincreate",
  components: {},
  props: {
    value: { default: false, type: Boolean },
    pa_id: Number,
    in_id: [Number, String],
    in_date: String
  },
  data() {
    return {
      avoirDate: this.$dayjs().format("YYYY-MM-DD"),
      dialogErr: false,
      dialogErrTxt: "",
      row_pa: {},
      row_in: {},
      candidatJureWinEdit: false,
      winesToAvoir: [],
      avoirConfirm: false,
      avoirConfirm2: false,
      echentillonPrice: 0,
      totalAvoir: 0,
      keyloadFormInvoice: 0,
      wineWinEdit: false,
      wineToEdit: {}
    };
  },
  watch: {
    value(v) {
      if (v) {
        this.row_pa = {};
        this.row_in = {};
        this.totalAvoir = 0;
        this.avoirConfirm = false;
        this.avoirConfirm2 = false;
        if (this.in_date) this.avoirDate = this.in_date;
        this.loadParticipation();
      }
    }
  },
  computed: {},
  created() {},
  async mounted() {
    window.addEventListener("keyup", this.onKeyup);
    let response = await this.$axios.get(
      this.$config.server_url +
      "/backoffice/1.0/yearpreferences/" +
      this.$store.state.year
    );
    this.echentillonPrice = response.data.data.yp_echantillons_price;
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },
    async loadParticipation(row_pa) {
      let id = row_pa ? row_pa.pa_id : this.pa_id;
      if (!id) return;
      let params = {};
      let response = await this.$axios.get(
        this.$config.server_url + "/backoffice/1.0/participations/" + id,
        { params }
      );
      let row_pa2 = response.data.data;
      row_pa2.invoices = row_pa2.invoices.filter(row => {
        return row.in_type === "FACTURE";
      });
      /*       row_pa2.invoices.map(invoice => {
        if (!invoice.in_id_avoir) invoice.in_id_avoir = 0;
      });
      console.log("row_pa2.invoices", row_pa2.invoices); */
      this.row_pa = row_pa2;
    },
    emitChangeCandidat(row_pa) {
      // console.log("row_pa", row_pa);
      // this.pa_id = row_pa.pa_id;
      // console.log("this.pa_id", this.pa_id);
      // if (!this.row_pa)
      this.loadParticipation(row_pa);
    },
    emitEditCandidatJure() {
      this.candidatJureWinEdit = true;
    },
    emitChangeInputInvoice(row_in) {
      // console.log("row_in", row_in);
      this.row_in = row_in;
      if (this.row_in.in_price_ht) this.totalAvoir = this.row_in.in_price_ht;
    },
    editWine(item) {
      this.wineWinEdit = true;
      this.wineToEdit = item;
    },
    WineWinEditActions(what) {
      if (what.action === "saved" || what.action === "deleted") {
        this.loadParticipation();
      }
    },
    emitwinesAvoir(wines) {
      this.winesToAvoir = wines;
      this.totalAvoir = wines.length * this.echentillonPrice;
      if (this.row_in.in_price_ht) this.totalAvoir = this.row_in.in_price_ht;
      //else this.row_in.in_price_ht = wines.length * this.echentillonPrice;
      this.keyloadFormInvoice++;
    },
    tryToSaveWin() {
      let err = [];
      // console.log("this.row_Pa", this.row_pa);
      if (!this.row_pa && !this.row_pa.pa_id)
        err.push("Vous devez choisir un candidat");
      if (!this.avoirDate) err.push("Le champ date est obligatoire");
      if (!this.in_id && !this.winesToAvoir.length)
        err.push("Vous devez sélectionner au moins un vin");

      if (err.length) {
        this.dialogErrTxt = "";
        for (let ierr = 0; ierr < err.length; ierr++) {
          const error = err[ierr];
          this.dialogErrTxt += error + " <br>";
        }
        this.dialogErr = true;
        return;
      }
      this.avoirConfirm = true;
    },
    cancelAvoir() {
      this.avoirConfirm2 = false;
      this.avoirConfirm = false;
    },
    async saveWin1() {
      this.avoirConfirm2 = true;
    },
    async saveWin2() {
      let ids = [];
      for (let iwi = 0; iwi < this.winesToAvoir.length; iwi++) {
        const row_wi = this.winesToAvoir[iwi];
        ids.push(row_wi.wi_id);
      }
      let invoice = {
        participation: this.row_pa,
        wi_ids: ids,
        in_date: this.avoirDate,
        in_price_ht: this.totalAvoir,
        in_payed: this.row_in.in_payed ? this.row_in.in_payed : false,
        in_typepaiement: this.row_in.in_typepaiement,
        in_auto: this.row_in.in_auto
      };
      let response;
      if (this.in_id) {
        response = await this.$axios.put(
          this.$config.server_url + "/backoffice/1.0/invoices/" + this.in_id,
          invoice
        );
      } else {
        response = await this.$axios.post(
          this.$config.server_url + "/backoffice/1.0/invoices/avoir",
          invoice
        );
      }

      if (response.data.err) {
        this.$store.dispatch("showDialogError", response.data.err.message);
        return;
      }

      this.$emit("input", false);
      this.$emit("WinEditActions", { action: "saved" });
    },
    cancelWin() {
      this.$emit("input", false);
    }
  }
};
</script>

<style scoped lang="scss">.modal-dialog {
  .modal-content {
    background-color: #eceff0;
  }

  .modal-header,
  .modal-footer {
    border: 0;
  }
}
</style>
