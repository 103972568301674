<template>
  <div>
    <div class="d-flex justify-content-between">
      <div>
        <h4>Candidat</h4>
      </div>
      <div>
        <button @click="editCandidatJure"
          class="btn btn-primary btn-sm"
          :disabled="!paSeleted.pa_id">
          Modifier
        </button>
      </div>
    </div>
    <div>
      <m-form-combobox v-model="paSeleted"
        label="Candidat"
        :store-url="$config.server_url + '/backoffice/1.0/participations'"
        item-value="pa_id"
        :item-text="$options.filters.formatContactSociety"
        :store-params="{
          pa_candidat: true,
          pa_year: $store.state.year,
          sort: 'pa_society ASC',
          medailles: medailles
        }"
        :item-text-params="'pa_'"></m-form-combobox>
      <!-- ajouter l'année (pa_year) dans le combobox -->
    </div>
    <div class="row mt-4"
      v-if="row_pa2">
      <div class="col-md-6">
        <h5>Entreprise</h5>
        <div>{{ row_pa2.pa_society }}</div>
        <div v-html="$options.filters.formatAddress(row_pa2, 'pa_')"></div>
        <div>
          <a :href="row_pa2.pa_web">{{ row_pa2.pa_web }}</a>
        </div>
      </div>

      <div class="col-md-6">
        <h5>Contact</h5>
        <div>
          {{ $options.filters.formatContactNameSimple(row_pa2, "pa_", true) }}
        </div>
        <div v-if="row_pa2.pa_fonction">
          Fonction : {{ row_pa2.pa_fonction }}
        </div>
        <div v-if="row_pa2.pa_phone_fix">
          Tél fixe : {{ row_pa2.pa_phone_fix }}
        </div>
        <div v-if="row_pa2.pa_phone_mobile">
          Portable : {{ row_pa2.pa_phone_mobile }}
        </div>
        <div v-if="row_pa2.lo_login">
          Email :
          <span v-html="$options.filters.formatEmail(row_pa2.lo_login)"></span>
        </div>
        <div v-if="row_pa2.login && row_pa2.login.lo_login">
          Email :
          <span v-html="$options.filters.formatEmail(row_pa2.login.lo_login)"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "ChoiceCandidat",
  components: {},
  props: {
    row_pa: Object,
    keyload: Number,
    medailles: {
      default: function () {
        return false;
      },
      type: Boolean
    }
  },
  data() {
    return {
      paSeleted: {},
      row_pa2: { login: {} }
    };
  },
  watch: {
    keyload: function (v) {},
    row_pa: function (v) {
      // console.log("row_pa2", v);
      if (!v.login) v.login = {};
      this.row_pa2 = v;
      this.paSeleted = v;
    },
    paSeleted: function (v) {
      if (v.pa_id != this.row_pa2.pa_id) {
        this.row_pa2 = v;
        this.$emit("emitChangeCandidat", v);
      }
    }
  },
  async mounted() {
    if (!this.row_pa.login) this.row_pa.login = {};
    this.row_pa2 = this.row_pa;
    this.paSeleted = this.row_pa;
    // console.log("this.row_pa2", this.row_pa2);
  },
  methods: {
    ChangeCandidat() {},
    editCandidatJure() {
      this.$emit("emitEditCandidatJure");
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
