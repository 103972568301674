<template>
  <div class="modal"
    v-if="value"
    :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'
      "
    tabindex="-1"
    role="dialog"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable"
      role="document">
      <div class="modal-content">
        <div class="modal-header d-flex flex-row align-items-center">
          <div>
            <h3 class="modal-title"
              v-if="ce_id > 0">
              Modification fiche interprofession
            </h3>
            <h3 class="modal-title"
              v-if="ce_id === -1">
              Création d'une fiche interprofession
            </h3>
            <p>Seuls les champs avec une astérisque sont obligatoires</p>
          </div>
          <div class="d-flex flex-row relative">
            <div @click="changeAvatar"
              class="container-avatar pointer"
              :style="styleAvatar"
              v-tooltip
              data-bs-placement="left"
              title="cliquez pour modifier votre avatar"></div>
            <input type="file"
              ref="inputImageAvatar"
              hidden
              name="inputImageAvatar"
              accept="image/png, image/jpeg"
              @change="fileJusteSelected($event)" />
            <div v-if="row_ce.ce_avatar"
              class="delete-avatar pointer d-flex justify-centent-center align-items-center"
              @click="deleteAvatar">
              <icon color="#fff"
                name="times"></icon>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="row mb-2">
                <div class="col-md-6">
                  <m-form-text label="Nom interprofession"
                    type="text"
                    v-model="row_ce.ce_name"
                    :name="$Utils.randomstring('ce_name')"></m-form-text>
                  <m-form-text label="Nom interprofession court"
                    type="text"
                    v-model="row_ce.ce_nameshort"
                    :name="$Utils.randomstring('ce_nameshort')"></m-form-text>
                  <m-form-text label="Téléphone"
                    type="phone"
                    v-model="row_ce.ce_phone"
                    :name="$Utils.randomstring('ce_phone')"
                    @keyup="oninputPhoneMobile($event)"></m-form-text>
                  <m-form-text label="eMail *"
                    type="mail"
                    v-model="row_ce.ce_email"
                    :name="$Utils.randomstring('ce_email')"
                    :rules="[$Validation.mandatory]"></m-form-text>
                </div>
                <div class="col-md-6">
                  <div class="bg-light p-2">
                    <m-form-text label="Adresse 1"
                      type="text"
                      v-model="row_ce.ce_address1"
                      :name="$Utils.randomstring('ce_address1')"></m-form-text>
                    <m-form-text class="mt-1 mb-2"
                      type="text"
                      v-model="row_ce.ce_address2"
                      :name="$Utils.randomstring('ce_address2')"></m-form-text>
                    <m-form-text class="mt-1 mb-2"
                      type="text"
                      v-model="row_ce.ce_address3"
                      :name="$Utils.randomstring('ce_address3')"></m-form-text>
                    <div class="row mb-2">
                      <div class="col-md-6">
                        <m-form-text label="Code postal"
                          type="text"
                          v-model="row_ce.ce_zip"
                          :name="$Utils.randomstring('ce_zip')"></m-form-text>
                      </div>
                      <div class="col-md-6">
                        <m-form-text label="Ville"
                          type="text"
                          v-model="row_ce.ce_city"
                          :name="$Utils.randomstring('ce_city')"></m-form-text>
                      </div>
                    </div>
                  </div>
                  <m-form-text label="Remarque"
                    type="textarea"
                    class="mt-2"
                    v-model="row_ce.ce_comment"
                    :name="$Utils.randomstring('ce_comment')"></m-form-text>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex flex-row">
          <button type="button"
            class="btn btn-outline-danger"
            @click="deleteConfirmWin">
            Supprimer
          </button>
          <div class="ms-auto"></div>

          <div class="form-check">
            <label class="form-check-label"
              for="ce_active">Interprofession active
              <input class="form-check-input"
                type="checkbox"
                name="ce_active"
                id="ce_active"
                v-model="row_ce.ce_active" /></label>
          </div>

          <button type="button"
            class="btn btn-secondary "
            @click="cancelWin">
            Annuler
          </button>
          <button type="button"
            class="btn btn-primary ms-2"
            @click="tryToSaveWin">
            Enregistrer
          </button>
        </div>
      </div>
    </div>
    <m-confirm-dialog v-model="confirmdelete"
      text="Souhaitez-vous effacer cet interprofession ?"
      title="Confirmation"
      @canceled="confirmdelete = false"
      @confirmed="deleteWin()"></m-confirm-dialog>
    <m-message-dialog v-model="dialogErr"
      title="Erreur"
      :text="dialogErrTxt"></m-message-dialog>
  </div>
</template>

<script>
export default {
  name: "CenterWinEdit",
  components: {},
  props: {
    ce_id: Number,
    value: { default: false, type: Boolean }
  },
  data() {
    return {
      dialogErr: false,
      dialogErrTxt: "",
      rulesPassword: {
        nbCharacteres: 8,
        specialCharactere: true,
        uppercase: true,
        number: true
      },
      confirmdelete: false,
      row_ce: { ce_rights: {}, ce_type: "" },
      password1: "",
      errPassword: [],
      /// avatar
      avatarUrl: "",
      styleAvatar: "",
      fileSelected: { image: null, binary: null }
    };
  },
  watch: {
    value(v) {
      if (v) {
        this.fileSelected = { image: null, binary: null };
        this.getContact();
      }
    },
    avatarUrl(val) {
      if (this.fileSelected.image) {
        this.styleAvatar =
          "background-image:url('" + this.fileSelected.binary + "')";
      } else {
        this.styleAvatar = "background-image:url('" + val + "')";
      }
    }
  },
  computed: {
    // coolCoType() {
    //   return this.$_.result(
    //     this.$_.find(this.$store.state.items_rights_roles, {
    //       riro_id: this.row_ce.ce_type
    //     }),
    //     "riro_label",
    //     ""
    //   );
    // },
    // displayAcl() {
    //   let ret = [];
    //   this.$_.each(this.$store.state.items_rights_resources, (val, key) => {
    //     if (val.rire_id.indexOf("canSeeProduct_") == 0) {
    //       let f = this.$_.find(this.$store.state.items_rights_rolesresources, {
    //         rire_id: val.rire_id,
    //         riro_id: this.row_ce.ce_type
    //       });
    //       if (f) {
    //         let s = val.rire_id.split("_");
    //         ret.push(`${s[1]} - ${s[2]}`);
    //       }
    //     }
    //   });
    //   return ret;
    // },
    disabledType() {
      return false;
    }

    /**
     * défini de manière dinamyque les types  à afficher dans  le select type d'utilisateur
     * en fonction  de la route, va chercher les types corespondants renseignés  dans le  store
     */
    // itemsTypes() {
    //   let itemsTypes = this.$store.state.items_rights_roles
    //     .map(item => {
    //       return { value: item.riro_id, text: item.riro_label };
    //     })
    //     .filter(item => {
    //       let ok = false,
    //         centerType = this.$store.state.center.ce_type;
    //       if (centerType == "superadmin" || centerType == "admin") ok = true;
    //       if (centerType == item.value) ok = true;
    //       return ok;
    //     });
    //   return itemsTypes;
    // }
  },

  created() {},
  mounted() {
    this.contactType = this.$route.meta.type;
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },
    oninputPhoneMobile(evt) {
      if (evt.key === "Backspace" || evt.key === "Delete") return;
      let phone = this.$Utils.formatedPhone(this.row_ce.ce_phone);
      this.row_ce.ce_phone = phone;
      // this.$forceUpdate();
    },

    _hasRight(row_rire) {
      if (this.row_ce.ce_rights[row_rire.rire_id] === -1) return false;
      if (this.row_ce.ce_rights[row_rire.rire_id] === 1) return true;
      return !!this.$_.find(this.$store.state.items_rights_rolesresources, {
        rire_id: row_rire.rire_id,
        riro_id: this.row_ce.ce_type
      });
    },
    rightCalculated(row_rire) {
      if (this._hasRight(row_rire))
        return "<span class='text-success'>OUI</span>";
      return "<span class='text-danger'>NON</span>";
    },
    /*     classRightCalculated(row_rire) {
      if (this._hasRight(row_rire)) return "bg-success";
      return "bg-danger";
    }, */

    async getContact() {
      let params = { getacl: true };
      if (this.ce_id == -1) params = {};
      let response = await this.$axios.get(
        this.$config.server_url + "/backoffice/1.0/centers/" + this.ce_id,
        { params }
      );
      let row_ce = response.data.data;
      this.password1 = "";
      row_ce.ce_password = "";
      //this.avatarUrl = `${this.$config.server_url}/uploads${row_ce.ce_avatar}`;
      this.avatarUrl = `${this.$config.server_url}/backoffice/1.0/centers/${this.ce_id}/avatar?token=${this.$store.state.accesstoken}&origin=${this.$config.backoffice_url}`;

      // formater le téléphone joli
      if (this.row_ce.ce_phone)
        row_ce.ce_phone = this.$Utils.addSpaceToMobile(row_ce.ce_phone);

      if (!row_ce.ce_id) {
        row_ce.ce_type = this.$route.meta.contactsType;
      }
      if (!row_ce.ce_rights) row_ce.ce_rights = {};
      for (
        let iRe = 0;
        iRe < this.$store.state.items_rights_resources.length;
        iRe++
      ) {
        const ressource = this.$store.state.items_rights_resources[iRe];
        if (!row_ce.ce_rights[ressource.rire_id])
          row_ce.ce_rights[ressource.rire_id] = 0;
      }
      this.row_ce = row_ce;
    },

    tryToSaveWin() {
      let err = [];
      let fieldRequired = [
        { field: "ce_name", text: "Nom" },
        { field: "ce_address1", text: "Adresse 1" },
        { field: "ce_zip", text: "CP" },
        { field: "ce_city", text: "Ville" }
      ];
      for (let ifi = 0; ifi < fieldRequired.length; ifi++) {
        const field = fieldRequired[ifi];
        if (!this.row_ce[field.field]) err.push(field);
      }
      if (err.length) {
        this.dialogErrTxt =
          "<span class='bis'>Les champs suivants sont obligatoires : </span><br>";
        for (let ierr = 0; ierr < err.length; ierr++) {
          const error = err[ierr];
          this.dialogErrTxt += error.text + " <br>";
        }
        this.dialogErr = true;
        return;
      }
      this.saveWin();
    },
    trimFields() {
      let tabField = [
        "ce_name",
        "ce_email",
        "ce_address1",
        "ce_address2",
        "ce_zip",
        "ce_city"
      ];
      for (let i = 0; i < tabField.length; i++) {
        const field = tabField[i];
        this.row_ce[field] = this.row_ce[field].trim();
      }
    },
    async saveWin() {
      this.trimFields();
      // reformater le téléphone mobile
      if (this.row_ce.ce_phone)
        this.row_ce.ce_phone = this.$Utils.removeSpaceToMobile(
          this.row_ce.ce_phone
        );
      // console.log("this.row_ce", this.row_ce);

      let response;
      if (this.row_ce.ce_id) {
        response = await this.$axios.put(
          this.$config.server_url +
          "/backoffice/1.0/centers/" +
          this.row_ce.ce_id,
          this.row_ce
        );
      } else {
        this.row_ce.ce_cgu_date = "0000-00-00 00:00:00";
        response = await this.$axios.post(
          this.$config.server_url + "/backoffice/1.0/centers",
          this.row_ce
        );
      }
      if (response.data.err) {
        this.$store.dispatch("showDialogError", response.data.err.message);
        return;
      }
      let row_ce = response.data.data;
      if (this.fileSelected.image) {
        await this.saveImage(this.fileSelected.image, row_ce.ce_id);
      }
      // console.log("row_ce", row_ce);
      if (
        this.$store.state.center &&
        row_ce.ce_id == this.$store.state.center.ce_id
      ) {
        this.$store.commit("set_center_simple", row_ce);
      }
      this.$emit("input", false);
      this.$emit("saved", row_ce ? row_ce.ce_id : 0);
    },
    cancelWin() {
      this.$emit("input", false);
      this.$emit("canceled", this.row_ce.ce_id);
    },
    deleteConfirmWin() {
      this.confirmdelete = true;
    },
    async deleteWin() {
      let ce_id = this.row_ce.ce_id;
      await this.$axios.delete(
        this.$config.server_url + "/backoffice/1.0/centers/" + ce_id
      );
      this.confirmdelete = false;
      // this.dialog = false;
      this.$emit("input", false);
      this.$emit("deleted", 0);
    },
    /// avatar
    async deleteAvatar() {
      this.fileSelected = { image: null, binary: null };
      this.avatarUrl = "";
      this.row_ce.ce_avatar = "";
      let row_ce = await this.$axios.delete(
        this.$config.server_url +
        "/backoffice/1.0/centers/" +
        this.ce_id +
        "/avatar"
      );
      this.avatarUrl = `${this.$config.server_url}/backoffice/1.0/centers/${this.ce_id}/avatar?token=${this.$store.state.accesstoken}&origin=${this.$config.backoffice_url}`;
      this.$store.commit("set_reloadAvatar", row_ce);
    },
    changeAvatar() {
      this.$refs.inputImageAvatar.click();
    },
    fileJusteSelected($event) {
      // console.log("$event", $event);
      if (!$event.target.files.length) return;

      this.fileSelected.image = $event.target.files[0];
      var reader = new FileReader();
      var me = this;
      reader.onload = (function (theFile) {
        return function (e) {
          me.fileSelected.binary = e.target.result;
          me.avatarUrl = e.target.result;
        };
      })($event.target.files[0]);
      reader.readAsDataURL($event.target.files[0]);
    },
    async saveImage(file, ce_id) {
      if (!file) return;
      let formData = new FormData();
      formData.append("image", file, file.name);
      let row_ce = await this.$axios.post(
        this.$config.server_url +
        "/backoffice/1.0/centers/" +
        this.ce_id +
        "/avatar",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
      this.fileSelected = { image: null, binary: null };
      this.$store.commit("set_reloadAvatar", row_ce);
    }
  }
};
</script>

<style scoped lang="scss">
.modal {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.delete-avatar {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 25px;
  height: 25px;
  background-color: #c8bfbf;
  box-shadow: 0 0 2px 0 grey;
  border-radius: 50%;
  padding: 7px;
}
</style>
