<template>
  <div>
    <div class="row mb-2">
      <div class="d-flex justify-content-between align-items-end">
        <div>
          <h3 class="mb-0 d-flex align-items-center">
            <div class="icon-title d-flex align-items-center justify-content-center">
              <icon width="30"
                height="30"
                name="shopping-cart"></icon>
            </div>
            <div>
              <div>Commande n°{{ row_or.or_num }}</div>
              <div class="fs-6 fw-light">
                visualisation d'une commande
              </div>
            </div>
          </h3>
        </div>
        <div class="d-flex align-items-end">
          <div>
            <!--  select état de la commande -->
            <m-form-select style="width:300px;"
              label="État de la commande"
              :name="$Utils.randomstring('or_state')"
              :items="$store.state.items_stateOrder"
              v-model="row_or.or_state"></m-form-select>
          </div>
        </div>
      </div>
    </div>
    <read-order-macaron :row_pa="row_pa"
      :row_or="row_or"
      @editCandidatJure="editCandidatJure"></read-order-macaron>
    <div>
      <div class="frame">
        <h4>La commande</h4>
        <p>Voici la liste macarons que vous avez commandés</p>
        <!-- <wines-list-macaron
          ref="winesListMacaron"
          :readonly="true"
          :row_or="row_or"
        ></wines-list-macaron> -->
        <!-- <m-list-simple
          tableClass="table-hover table-striped"
          ref="orderlineslist"
          :dynamic="true"
          :items="row_or.orderlines"
          item-value="orl_id"
          :item-height="50"
          :total="row_or.orderlines.length"
        >
          <template v-slot:ths="{}"> </template>
          <template v-slot:tds="{ item }">
            <td class="d-flex align-items-center">
              <div class="p-2">
                <img
                  class="img-fluid img-macaron"
                  :src="
                    `${$config.server_url}/backoffice/1.0/files/${item.wine
                      .wi_medaille + 1}/${row_or.or_year}?token=${
                      $store.state.accesstoken
                    }&origin=${$config.backoffice_url}`
                  "
                  alt="image macaron"
                />
              </div>
              <div class="ms-3">
                <div>{{ item.wine | formatWineName }}</div>
                <h5>
                  <span>{{ item.wine.denomination.de_name }} -</span>
                  <span>{{ item.wine.wi_couleur | formatWineColor }} -</span>
                  <span>{{ item.wine.wi_millesime }}</span>
                </h5>
                <div>
                  Vous avez déclaré :
                  <div
                    v-for="(contenant, index) in item.wine.contenants"
                    :key="index"
                  >
                    {{ contenant.nombre }} x {{ contenant.contenant / 100 }} cl
                  </div>
                </div>
              </div>
            </td>

            <td class="align-middle">
              Macarons
              {{
                $options.filters.formatvalue(
                  item.wine.wi_medaille,
                  "items_medailles",
                  "uppercase",
                  "singular"
                )
              }}
              : {{ item.orl_quantity }}
            </td>
          </template>
        </m-list-simple> -->
      </div>
      <form-invoice :row="row_or"
        prefix="or_"
        @changeInput="emitChangeInputPaiement"></form-invoice>
      <div class="d-flex mt-4">
        <button type="button"
          class="btn btn-danger"
          @click="deleteConfirmWin">
          Supprimer
        </button>
        <div class="ms-auto"></div>

        <button type="button"
          class="btn btn-secondary ">
          Annuler
        </button>
        <button type="button"
          class="btn btn-primary ms-2">
          Valider
        </button>
      </div>
    </div>
    <!--  <m-confirm-dialog
      v-model="confirmFacture"
      :text="confirmFactureTxt"
      title="Confirmation"
      @canceled="confirmFacture = false"
      @confirmed="createFacture"
    ></m-confirm-dialog> -->
    <m-confirm-dialog v-model="confirmdelete"
      text="Souhaitez-vous supprimer cette commande ? "
      title="Confirmation"
      @canceled="confirmdelete = false"
      @confirmed="deleteOrder"></m-confirm-dialog>
    <m-message-dialog v-model="dialogErr"
      title="Erreur"
      :text="dialogErrTxt"></m-message-dialog>
    <candidat-jure-win-edit v-if="row_pa"
      v-model="candidatJureWinEdit"
      :pa_id="row_pa.pa_id"
      @WinEditAction="CandidatWinEditAction"></candidat-jure-win-edit>
    <!--     <wine-win-edit
      v-model="wineWinEdit"
      :wi_id="wineToEdit.wi_id"
      :row_pa="row_pa"
      @WinEditActions="WineWinEditActions"
    >
    </wine-win-edit> -->
    <!--     <avoir-win-create
      :pa_id="row_pa.pa_id"
      :in_id="invoiceIdToEdit"
      :in_date="invoiceIdToEdit.in_date"
      v-model="avoirWinEdit"
      @WinEditActions="AvoirWinEditActions"
    >
    </avoir-win-create>
    <invoice-avoir-win-edit
      v-model="invoiceWinEdit"
      :in_id="invoiceIdToEdit"
      :routeParent="'/candidats/' + row_pa.pa_id"
      @winEditAction="emitInvoicesWinEdit"
    >
    </invoice-avoir-win-edit> -->
  </div>
</template>

<script>
export default {
  name: "orderdetails",
  components: {},
  props: {},
  data() {
    return {
      row_or: {},
      row_pa: {},
      or_id: 0,
      dialogErr: false,
      dialogErrTxt: "",
      confirmdelete: false,
      confirmdeleteTxt: "",
      //wineToDelete: {},
      // avoirToDelete: {},
      candidatJureWinEdit: false
      //wineWinEdit: false,
      // wineToEdit: {},
      // avoirWinEdit: false,
      //invoiceWinEdit: false,
      // invoiceIdToEdit: 0,
      //confirmFactureTxt: "",
      //confirmFacture: false,
      //confirmFacturePaiement: ""
    };
  },
  watch: {
    /*     $route(to, from) {
      this.pa_id = to.params.id;
      this.loadOrder();
    } */
  },
  async mounted() {
    this.or_id = this.$route.params.id;
    await this.loadOrder();
    /*     if (this.$route.name === "CandidatJureWinEdit") {
      this.editCandidatJure();
    } */
  },
  methods: {
    /*     changeCandidat(row_pa) {
      this.row_pa = row_pa;
    }, */
    async loadOrder() {
      this.cancelAxiosLoadJures && this.cancelAxiosLoadJures();
      let response = await this.$axios.get(
        this.$config.server_url + "/backoffice/1.0/orders/" + this.or_id,
        {
          cancelToken: new this.$axios.CancelToken(c => {
            this.cancelAxiosLoadJures = c;
          })
        }
      );
      if (!response || !response.data) return;
      let row_or = response.data.data;
      // contenances
      for (let iwi = 0; iwi < row_or.orderlines.length; iwi++) {
        const orl = row_or.orderlines[iwi];
        orl.wine.contenants = [];
        if (orl.wine.wi_contenances) {
          let tabC = orl.wine.wi_contenances.split(";;");
          for (let i = 0; i < tabC.length; i++) {
            const el = tabC[i];
            if (el.length) {
              let tab2 = el.split(":");
              orl.wine.contenants.push({
                contenant: parseInt(tab2[0]),
                nombre: parseInt(tab2[1])
              });
            }
          }
        }
      }
      // console.log("row_or", row_or);
      this.row_or = row_or;
      this.row_pa = this.row_or.participation;
    },
    editCandidatJure() {
      this.candidatJureWinEdit = true;
      this.$router
        .push("/orders/candidats/" + this.row_pa.pa_id + "/edit")
        .catch(err => {});
    },
    CandidatWinEditAction(what) {
      // console.log("what", what);
      // console.log(
      //   "this.$route.name, CandidatWinEditAction : ",
      //   this.$route.name
      // );
      this.$router
        .push("/orders/" + this.row_pa.pa_id + "/details")
        .catch(err => {});
      if (what.action === "saved") {
        this.loadOrder();
      }
      if (what.action === "deleted") {
        this.$router.push("/orders").catch(err => {});
      }
    },
    emitChangeInputPaiement(row_or) {
      this.row_or = row_or;
    },
    async sendEmail(email) {
      // console.log("this.$route", this.$route);
      let type = "candidat";
      if (this.$route.name === "juredetails") type = "jure";
      let response = await this.$axios.get(
        this.$config.server_url +
        "/backoffice/1.0/participations/" +
        this.row_pa.pa_id +
        "/sendemail/" +
        email +
        "/" +
        type
      );
      if (response.data.data.success) {
        this.$store.dispatch("showToast", {
          title: "Juré " + response.data.data.pa_society,
          text: "L'email a bien été envoyé",
          color: "green"
        });
      } else {
        this.$store.dispatch("showToast", {
          title: "Juré " + response.data.data.pa_society,
          text: response.data.data.error,
          color: "red"
        });
      }
    },
    deleteConfirmWin() {
      this.confirmdelete = true;
    },
    async deleteOrder() {
      await this.$axios.delete(
        this.$config.server_url + "/backoffice/1.0/orders/" + this.row_or.or_id
      );
      this.confirmdelete = false;
      this.$router.push("/orders").catch(err => {});
    }
    /*     async sendAllFacture() {
      let response = await this.$axios.get(
        this.$config.server_url +
          "/backoffice/1.0/participations/" +
          this.pa_id +
          "/sendinvoices"
      );
      if (response.data.data.success) {
        this.$store.dispatch("showToast", {
          title: "Candidat " + response.data.data.pa_society,
          text: "Les factures ont bien été envoyées",
          color: "green"
        });
      } else {
        this.$store.dispatch("showToast", {
          title: "Candidat " + response.data.data.pa_society,
          text: response.data.data.error,
          color: "red"
        });
      }
    } */
  }
};
</script>

<style lang="scss" scoped>
</style>
