import Vue from "vue";
// import jQuery from "jquery";

//*****// icons projet de base //*****//
//// dans les composants
import "vue-awesome/icons/eye";
import "vue-awesome/icons/eye-slash";
import "vue-awesome/icons/ellipsis-v";
import "vue-awesome/icons/arrow-left";
import "vue-awesome/icons/arrow-down";
import "vue-awesome/icons/arrow-right";
import "vue-awesome/icons/bold";
import "vue-awesome/icons/italic";
import "vue-awesome/icons/times";
//// dans app.js
import "vue-awesome/icons/question-circle";
import "vue-awesome/icons/bars";
import "vue-awesome/icons/home";
import "vue-awesome/icons/cog";
import "vue-awesome/icons/user-friends";
import "vue-awesome/icons/mail-bulk";
import "vue-awesome/icons/sign-out-alt";
import "vue-awesome/icons/shopping-cart";
import "vue-awesome/icons/calendar-alt";
import "vue-awesome/icons/newspaper";
//// dans les views
import "vue-awesome/icons/edit";
import "vue-awesome/icons/trash-alt";
import "vue-awesome/icons/plus";
import "vue-awesome/icons/fire";
import "vue-awesome/icons/user-check";
import "vue-awesome/icons/upload";
import "vue-awesome/icons/download";
//*****// icons projet concours //*****//
import "vue-awesome/icons/list";
import "vue-awesome/icons/key";
import "vue-awesome/icons/wine-bottle";
import "vue-awesome/icons/bolt";
import "vue-awesome/icons/wine-glass-alt";
import "vue-awesome/icons/print";
import "vue-awesome/icons/envelope";
import "vue-awesome/icons/pen-fancy";
import "vue-awesome/icons/box-open";
import "vue-awesome/icons/copy";
import "vue-awesome/icons/key";
import "vue-awesome/icons/file-export";

import Icon from "vue-awesome/components/Icon";
Vue.component("Icon", Icon);

import Login from "../views/Login";
Vue.component("login", Login);

//*****// import des composant morphine //*****//
import MFormText from "../../node_modules/interigp-commons/components/MFormText";
Vue.component("m-form-text", MFormText);
import MFormTextMarkdown from "../../node_modules/interigp-commons/components/MFormTextMarkdown";
Vue.component("m-form-text-markdown", MFormTextMarkdown);
import MFormTextJodit from "../../node_modules/interigp-commons/components/MFormTextJodit";
Vue.component("m-form-text-jodit", MFormTextJodit);
import MFormPassword from "../../node_modules/interigp-commons/components/MFormPassword";
Vue.component("m-form-password", MFormPassword);
import MFormCombobox from "../../node_modules/interigp-commons/components/MFormCombobox";
Vue.component("m-form-combobox", MFormCombobox);
import MFormRadioGroup from "../../node_modules/interigp-commons/components/MFormRadioGroup";
Vue.component("m-form-radio-group", MFormRadioGroup);
import MFormRadio from "../../node_modules/interigp-commons/components/MFormRadio";
Vue.component("m-form-radio", MFormRadio);
import MFormCheckbox from "../../node_modules/interigp-commons/components/MFormCheckbox";
Vue.component("m-form-checkbox", MFormCheckbox);
import MFormDate from "../../node_modules/interigp-commons/components/MFormDate";
Vue.component("m-form-date", MFormDate);
import MCalendar from "../../node_modules/interigp-commons/components/MCalendar";
Vue.component("m-calendar", MCalendar);
import MFormHour from "../../node_modules/interigp-commons/components/MFormHour";
Vue.component("m-form-hour", MFormHour);
import MHour from "../../node_modules/interigp-commons/components/MHour";
Vue.component("m-hour", MHour);
import MConfirmDialog from "../../node_modules/interigp-commons/components/MConfirmDialog";
Vue.component("m-confirm-dialog", MConfirmDialog);
import MMessageDialog from "../../node_modules/interigp-commons/components/MMessageDialog";
Vue.component("m-message-dialog", MMessageDialog);
import MAlertDialog from "../../node_modules/interigp-commons/components/MAlertDialog";
Vue.component("m-alert-dialog", MAlertDialog);
import MToast from "../../node_modules/interigp-commons/components/MToast";
Vue.component("m-toast", MToast);
import MFormFiles from "../../node_modules/interigp-commons/components/MFormFiles";
Vue.component("m-form-file", MFormFiles);
// import MFormImages from "../../node_modules/interigp-commons/components/MFormImages";
// Vue.component("m-form-images", MFormImages);
import MFormSelect from "../../node_modules/interigp-commons/components/MFormSelect";
Vue.component("m-form-select", MFormSelect);
import MFormSelectMultiple from "../../node_modules/interigp-commons/components/MFormSelectMultiple";
Vue.component("m-form-select-multiple", MFormSelectMultiple);
import MList from "../../node_modules/interigp-commons/components/MList";
Vue.component("m-list", MList);
import MListSimple from "../../node_modules/interigp-commons/components/MListSimple";
Vue.component("m-list-simple", MListSimple);
// import MFormDateHour from "../../node_modules/interigp-commons/components/MFormDateHour";
// Vue.component("m-form-date-hour", MFormDateHour);

//*****// import d'autres composants //*****//
// import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
// import datePicker from "vue-bootstrap-datetimepicker";
// Vue.component("date-picker", datePicker);
// jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
//   icons: {
//     time: "far fa-clock",
//     date: "far fa-calendar",
//     up: "fas fa-arrow-up",
//     down: "fas fa-arrow-down",
//     previous: "fas fa-chevron-left",
//     next: "fas fa-chevron-right",
//     today: "fas fa-calendar-check",
//     clear: "far fa-trash-alt",
//     close: "far fa-times-circle"
//   }
// });
import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";
import VueDraggable from "vuedraggable";
Vue.component("draggable", VueDraggable);
import Jodit from "jodit/build/jodit.js";
import "jodit/build/jodit.min.css";
Vue.component("jodit-editor", Jodit);
Object.defineProperty(Vue.prototype, "$Jodit", { value: Jodit });
import Paginate from "vuejs-paginate";
Vue.component("paginate", Paginate);

import { Tooltip } from "bootstrap";
import store from "./store";
Vue.directive("tooltip", {
  inserted: function (el) {
    if (store.state.preferences.personal.help) new Tooltip(el);
  }
});
/* Vue.directive("toto", {
  inserted: function(el) {
    console.log("coucou  toto", el);
  }
}); */

//*****// import des vues //*****//
import PreferencesPersonal from "../views/PreferencesPersonal";
Vue.component("preferencespersonal-win-edit", PreferencesPersonal);
import UserWinEdit from "../views/UserWinEdit";
Vue.component("user-win-edit", UserWinEdit);
import CenterWinEdit from "../views/CenterWinEdit";
Vue.component("center-win-edit", CenterWinEdit);
import UsersList from "../views/UsersList";
Vue.component("users-list", UsersList);
import CentersList from "../views/CentersList";
Vue.component("centers-list", CentersList);
import ImportWin from "../views/ImportWin";
Vue.component("import-win", ImportWin);
import ImportWinImages from "../views/ImportWinImages";
Vue.component("import-win-images", ImportWinImages);
// import MailingEmailWinEdit from "../views/MailingEmailWinEdit";
// Vue.component("email-win-edit", MailingEmailWinEdit);
import MailinglistWinEdit from "../views/MailinglistWinEdit";
Vue.component("mailinglist-win-edit", MailinglistWinEdit);
import WineWinEdit from "../views/WineWinEdit";
Vue.component("wine-win-edit", WineWinEdit);
import JureWinEdit from "../views/JureWinEdit";
Vue.component("jure-win-edit", JureWinEdit);
import CandidatJureWinEdit from "../views/CandidatJureWinEdit";
Vue.component("candidat-jure-win-edit", CandidatJureWinEdit);
import Research from "../views/Research.vue";
Vue.component("research-view", Research);
import ContactDetails from "../views/ContactDetails.vue";
Vue.component("contact-details", ContactDetails);
import ContactWinEdit from "../views/ContactWinEdit.vue";
Vue.component("contact-win-edit", ContactWinEdit);
import InvoiceWinEdit from "../views/InvoiceWinEdit.vue";
Vue.component("invoice-avoir-win-edit", InvoiceWinEdit);
import AvoirWinCreate from "../views/AvoirWinCreate.vue";
Vue.component("avoir-win-create", AvoirWinCreate);
import AvoirWinConfirm from "../views/AvoirWinConfirm.vue";
Vue.component("avoir-win-confirm", AvoirWinConfirm);
import ContactAdvancedSearchWin from "../views/ContactAdvancedSearchWin.vue";
Vue.component("contact-advanced-search-win", ContactAdvancedSearchWin);
import ContactAdvancedSearch from "../views/ContactAdvancedSearch.vue";
Vue.component("contact-advanced-search", ContactAdvancedSearch);
import ActualitesWinEdit from "../views/ActualitesWinEdit.vue";
Vue.component("actualite-win-edit", ActualitesWinEdit);
import OrderNew1 from "../views/OrderNew1.vue";
Vue.component("order-new-1", OrderNew1);
import OrderNew2 from "../views/OrderNew2.vue";
Vue.component("order-new-2", OrderNew2);
// import Calendar from "../views/Calendar";
// Vue.component("calendar", Calendar);

//*****// import des composants des vues //*****//
import FormCandidat from "../../node_modules/interigp-commons/views/FormCandidat.vue";
Vue.component("form-candidat", FormCandidat);
import FormWine from "../../node_modules/interigp-commons/views/FormWine.vue";
Vue.component("form-wine", FormWine);
import FormInvoice from "../views/viewscomponents/FormInvoice.vue";
Vue.component("form-invoice", FormInvoice);
import ReadCandidatJure from "../views/viewscomponents/ReadCandidatJure.vue";
Vue.component("read-candidat-jure", ReadCandidatJure);
import ChoiceCandidat from "../views/viewscomponents/ChoiceCandidat.vue";
Vue.component("choice-candidat", ChoiceCandidat);
import FrameInvoice from "../views/viewscomponents/FrameInvoice.vue";
Vue.component("frame-invoice", FrameInvoice);
import FrameAvoir from "../views/viewscomponents/FrameAvoir.vue";
Vue.component("frame-avoir", FrameAvoir);
import HelpVariables from "../views/viewscomponents/HelpVariables.vue";
Vue.component("help-variables", HelpVariables);
import ResultsOrderMacaron from "../../node_modules/interigp-commons/views/ResultsOrderMacaron.vue";
Vue.component("results-order-macaron", ResultsOrderMacaron);
import ReadOrderMacaron from "../views/viewscomponents/ReadOrderMacaron.vue";
Vue.component("read-order-macaron", ReadOrderMacaron);
import ImportWinDetoure from "../views/ImportWinDetoure.vue";
Vue.component("import-win-detoure", ImportWinDetoure);
import ImportWinWines from "../views/ImportWinWines.vue";
Vue.component("import-win-wines", ImportWinWines);
