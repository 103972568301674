<template>
  <section class="frame">
    <div class="frame-img-top"></div>
    <h4>Calendrier</h4>
    <div class="row">
      <div class="col">
        <FullCalendar :options="calendarOptions" />
      </div>
    </div>
  </section>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
// import bootstrapPlugin from "@fullcalendar/bootstrap";

export default {
  name: "home",
  components: { FullCalendar },
  data() {
    return {
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin
          //   bootstrapPlugin
        ],
        // themeSystem: "bootstrap",
        initialView: "timeGridWeek",
        views: {
          timeGridDay: {
            titleFormat: { day: "numeric", month: "long" }
          }
        },
        hiddenDays: [0, 6],
        height: 800,
        // defaultDate: this.value,
        editable: this.editable,
        selectable: this.editable,
        firstDay: 1,
        //   scrollTime: "09:00:00",
        // minTime: "08:00:00",
        // maxTime: "20:00:00",
        // locale: frLocale,
        // header: {
        //   left: "title",
        //   center: "timeGridDay,timeGridWeek,dayGridMonth",
        //   right: "today prev,next"
        // },
        dateClick: info => {},
        select: info => {
          this.openCalendarWinEdit(
            -1,
            this.$moment(info.start),
            this.$moment(info.end),
            info.allDay
          );
        },
        eventClick: info => {
          if (!info.event.durationEditable) return;
          this.openCalendarWinEdit(info.event.id);
        },
        eventResize: info => {
          if (!info.event.extendedProps.row_ev.ev_absence) {
            this.toast = true;
            return info.revert();
          }
          if (!info.event.durationEditable) return info.revert();
          this.saveEventUpdated(info.event);
        },
        eventDrop: info => {
          if (!info.event.extendedProps.row_ev.ev_absence) {
            this.toast = true;
            return info.revert();
          }
          if (!info.event.durationEditable) return info.revert();
          this.saveEventUpdated(info.event);
        },
        events: async (fetchInfo, successCallback, failureCallback) => {
          // let users = [];
          if (this.users.length === 0) return successCallback([]);
          let users = this.users.map(user => {
            return user.co_id;
          });
          // for (let ius = 0; ius < this.users.length; ius++) {
          //   const user = this.users[ius];
          //   users.push(user.co_id);
          // }
          // if (this.users.length === 0) {
          //   users.push("-1");
          // }
          let params = {
            from: this.$moment(fetchInfo.start).format("YYYY-MM-DD HH:mm:ss"),
            to: this.$moment(fetchInfo.end).format("YYYY-MM-DD HH:mm:ss"),
            users
          };
          let response = await this.$axios.get(
            this.$config.server_url + "/backoffice/1.0/events",
            { params }
          );
          // console.log("response", response);
          let events = [];
          for (let iEv = 0; iEv < response.data.data.length; iEv++) {
            const row_ev = response.data.data[iEv];
            let title = [];
            if (row_ev.ev_summary) title.push(row_ev.ev_summary);
            if (row_ev.fd_id && row_ev.fd_id.co_id_patient) {
              title.push(
                this.$options.filters.formatContactName(
                  row_ev.fd_id.co_id_patient
                )
              );
            }
            if (row_ev.fd_id && row_ev.fd_id.fd_id && row_ev.co_id_apas) {
              title.push(
                this.$options.filters.formatContactName(row_ev.co_id_apas)
              );
            }
            if (row_ev.fd_id && row_ev.fd_id.fd_id && row_ev.co_id_docmouveal) {
              title.push(
                this.$options.filters.formatContactName(row_ev.co_id_docmouveal)
              );
            }
            if (
              row_ev.fd_id &&
              row_ev.fd_id.fd_id &&
              row_ev.co_guests &&
              this.$_.isArray(row_ev.co_guests)
            ) {
              for (let iGuest = 0; iGuest < row_ev.co_guests.length; iGuest++) {
                const guest = row_ev.co_guests[iGuest];
                if (!guest.co_id) continue;
                title.push(
                  this.$options.filters.formatContactName(guest.co_id)
                );
              }
            }
            if ((!row_ev.fd_id || !row_ev.fd_id.fd_id) && row_ev.co_id) {
              title.push(this.$options.filters.formatContactName(row_ev.co_id));
            }
            let editable = this.editable;
            let color = "#7a7a7a";
            let cls = [];
            //let rdvtype = "rdvPatient";
            if (row_ev.ev_absence) {
              color = "#9fcde2";
              title.unshift("ABSENCE");
              //rdvtype = "absence";
            } else if (row_ev.ev_status === "validated") {
              color = "#53c034";
            } else if (row_ev.ev_status === "holidays") {
              color = "#ea409b";
              editable = false;
            } else {
              color = "#7a7a7a";
              cls.push("m_gantt_hachure");
            }
            if (row_ev.ev_workshop) {
              color = "#e09571";
              title.unshift("ATELIER");
              // rdvtype = "workshop";
            }
            events.push({
              editable,
              id: row_ev.ev_id,
              title: title.join(" > "),
              start: this.$moment(row_ev.ev_dtstart).toDate(),
              end: this.$moment(row_ev.ev_dtend).toDate(),
              allDay: row_ev.ev_allday,
              classNames: cls,
              color,
              extendedProps: {
                row_ev: row_ev
              }
            });
          }
          successCallback(events);
        }
      }
    };
  },
  mounted() {
    this.loadEvents();
  },
  methods: {
    async loadEvents() {
      /* let response = await this.$axios.get(
        this.$config.server_url + "/backoffice/1.0/stats"
      );
      // console.log("response", response);
      this.stats = response.data.data; */
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
