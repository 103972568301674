<template>
  <div class="modal"
    v-if="value"
    :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'
      "
    tabindex="-1"
    role="dialog"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable"
      role="document">
      <div class="modal-content"
        :style="'width:700px'">
        <div class="modal-header">
          <div v-if="confirmNum === 1">
            <h4 v-if="wines.length > 1">
              Vous allez créer un avoir avec les {{ wines.length }} vins
              suivants :
            </h4>
            <h4 v-else>
              Vous allez créer un avoir avec le vin suivant :
            </h4>
          </div>
          <h4 v-if="confirmNum === 2">
            Veillez confirmer à nouveau la création de l'avoir
          </h4>
        </div>
        <div class="modal-body">
          <m-list-simple tableClass="table-striped"
            ref="winelisttoavoir"
            :dynamic="true"
            :items="wines"
            item-value="wi_id"
            :item-height="30"
            :skip="0"
            :total="wines.length">
            <template v-slot:ths="{ }">
              <th><span>Nom</span></th>
              <th><span>Dénomination</span></th>
              <th><span>Couleur</span></th>
              <th><span>Millésime</span></th>
              <th><span>Candidat</span></th>
            </template>
            <template v-slot:tds="{ item }">
              <td>{{ item | formatWineName }}</td>
              <td>
                {{
                  item.denomination
                  ? item.denomination.de_name
                  : item.wi_denomination
                }}
              </td>
              <td>{{ item.wi_couleur | formatWineColor }}</td>
              <td>{{ item.wi_millesime | formatWineMillesime }}</td>
              <td>
                {{ $options.filters.formatContactNameSimple(row_pa, "pa_") }}
              </td>
            </template>
          </m-list-simple>
          <div v-if="confirmNum === 1">
            <span class="fw-bold">Ce qui aura pour conséquence de : </span>
            <br /><br />
            <ul>
              <li>Mettre les {{ wines.length }} vins hors concours</li>
              <li>
                Créer un avoir de {{ totalAvoir / 100 }}€ HT que vous
                rembourserez à
                {{ row_pa.pa_society }}
              </li>
              <li>
                Envoyer automatiquement un email d'avoir à
                {{ row_pa.login.lo_email }}
              </li>
            </ul>
          </div>
          <div v-if="confirmNum === 2">
            <span class="fw-bold">Vous ne pourrez plus modifier cet avoir une fois enregistré</span>
            <m-form-checkbox class="ms-2"
              label="Je confirme la création de l'avoir"
              :name="$Utils.randomstring('avoirConfirm')"
              v-model="avoirConfirm"></m-form-checkbox>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary"
            @click="cancelWin">Annuler</button>
          <button v-if="confirmNum === 1"
            class="btn btn-primary ms-2"
            @click="saveWin1">
            Valider ces trois actions
          </button>
          <button v-if="confirmNum === 2"
            class="btn btn-primary ms-2"
            @click="saveWin2">
            Créer avoir
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "mmessagedialog",
  props: {
    value: Boolean,
    wines: Array,
    row_pa: Object,
    totalAvoir: Number,
    confirmNum: Number
  },

  data() {
    return {
      dialog: false,
      avoirConfirm: false
    };
  },
  mounted() {},
  watch: {
    value: function (val) {
      this.dialog = val;
      // if (!this.width) this.width = "500px";
    }
  },
  components: {},
  methods: {
    cancelWin() {
      this.$emit("canceled");
      this.avoirConfirm = false;
    },
    saveWin1() {
      this.$emit("valided");
    },
    saveWin2() {
      if (!this.avoirConfirm) {
        this.$store.dispatch("showToast", {
          title: "erreur",
          text: "Vous devez cocher la case de confirmation",
          color: "red"
        });
      } else {
        this.$emit("confirmed");
        this.avoirConfirm = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>.modal-content {
  margin: auto;
}

.table .thead-light th {
  background-color: #eceff0;
}
</style>
