<template>
  <div>
    <div class="row mb-3">
      <h3 class="bis d-flex align-items-center">
        <div class="icon-title d-flex align-items-center justify-content-center">
          <icon width="30"
            height="30"
            name="cog"></icon>
        </div>
        <div>
          <div>Les logs</div>
          <div class="fs-6 fw-light">
            Vous pouvez visualisez tous les logs ici.
          </div>
        </div>
      </h3>
    </div>

    <div class="row mb-3">
      <div class="col-md-12 d-flex">
        <m-form-date label="Date de début"
          :name="$Utils.randomstring('debut')"
          formatInput="DD/MM/YYYY"
          v-model="filterDateStart"></m-form-date>
        <m-form-date class="ms-3"
          label="Date de fin"
          :name="$Utils.randomstring('fin')"
          formatInput="DD/MM/YYYY"
          v-model="filterDateEnd"></m-form-date>
        <m-form-select class="ms-3"
          style="width:500px;"
          label="Type de logs"
          :name="$Utils.randomstring('type')"
          :rules="[$Validation.integer]"
          v-model="filterTypeLogs"
          :items="$store.state.items_log_types"></m-form-select>
      </div>
    </div>
    <div class="frame">
      <m-list-simple tableClass="table-hover table-striped table-small"
        ref="logslist"
        :items="logs"
        item-value="ac_id"
        :item-height="30"
        :limit="100"
        :skip="logs_skip"
        :total="logs_total"
        :urlExportCSV="`${this.$config.server_url}/backoffice/1.0/actions/exportcsv?token=${this.$store.state.accesstoken}&origin=${this.$config.backoffice_url}`
          ">
        <template v-slot:ths="{ }">
          <th @click="sortUsers(['us_name', 'us_firstname'])">
            <span class="pointer">Utilisateur</span>
          </th>
          <th @click="sortUsers(['us_name', 'us_firstname'])">
            <span class="pointer">Candidat/juré</span>
          </th>
          <th @click="sortUsers(['ac_date'])">
            <span class="pointer">Date</span>
          </th>
          <th @click="sortUsers(['ac_type'])">
            <span class="pointer">Action</span>
          </th>

          <th>
            <span class="pointer">Element concerné</span>
          </th>
          <th>
            <span class="pointer">Commentaire</span>
          </th>
        </template>
        <template v-slot:tds="{ item }">
          <td>
            {{ $options.filters.formatContactNameSimple(item.user) }}
          </td>
          <td>
            {{ $options.filters.formatContactNameSimple(item.login, "lo_") }}
            <span v-if="item.lo_id_society">({{ item.lo_id_society }})</span>
          </td>
          <td>
            {{
              item.ac_date && item.ac_date !== "0000-00-00 00:00:00"
              ? $dayjs(item.ac_date).format("DD/MM/YYYY [à] HH:mm:ss")
              : " - "
            }}
          </td>
          <td>
            {{
              $options.filters.formatvalue(
                item.ac_type,
                "items_log_types",
                "uppercase",
                "singular"
              )
            }}
          </td>
          <td v-html="item.ac_infos"></td>
          <td>{{ item.ac_comment }}</td>
        </template>
      </m-list-simple>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "logs",
  components: {},
  data() {
    return {
      logs: [],
      logs_skip: 0,
      logs_total: 0,
      filterTypeLogs: "",
      filterDateStart: this.$dayjs()
        .subtract(1, "month")
        .format("YYYY-MM-DD"),
      filterDateEnd: this.$dayjs().format("YYYY-MM-DD"),
      itemsTypesLogs: []
    };
  },
  watch: {
    filterTypeLogs(val) {
      this.loadLogs();
    },
    filterDateStart(val) {
      this.loadLogs();
    },
    filterDateEnd(val) {
      this.loadLogs();
    }
  },
  async mounted() {
    if (!this.$Utils.hasRight("canLogsAccess")) {
      this.$router.push("/");
    }
    this.loadLogs();
  },
  methods: {
    async loadLogs() {
      let params = {
        ac_type: this.filterTypeLogs,
        date_from: this.filterDateStart,
        date_to: this.filterDateEnd,
        order_sort: "desc",
        limit: 100
      };
      let response = await this.$axios.get(
        this.$config.server_url + "/backoffice/1.0/actions",
        { params }
      );
      this.logs = response.data.data;
      //this.logs_total = response.data.meta.total;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
