<template>
  <div class="modal"
    v-if="value"
    :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'"
    tabindex="-1"
    role="dialog"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable"
      role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3>Importer les images détourées</h3>
        </div>
        <div class="modal-body">
          <m-form-file class="input-avatar mb-2"
            label="sélectionnez un fichier"
            ref="inputAvatar"
            :multiple="true"
            @inputfile="savefile" />
          <div v-if="importDone"
            class="alert alert-success"
            v-html="alertOkTxt"></div>
          <div v-if="importDone && alertErrTxt"
            class="alert alert-danger"
            v-html="alertErrTxt"></div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary"
            v-if="!importDone"
            @click="cancelWin">
            Annuler
          </button>
          <button class="btn btn-primary"
            @click="importfile">
            {{ importDone ? "Ok" : "Importer" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "importwinDetoure",
  props: {
    value: Boolean
  },
  data() {
    return {
      dialog: false,
      files: [],
      alertOkTxt: "",
      alertErrTxt: "",
      importDone: false
    };
  },
  mounted() {
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  watch: {
    value: function (val) {
      this.dialog = val;
      this.alertOkTxt = "";
      this.alertErrTxt = "";
      this.importDone = false;
    }
  },
  components: {},
  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },
    async savefile(filesSelected) {
      for (let ifi = 0; ifi < filesSelected.length; ifi++) {
        const file = filesSelected[ifi];
        this.files.push(file);
      }
    },
    async importfile() {
      if (this.importDone) {
        this.cancelWin();
        return;
      }
      let response;
      for (let ifi = 0; ifi < this.files.length; ifi++) {
        const file = this.files[ifi];
        let formData = new FormData();
        formData.append("file", file);
        response = await this.$axios.post(
          this.$config.server_url +
          "/backoffice/1.0/wines/import/detoure/" +
          this.$store.state.year,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        );
      }
      this.importDone = true;
      this.alertOkTxt = `${this.files.length} images importées <br/>`;
      if (response.data.err) {
        this.alertErrTxt = "erreur";
      }
    },
    cancelWin() {
      this.alertOkTxt = "";
      this.alertErrTxt = "";
      this.$emit("input", false);
      this.$emit("emitImportActions");
    }
  }
};
</script>
<style lang="scss" scoped>
</style>
