<template>
  <div class="modal"
    v-if="value"
    :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'
      "
    tabindex="-1"
    role="dialog"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable"
      role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="w-100 d-flex align-items-end justify-content-between">
            <div>
              <h3 class="mb-0 d-flex align-items-center">
                <div class="icon-title d-flex align-items-center justify-content-center">
                  <icon width="30"
                    height="30"
                    name="shopping-cart"></icon>
                </div>
                <div>
                  <div>
                    Valider une commande
                  </div>
                  <div class="fs-6 fw-light">
                    Étape 2 / 2
                  </div>
                </div>
              </h3>
            </div>
            <div>
              <!-- <m-form-select style="width:300px;"
                label="État de la commande"
                :name="$Utils.randomstring('or_state')"
                :items="$store.state.items_stateOrder"
                v-model="row_or.or_state"></m-form-select> -->
            </div>
          </div>
        </div>
        <div class="modal-body">
          <div class="frame bg-gray">
            <div class="row">
              <div class="col-md-12">
                <read-order-macaron :row_or="row_or"
                  :canReturnStep1="true"
                  @emitStep1="goStep1"></read-order-macaron>
              </div>
            </div>
          </div>
          <div class="frame">
            <h4>La commande</h4>
            <p>
              Voici la liste des vins où vous avez gagné une médaille,
              sélectionnez le nombre de macarons à commander par vin
            </p>
            <m-list-simple tableClass="table-hover table-striped"
              ref="wineslist"
              :dynamic="true"
              :items="row_or.orderlines"
              item-value="orl_id"
              :item-height="50"
              :total="row_or.orderlines.length">
              <template v-slot:ths="{ }"> </template>
              <template v-slot:tds="{ item }">
                <td class="d-flex align-items-center">
                  <div class="p-2">
                    <img class="img-fluid img-macaron"
                      :src="`${$config.server_url
      }/commons/1.0/files/yp_file_macaron_${$options.filters.formatvalue(
        item.wine.wi_medaille,
        'items_medailles',
        'lowercase',
        'singular'
      )}/${item.wine.wi_year}`
      "
                      alt="image macaron" />
                  </div>
                  <div class="ms-3">
                    <div>{{ item.wine | formatWineName }}</div>
                    <h5>
                      <span v-if="item.wine.denomination">{{ item.wine.denomination.de_name }} -
                      </span>
                      <span>{{ item.wine.wi_couleur | formatWineColor }} -
                      </span>
                      <span>{{
      item.wine.wi_millesime | formatWineMillesime
    }}</span>
                    </h5>
                    <div>
                      Nombre de vins déclarés :
                      <div v-for="(contenant, index) in item.wine.contenants"
                        :key="index">
                        {{ contenant.nombre }} x
                        {{ contenant.contenant / 100 }} cl
                      </div>
                    </div>
                  </div>
                </td>
                <td class="align-middle ">
                  <div>Concours {{ item.wine.wi_year }}</div>
                </td>
                <td class="align-middle">
                  <div>
                    Macarons
                    {{
      $options.filters.formatvalue(
        item.wine.wi_medaille,
        "items_medailles",
        "uppercase",
        "singular"
      )
    }}
                    : {{ item.orl_quantity | formatChiffreSimple }}
                  </div>
                </td>
              </template>
            </m-list-simple>
            <results-order-macaron :row_or="row_or"></results-order-macaron>
          </div>

          <!-- <div class="frame">
            <h4>Paiement</h4>
            <div class="d-flex align-items-end">
              <m-form-select style="width:260px;"
                label="Moyen de paiement"
                :name="$Utils.randomstring('or_typepaiement')"
                v-model="row_or.or_typepaiement"
                :items="$store.state.items_paiement_way"></m-form-select>
              <m-form-text class="ms-2"
                type="number"
                label="Somme TTC"
                :name="$Utils.randomstring('or_payed_ttc')"
                v-model="row_or.or_payed_ttc"></m-form-text>
              <m-form-date class="ms-2"
                label="Le (JJ/MM/AAAA)"
                :name="$Utils.randomstring('or_payed_date')"
                formatInput="DD/MM/YYYY"
                v-model="row_or.or_payed_date"
                :disabled="false"></m-form-date>
              <m-form-checkbox class="ms-4 me-2 mb-1"
                label="Payé"
                :name="$Utils.randomstring('or_payed')"
                v-model="row_or.or_payed"></m-form-checkbox>
              <m-form-text class="ms-2"
                label="N° banque"
                :name="$Utils.randomstring('or_auto')"
                v-model="row_or.or_auto"></m-form-text>
            </div>
          </div> -->
        </div>
        <div class="modal-footer d-flex flex-row">
          <button type="button"
            :disabled="row_or.or_payed"
            class="btn btn-outline-danger"
            @click="deleteConfirmWin">
            Supprimer
          </button>
          <button type="button"
            class="btn btn-outline-secondary"
            @click="cancelWin">
            Annuler
          </button>
          <div class="ms-auto"></div>
          <div class="d-flex align-items-center">
            <div class="me-2">Envoyer email réception</div>
            <label class="switch">
              <input v-model="sendEmailReception"
                type="checkbox" />
              <span class="slider round"></span>
            </label>
          </div>

          <button type="button"
            class="btn btn-secondary "
            @click="goStep1">
            Retour 1 / 2
          </button>
          <button type="button"
            class="btn btn-primary ms-2"
            @click="saveWin">
            Valider
          </button>
        </div>
      </div>
    </div>
    <m-confirm-dialog v-model="confirmdelete"
      text="Souhaitez-vous effacer cette commande ?"
      title="Confirmation"
      @canceled="confirmdelete = false"
      @confirmed="deleteWin()"></m-confirm-dialog>
    <!--     <candidat-jure-win-edit
      v-if="row_pa2"
      v-model="candidatJureWinEdit"
      :pa_id="row_pa2.pa_id"
      @WinEditAction="WinEditCandidatJureActions"
    ></candidat-jure-win-edit> -->
    <m-message-dialog v-model="dialogErr"
      title="Erreur"
      :text="dialogErrTxt"></m-message-dialog>
  </div>
</template>

<script>
export default {
  name: "OrderNew2",
  components: {},
  props: {
    or_id: Number,
    /*     row_pa: {
      default: function() {
        return {};
      },
      type: Object
    }, */
    value: { default: false, type: Boolean }
  },
  data() {
    return {
      confirmdelete: false,
      dialogErrTxt: "",
      dialogErr: false,
      row_or: { orderlines: [] },
      wines: [],
      // row_pa2: {},
      candidatJureWinEdit: false,
      sendEmailReception: false
    };
  },
  watch: {
    value(v) {
      if (v) {
        this.sendEmailReception = false;
        // if (this.row_pa) this.row_pa2 = this.row_pa;
        this.showFraisDePort = false;
        this.loadOrder();
        //this.contenants = [];
        //  this.loadDenominations();
        //this.loadWines();
      }
    }
  },
  computed: {},
  created() {},
  mounted() {
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },
    async loadOrder() {
      let response = await this.$axios.get(
        this.$config.server_url + "/backoffice/1.0/orders/" + this.or_id
      );
      let row_or = response.data.data;
      // console.log("row_or.or_state", row_or.or_state);
      // calcul contenance
      for (let iwi = 0; iwi < row_or.orderlines.length; iwi++) {
        const orl = row_or.orderlines[iwi];
        orl.wine.nbBouteille = 0;
        orl.wine.contenants = [];
        if (orl.wine.wi_contenances) {
          let tabC = orl.wine.wi_contenances.split(";;");
          for (let i = 0; i < tabC.length; i++) {
            const el = tabC[i];
            if (el.length) {
              let tab2 = el.split(":");
              //wi.nbBouteille += parseInt(tab2[1]);
              orl.wine.contenants.push({
                contenant: parseInt(tab2[0]),
                nombre: parseInt(tab2[1])
              });
            }
          }
        }
      }
      this.row_or = row_or;
      //this.row_pa2 = this.row_or.participation;
    },
    /*  editCandidatJure() {
      this.candidatJureWinEdit = true;
      //this.$router.push("/candidats/" + this.row_pa.pa_id + "/edit");
    }, */
    emitChangeInputPaiement(row_or) {
      this.row_or = row_or;
    },
    /* async loadWines() {
      if (!this.row_pa2.pa_id) return;
      let params = {
        medailles: true,
        wi_year: this.$store.state.year,
        pa_id: this.row_pa2.pa_id
      };
      if (this.wi_id == -1) params = {};
      let response = await this.$axios.get(
        this.$config.server_url + "/backoffice/1.0/wines",
        { params }
      );
      let rows_wi = response.data.data;
      // contenances
      for (let iwi = 0; iwi < rows_wi.length; iwi++) {
        const wi = rows_wi[iwi];
        wi.contenants = [];
        if (wi.wi_contenances) {
          let tabC = wi.wi_contenances.split(";;");
          for (let i = 0; i < tabC.length; i++) {
            const el = tabC[i];
            if (el.length) {
              let tab2 = el.split(":");
              wi.contenants.push({
                contenant: parseInt(tab2[0]),
                nombre: parseInt(tab2[1])
              });
            }
          }
          // this.calculContenance();
        }
      }
      this.wines = rows_wi;
    }, */

    /*     emitChangeCandidat(row_pa) {
      this.row_pa2 = row_pa;
      this.row_or.participation = row_pa;
      this.loadWines();
      this.$refs.winesListMacaron.resetNbMacaron();
    }, */
    /*     emitEditCandidatJure() {
      this.candidatJureWinEdit = true;
    }, */
    async saveWin() {
      // console.log("this.row_or.or_fraisport", this.row_or.or_fraisport);
      if (this.sendEmailReception && !this.row_or.or_fraisport) {
        this.dialogErrTxt = "Veuillez indiquer les frais de port";
        this.dialogErr = true;
        return;
      }
      this.row_or.sendEmailReception = this.sendEmailReception;
      this.row_or.or_fraisport = this.row_or.or_fraisport * 100;
      let response = await this.$axios.put(
        this.$config.server_url +
        "/backoffice/1.0/orders/" +
        this.row_or.or_id +
        "/step2",
        this.row_or
      );
      this.$emit("input", false);
      this.$emit("WinEditActions", {
        action: "saved",
        data: response.data.data
      });
    },

    goStep1() {
      this.$emit("input", false);
      this.$emit("returnStep1", this.row_or);
    },
    deleteConfirmWin() {
      this.confirmdelete = true;
    },
    async deleteWin() {
      await this.$axios.delete(
        this.$config.server_url + "/backoffice/1.0/orders/" + this.row_or.or_id
      );
      this.confirmdelete = false;
      this.$emit("input", false);
      this.$emit("WinEditActions", { action: "deleted" });
    },
    /* WinEditCandidatJureActions(data) {
      if (data.action === "saved") {
        this.row_pa2 = data.row_pa;
      }
    }, */
    cancelWin() {
      this.$emit("input", false);
      this.$emit("WinEditActions", { action: "saved" });
      this.$router.push("/orders").catch(err => {});
    }
  }
};
</script>

<style scoped
  lang="scss">
  .img-macaron {
    width: 80px;
  }
</style>
