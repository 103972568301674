<template>
  <section class="row">
    <div class="col-md-6">
      <div>Civilité : {{ row_co.co_civility }}</div>
      <div>Nom : {{ row_co.co_name }}</div>
      <div>Prénom : {{ row_co.co_firstname }}</div>
    </div>
    <div class="col-md-6">
      <div>Adresse :</div>
      <div>{{ row_co.co_address1 }}</div>
      <div>{{ row_co.co_address2 }}</div>
      <div>{{ row_co.co_zip }} {{ row_co.co_city }}</div>
    </div>
  </section>
</template>

<script>
export default {
  name: "contactdetails",
  components: {},
  props: {
    co_id: Number,
    keyload: Number
  },
  data() {
    return {
      row_co: {}
    };
  },
  async mounted() {},
  watch: {
    keyload: function (v) {
      this.loadContact();
    },
    co_id: function (v) {
      this.loadContact();
    }
  },
  computed: {},
  methods: {
    async loadContact() {
      let response = await this.$axios.get(
        this.$config.server_url + "/backoffice/1.0/contacts/" + this.co_id
      );
      if (!response || !response.data) return;
      this.row_co = response.data.data;

      this.$emit("loaded");
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
