<template>
  <div :class="$store.state.theme">
    <!-- @click="myaccountDropdown = false" -->
    <div v-if="$store.state.connected"
      class="">
      <div v-if="menuRoute !== 'print'"
        class="top-bar d-flex">
        <div class="top-bar-left p-0 text-center container-logo-app"
          :style="`background-image:url(${this.$config.server_url}/commons/1.0/files/yp_file_logo/${year})`
            "></div>
        <div class="top-bar-right d-flex align-items-center justify-content-between relative">
          <div class="d-flex align-items-center">
            <div @click.stop="hideOrShowMenu"
              class="d-flex align-items-center p-2">
              <icon class="icon-topbar"
                name="bars"></icon>
            </div>
            <!-- <m-form-text
              style="width:350px;"
              class="mb-0 input-search"
              placeholder="Rechercher dans tous les modules"
              prepend-inner-icon="mdi-magnify"
              v-model="search"
            ></m-form-text> -->
          </div>
          <div class="nav-left d-flex">
            <div class="pointer me-3 d-flex align-items-center"
              @click="downloadHelp">
              <icon width="22"
                height="22"
                name="question-circle"
                class="me-2 icon-topbar"></icon>
              <div class="icon-topbar">Aide</div>
            </div>
            <div class="separation-verticale"></div>
            <div class="dropdown">
              <div @click.stop="myaccountDropdown = !myaccountDropdown"
                aria-expanded="false"
                class="pointer ms-3 me-4 justify-content-center">
                <div class="d-flex">
                  <div class="container-avatar  container-avatar-app"
                    ref="avatar"
                    :style="`background-image: url(${this.$config.server_url}/backoffice/1.0/users/${this.$store.state.user.us_id}/avatar?token=${this.$store.state.accesstoken}&c=${$store.state.reloadkey_avatar}&origin=${this.$config.backoffice_url})`
                      "></div>
                  <div class="ps-2 pt-1"
                    style="line-height:1rem;">
                    <span style="color:#FB5958; font-weight:bold;">
                      {{ $store.state.user | formatContactNameSimple }}</span>
                    <br />
                    <span class="small-text">{{
                      $store.state.user | formatContactType
                    }}</span>
                  </div>
                </div>
                <!--<img
                  class="container-avatar "
                  :src="
                    `background-image: url(${this.$config.server_url}/backoffice/1.0/users/${this.$store.state.user.us_id}/avatar?token=${this.$store.state.accesstoken})`
                  "
                  alt="avatar utilisateur"
                /> -->
              </div>
              <!-- <transition name="slide-myaccount"> -->
              <div class="dropdown-menu menu-profil"
                v-if="myaccountDropdown">
                <div class="py-1 px-4 text-nowrap">
                  {{ $store.state.user | formatContactNameSimple }}<br />
                  <span class="small-text">{{
                    $store.state.user | formatContactType
                  }}</span>
                </div>
                <hr class="dropdown-divider" />
                <a class="dropdown-item pointer"
                  @click="userWinEdit">Ma fiche</a>
                <a class="dropdown-item pointer"
                  @click="preferenceWinEdit">Mes préférences</a>
                <hr class="dropdown-divider" />
                <a class="dropdown-item pointer"
                  @click="logout">Me déconnecter</a>
              </div>
              <!-- </transition> -->
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <transition name="slide-menu">
          <div v-if="menuRoute !== 'print' && showMenu"
            class="menu"
            ref="menu">
            <ul class="nav nav-pills flex-column mb-auto">
              <li @click="deleteSearch">
                <router-link class="nav-item"
                  to="/home">
                  <icon width="25"
                    height="25"
                    name="home"
                    color="white"></icon>
                  <div class="nav-link">Accueil</div>
                </router-link>
              </li>
              <li v-if="$Utils.hasRight('canUsersAccess')"
                @click="deleteSearch">
                <router-link class="nav-item"
                  to="/admins">
                  <icon width="25"
                    height="25"
                    name="bolt"
                    color="white"></icon>
                  <div class="nav-link">Les administrateurs</div>
                </router-link>
              </li>
              <li v-if="$Utils.hasRight('canCentersAccess')"
                @click="deleteSearch">
                <router-link class="nav-item"
                  to="/centers">
                  <icon width="25"
                    height="25"
                    name="bolt"
                    color="white"></icon>
                  <div class="nav-link">Les interprofessions</div>
                </router-link>
              </li>

              <!--               <li @click="deleteSearch">
                <router-link class="nav-item" to="/contacts/group/0">
                  <icon
                    width="25"
                    height="25"
                    name="user-friends"
                    color="white"
                  ></icon>
                  <div class="nav-link">Contacts</div></router-link
                >
              </li> -->

              <li v-if="$Utils.hasRight('canContactsAccess')"
                @click="deleteSearch">
                <router-link class="nav-item"
                  to="/candidats">
                  <icon width="25"
                    height="25"
                    name="user-friends"
                    color="white"></icon>
                  <div class="nav-link">Les candidats</div>
                </router-link>
              </li>
              <li v-if="$Utils.hasRight('canContactsAccess')"
                @click="deleteSearch">
                <router-link class="nav-item"
                  to="/wines">
                  <icon width="25"
                    height="25"
                    name="wine-bottle"
                    color="white"></icon>
                  <div class="nav-link">Les vins</div>
                </router-link>
              </li>
              <li v-if="$Utils.hasRight('canContactsAccess')"
                @click="deleteSearch">
                <router-link class="nav-item"
                  to="/jures">
                  <icon width="25"
                    height="25"
                    name="wine-glass-alt"
                    color="white"></icon>
                  <div class="nav-link">Les jurés</div>
                </router-link>
              </li>
              <li v-if="$Utils.hasRight('canFacturesAccess')"
                @click="deleteSearch">
                <router-link class="nav-item"
                  to="/invoices">
                  <icon width="25"
                    height="25"
                    name="pen-fancy"
                    color="white"></icon>
                  <div class="nav-link">Les factures / avoirs</div>
                </router-link>
              </li>
              <li v-if="$Utils.hasRight('canMacaronsAccess')"
                @click="deleteSearch">
                <router-link class="nav-item"
                  to="/orders">
                  <icon width="25"
                    height="25"
                    name="shopping-cart"
                    color="white"></icon>
                  <div class="nav-link">Les commandes</div>
                </router-link>
              </li>
              <li v-if="$Utils.hasRight('canMailinglistAccess')"
                @click="deleteSearch">
                <router-link class="nav-item"
                  to="/mailinglists">
                  <icon width="25"
                    height="25"
                    name="mail-bulk"
                    color="white"></icon>
                  <div class="nav-link">Mailinglistes</div>
                </router-link>
              </li>
              <!--  <li @click="deleteSearch">
                <router-link class="nav-item" to="/calendar">
                  <icon
                    width="25"
                    height="25"
                    name="calendar-alt"
                    color="white"
                  ></icon>
                  <div class="nav-link">Calendrier</div></router-link
                >
              </li> -->
              <li v-if="$Utils.hasRight('canPresseAccess')"
                @click="deleteSearch">
                <router-link class="nav-item"
                  to="/actualites">
                  <icon width="25"
                    height="25"
                    name="newspaper"
                    color="white"></icon>
                  <div class="nav-link">Espace presse</div>
                </router-link>
              </li>
              <li v-if="$Utils.hasRight('canPreferencesAccess')"
                @click="deleteSearch">
                <router-link class="nav-item"
                  to="/preferences">
                  <icon width="25"
                    height="25"
                    name="cog"
                    color="white"></icon>
                  <div class="nav-link">Préférences</div>
                </router-link>
              </li>
            </ul>
          </div>
        </transition>

        <div class="main-part"
          ref="mainpart">
          <router-view v-if="!search"></router-view>
          <research-view v-if="search"
            :researchVal="search"
            @deleteResearch="deleteResearch"></research-view>
          <m-message-dialog v-model="$store.state.dialogErr"
            title="Erreur"
            :text="$store.state.dialogErrTxt"
            @close="$store.dispatch('showDialogError', false)"></m-message-dialog>
          <m-alert-dialog v-if="$store.state.alertDialog.container === 'app'"
            v-model="$store.state.alertDialog.value"
            :text="$store.state.alertDialog.text"
            container="app"
            :alertColor="$store.state.alertDialog.type"
            @close="$store.dispatch('showAlertDialog', false)"></m-alert-dialog>
          <m-toast :toasts="$store.state.toasts"
            @close="timestamp => {
              $store.dispatch('showToast', timestamp);
            }
              "></m-toast>
        </div>
      </div>
    </div>
    <div v-if="!$store.state.connected && $store.state.connectedCharged">
      <div class="bg-login d-flex align-items-center">
        <login></login>
      </div>
    </div>
    <user-win-edit v-model="userWinMe"
      :us_id="$store.state.user.us_id"
      :returnRoute="route"
      @close="userWinMe = false"
      @saved="meWinEditAction"
      @deleted="meWinEditAction"
      @canceled="meWinEditAction">
    </user-win-edit>
    <preferencespersonal-win-edit v-model="preferenceEdit"
      :us_id="$store.state.user.us_id"
      @close="preferenceEdit = false"
      @saved="meWinEditAction"
      @canceled="meWinEditAction"></preferencespersonal-win-edit>
  </div>
</template>

<script>
// import "@fullcalendar/core/main.css";
// import "@fullcalendar/daygrid/main.css";
// import "@fullcalendar/timegrid/main.css";
// import "@fullcalendar/list/main.css";
// import Vue from "vue";
// import OfferDetails from "./views/OffersDetails";
// Vue.component("offer-details", OfferDetails);

export default {
  name: "App",
  components: {},
  data() {
    // let year = this.$dayjs().format("YYYY");
    // if (
    //   this.$dayjs()
    //     .startOf("day")
    //     .isSameOrAfter(this.$dayjs(year + "-09-01"))
    // ) {
    //   year = parseInt(year) + 1;
    // }
    return {
      year: this.$store.state.year,
      menuRoute: "",
      confirmLogoutDialog: false,
      showMenu: true,
      search: "",
      myaccountDropdown: false,
      userWinMe: false,
      preferenceEdit: false,
      route: this.$route.path
    };
  },
  watch: {
    $route(to, from) {
      this.menuRoute = this.$route.meta.menu;
      if (this.menuRoute === "print") {
        this.showMenu = true;
        //this.hideOrShowMenu();
      }
      this.route = this.$route.path;
      /* if (to.path === "/profile/preferences/edit") {
        this.preferenceEdit = true;
      } */
    }
  },
  sockets: {
    connect() {
      this.$store.dispatch("socketjoinroom", this.$socket);
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  async mounted() {
    // this.$store.dispatch("loadPreferences");
    window.addEventListener("click", () => {
      this.myaccountDropdown = false;
    });
    // let tab = [
    //   { title: "coucou", text: "c'est moi", persist: true },
    //   { title: "coucou2", text: "c'est moi2", persist: true },
    //   { text: "au fait...", persist: true },
    //   { text: "au fait 2...", persist: true }
    // ];
    // for (let index = 0; index < tab.length; index++) {
    //   const el = tab[index];
    //   this.$store.dispatch("showToast", el);
    // }
    //this.user = this.$store.state.user;
  },
  methods: {
    deleteSearch() {
      this.search = "";
    },
    hideOrShowMenu() {
      this.showMenu = !this.showMenu;
      //if (this.showMenu) this.$refs.mainpart.style.paddingLeft = `0`;
      /*       
      if (this.showMenu) {
        this.$refs.menu.style.transform = `translate(-250px, 0px)`;
      }
      if (!this.showMenu) {
        this.$refs.menu.style.transform = `translate(0px, 0px)`;
        this.$refs.mainpart.style.paddingLeft = `250px`;
      }
      this.showMenu = !this.showMenu; */
    },
    // resizeMainContainer() {
    //   if (!this.$refs.mainContainer) return;
    //   // if (this.$route.name == "login") {
    //   //   this.$refs.mainContainer.style.height = "100%";
    //   //   return;
    //   // }
    //   this.$refs.mainContainer.style.height =
    //     parseInt(window.innerHeight) - 64 + "px";
    // },
    // go(where) {
    //   this.deleteResearch();
    //   this.$router.push(where);
    // },
    deleteResearch() {
      this.search = "";
    },
    logout() {
      this.confirmLogoutDialog = false;
      this.$store.commit("set_connexion", {
        accesstoken: null,
        refreshtoken: null,
        user: null,
        preferences: null
      });
      this.$router.push("/login").catch(err => {});
    },
    login() {
      this.$router.push("/login").catch(err => {});
    },
    preferenceWinEdit() {
      this.preferenceEdit = true;
      // this.$router.push("/profile/preferences/edit");
    },
    userWinEdit() {
      this.userWinMe = true;
      // this.$router.push("/profile/edit");
    },
    meWinEditAction() {
      // console.log("this.route", this.route);
      // this.$router.push(this.route);
      this.userWinMe = false;
      this.preferenceEdit = false;
    },
    saveWinUser() {
      // console.warn("this.$store.state.user.co_name", this.$store.state.user);
      // this.$refs.modal1.close();
    },
    openWinUser() {
      this.$refs.modal1.open();
    },
    closeWinUser() {
      this.$refs.modal1.close();
    },
    async downloadHelp() {
      window.open(
        `${this.$config.server_url}/backoffice/1.0/files/help?help=global&token=${this.$store.state.accesstoken}&origin=${this.$config.backoffice_url}`,
        "_blank"
      );
    }
  }
};
</script>
<style lang="scss">
@import "./scss/styles.scss";

$heightTopBar: 60px;
$widthLeftMenu: 16.66667%;

// .container-logo {
//   background-color: #bcbcbc;
// }
.container-logo-app {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.top-bar {
  background-size: cover;
  background-position: center;
  background-color: #fff;
  height: $heightTopBar;

  .top-bar-right {
    flex-grow: 1;
  }

  .top-bar-left {
    width: $widthLeftMenu;
    background-color: $them0-color-topbarleft;
  }

  .input-search input {
    background-color: #ffffffa8;
  }

  .icon-topbar {
    color: #fff;
  }
}

.separation-verticale {
  width: 1px;
  background-color: #bcbcbc;
}

.container-avatar-app {
  width: 45px !important;
  height: 45px !important;
  margin-top: 0 !important;
  box-shadow: none !important;
  /*   border: 1px solid gray;
  border-radius: 50%;
  overflow: hidden; */
}

.menu-profil {
  display: inline-table !important;
  top: 98% !important;
  right: 0 !important;
  left: auto !important;

  li {
    width: 100%;
  }
}

/************* nav ***************/
.menu {
  background-color: $them0-color-menu;
  width: $widthLeftMenu;
  height: calc(100vh - #{$heightTopBar});
  transition: all 0.5s ease;
  overflow: hidden;

  .nav {
    .nav-link {
      color: #fff;
      font-size: 15px;
      text-transform: uppercase;
    }

    .nav-item {
      width: 100%;
      padding: 5px 10px;
      border-bottom: solid 1px $them0-color-topbarleft;
      display: flex;
      align-items: center;
      text-decoration: none;
    }

    .nav-item:hover {
      background-color: darken($them0-color-menu, 5%);
    }

    li {
      width: 100%;
    }
  }
}

.badge {
  border-radius: 0;
}

/* Les animations d'entrée (« enter ») et de sortie (« leave »)  */
/* peuvent utiliser différentes fonctions de durée et de temps.  */
.slide-menu-enter-active,
.slide-menu-leave-active {
  transition: all 0.7s ease;
}

.slide-menu-enter-to,
.slide-menu-leave {
  width: $widthLeftMenu;
}

.slide-menu-enter,
.slide-menu-leave-to {
  width: 0;
}

/************ main-part **************/
.main-part {
  flex-grow: 1;
  background-color: #efefef;
  //transition: all 0.5s ease;
  height: calc(100vh - #{$heightTopBar});
  overflow: auto;
  width: 83.333333%;
  padding: 15px 30px;
}

.bg-login {
  background-image: url("/images/plage-kai-pilger.jpeg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
}

/************ footer **************/

.v-select {
  background-color: white;
}
</style>
