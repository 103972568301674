<template>
  <section>
    <div class="row mb-2">
      <div class="col-md-12 d-flex align-items-center justify-content-between">
        <div>
          <h3 class="bis d-flex align-items-center">
            <div class="icon-title d-flex align-items-center justify-content-center">
              <icon width="30"
                height="30"
                name="user-friends"></icon>
            </div>
            <div>
              <div class="d-flex">
                <div>{{ candidats_total }} candidats inscrits en</div>
                <m-form-select :name="$Utils.randomstring('items_years')"
                  :items="$store.state.items_years"
                  style="width:110px"
                  class="ms-2 input-year"
                  :clearable="false"
                  v-model="year"></m-form-select>
                <m-form-combobox :name="$Utils.randomstring('items_centers')"
                  v-model="center"
                  :clearable="false"
                  class="ms-2 input-year"
                  style="width:400px"
                  :store-url="$config.server_url + '/backoffice/1.0/centers'"
                  item-value="ce_id"
                  :item-text="$options.filters.formatCenterName"
                  :store-params="{
                    sort: 'ce_name ASC',
                    onlymine: true,
                  }">
                </m-form-combobox>

                <!--                 <m-form-date
                  class="ms-2"
                  style="width:100px"
                  :name="$Utils.randomstring('annee')"
                  formatInput="YYYY"
                  v-model="year"
                  :year="true"
                  classInput="year-concours"
                ></m-form-date> -->
              </div>
              <div class="fs-6 fw-light">Liste des candidats</div>
            </div>
          </h3>
        </div>
        <div>
          <button :disabled="$dayjs().isAfter($store.state.yearObj.yp_results_date, 'day')
            "
            class="btn btn-primary"
            type="button"
            @click="addCandidat">
            Nouveau
          </button>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between mb-2">
      <div class="frame w-100 me-4 ">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <div class="uppercase fw-bold">Nombre de factures</div>
            <div class="stat-subtext">inscrits</div>
          </div>
          <div class="stat-percent"
            :class="$store.state.stats.invoices.color">
            {{ $store.state.stats.invoices.diff }} %
          </div>
        </div>
        <div class="stat-chiffre">{{ $store.state.stats.invoices.nb }}</div>
      </div>
      <div class="frame w-100 me-4 ">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <div class="uppercase fw-bold">Chiffre d'affaires</div>
            <div class="stat-subtext">du concours {{ $store.state.year }}</div>
          </div>
          <div class="stat-percent"
            :class="$store.state.stats.ca.color">
            {{ $store.state.stats.ca.diff }} %
          </div>
        </div>
        <div class="stat-chiffre">
          {{ ($store.state.stats.ca.nb / 100) | formatCa }}
        </div>
      </div>
      <div class="frame w-100 me-4 ">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <div class="uppercase fw-bold">Non payés</div>
            <div class="stat-subtext">inscrits</div>
          </div>
          <div class="stat-percent"
            :class="$store.state.stats.notPayed.color">
            {{ $store.state.stats.notPayed.diff }} %
          </div>
        </div>
        <div class="stat-chiffre">{{ $store.state.stats.notPayed.nb }}</div>
      </div>
      <div class="frame w-100 ">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <div class="uppercase fw-bold">Nombre de candidats</div>
            <div class="stat-subtext">validés</div>
          </div>
          <div class="stat-percent"
            :class="$store.state.stats.valid.color">
            {{ $store.state.stats.valid.diff }} %
          </div>
        </div>
        <div class="stat-chiffre">{{ $store.state.stats.valid.nb }}</div>
      </div>
    </div>
    <div class="d-flex mb-2">
      <div class="d-flex align-items-end">
        <m-form-text label="Recherche textuelle"
          v-model="filterText"
          :name="$Utils.randomstring('search')"
          autocomplete
          @input="loadDelayCandidats()"></m-form-text>
        <m-form-checkbox class="ms-2"
          :label="getlabel('medaille')"
          :name="$Utils.randomstring('filterMedailles')"
          v-model="filterMedailles"
          @input="loadDelayCandidats()"></m-form-checkbox>
        <m-form-checkbox class="ms-2"
          :label="getlabel('valid')"
          :name="$Utils.randomstring('filterValid')"
          v-model="filterValid"
          @input="loadDelayCandidats()"></m-form-checkbox>
        <m-form-checkbox class="ms-2"
          :label="getlabel('stop')"
          :name="$Utils.randomstring('filterStop')"
          v-model="filterStop"
          @input="loadDelayCandidats()"></m-form-checkbox>
      </div>
    </div>
    <div class="frame">
      <m-list-simple tableClass="table-hover table-striped"
        ref="candidatslist"
        :dynamic="true"
        :items="candidats"
        item-value="pa_id"
        :item-height="30"
        :limit="candidats_limit"
        :skip="candidats_skip"
        :total="candidats_total"
        :cursorOnRows="true"
        @itemclick="goCandidat"
        :urlExportCSV="`${this.$config.server_url}/backoffice/1.0/participations/exportcsv?pa_year=${this.$store.state.year}&pa_candidat=1&token=${this.$store.state.accesstoken}&origin=${this.$config.backoffice_url}`
          ">
        <template v-slot:ths="{ }">
          <th @click="sortTable(['pa_society'])">
            <span class="pointer">Société</span>
          </th>

          <th @click="sortTable(['pa_name', 'pa_firstname'])">
            <span class="pointer">Contact</span>
          </th>
          <th @click="sortTable(['invoices'])"
            style="width:360px">
            <span class="pointer">Factures</span>
          </th>
          <th @click="sortTable(['nb_wines'])"
            class="text-center"
            style="width:150px;">
            <span class="pointer">NB vins fact. / nb vins inscrits</span>
          </th>
          <!-- <th @click="sortTable(['nb_wines'])" class="text-end">
            <span class="pointer">Prix HT</span>
          </th> -->
          <th style="width:50px;"
            @click="sortTable(['stop'])"
            class="text-center">
            <span class="pointer">Stop</span>
          </th>
          <th @click="sortTable(['pa_candidat_valide'])"
            class="text-center">
            <span class="pointer">Validé</span>
          </th>
          <th></th>
        </template>
        <template v-slot:tds="{ item }">
          <td>
            {{ item.pa_society }}
          </td>
          <td>
            {{ $options.filters.formatContactNameSimple(item, "pa_") }}
          </td>
          <td>
            <div v-for="invoice in item.invoices"
              :key="invoice.in_id">
              <div>
                <span @click.stop="printFacture(invoice.in_id)"
                  class="me-1"
                  v-tooltip
                  data-bs-placement="left"
                  :title="`Imprimer la facture numéro ${invoice.in_num}`">
                  <icon :color="invoice.in_type === 'FACTURE' ? '' : '#3eb2dc'"
                    width="15"
                    height="15"
                    name="print"></icon>
                </span>
                <span v-html="showInvoice(invoice)"></span>
              </div>
            </div>
          </td>
          <td class="text-center">
            <span :style="getStyleNbWines(item)">
              {{ item.nb_wines }} / {{ item.nb_winestotal }}
            </span>
          </td>
          <!-- <td class="text-end">
            {{ (item.nb_wines * echantillon_price) | formatPriceDivisePar100 }}
          </td> -->
          <td>
            <div class="text-center"
              v-if="item.stop">
              <img class=""
                style="width:50%"
                src="/images/icons/icon-stop.png"
                alt="icon stop" />
            </div>
          </td>
          <td v-html="$options.filters.formatYesNoColored(item.pa_candidat_valide)
            "
            class="text-center"></td>
          <td>
            <div class="d-flex justify-content-end">
              <div @click.stop="sendAllFacture(item)"
                class="me-3"
                v-tooltip
                data-bs-placement="left"
                title="Envoyer toutes ses factures au candidat">
                <icon color="#3fb1dc"
                  name="envelope"></icon>
              </div>
              <div @click.stop="printAllFacture(item)"
                class="me-3"
                v-tooltip
                data-bs-placement="left"
                title="Imprimer toutes les factures du candidat">
                <icon name="print"></icon>
              </div>
              <div @click.stop="printEtiquette(item)"
                class="me-3"
                v-tooltip
                data-bs-placement="left"
                title="Imprimer l'étiquette de colisage">
                <icon name="print"
                  color="#2aaa2a"></icon>
              </div>
              <div v-if="$Utils.hasRight('canConnectAs')"
                @click.stop="connectLike(item)"
                class="me-3"
                v-tooltip
                data-bs-placement="left"
                title="Se connecter comme">
                <icon name="key"
                  color="#d7a302"></icon>
              </div>
              <!--  <div @click.stop="deleteConfirmCandidat(item)">
                <icon color="#950605" name="trash-alt"></icon>
              </div> -->
            </div>
          </td>
        </template>
      </m-list-simple>
    </div>

    <candidat-jure-win-edit v-model="candidatWinEdit"
      :pa_id="-1"
      @WinEditAction="candidatJureWinEditAction">
    </candidat-jure-win-edit>
    <m-confirm-dialog v-model="confirmdelete"
      text="Souhaitez-vous effacer ce candidat ?"
      title="Confirmation"
      @canceled="confirmdelete = false"
      @confirmed="deleteCandidat()"></m-confirm-dialog>
  </section>
</template>

<script>
export default {
  name: "candidats",
  components: {},
  data() {
    return {
      year: this.$store.state.year,
      center: this.$store.state.center,
      candidats: [],
      candidats_limit: 1000,
      candidats_skip: 0,
      candidatSelected: { pa_id: -1 },
      sortOrder: "asc",
      sortFields: ["pa_society"],
      filterText: "",
      filterMedailles: false,
      filterValid: false,
      filterStop: false,
      candidats_total: 0,
      echantillon_price: 0,
      candidatWinEdit: false,
      delayFilter: null,
      // actions
      confirmdelete: false,
      candidatToDelete: {},
      // nombres
      nbMedaille: 0,
      nbStop: 0,
      nbValid: 0
    };
  },
  async mounted() {
    if (!this.$Utils.hasRight("canContactsAccess")) {
      this.$router.push("/");
    }
    // console.log("this.$store.state.yearObj", this.$store.state.yearObj);
    if (this.$route.name == "candidatsedit")
      this.editCandidat({ pa_id: this.$route.params.id * 1 });
    // if (this.$route.params.page) {
    //   this.currentPage = parseInt(this.$route.params.page);
    //   this.candidats_skip = (this.currentPage - 1) * this.nbPerPage;
    // }
    // this.loadStats();
    await this.loadCandidats(this.candidats_skip);
    // this.$store.dispatch("loadStats");
    // console.log("this.$store.state.year", this.$store.state.year);
  },
  watch: {
    year: async function (val) {
      await this.$store.dispatch("set_year", val);
      this.loadCandidats();
      // this.$store.dispatch("loadStats");
      // this.$store.dispatch("loadPreferences");
      // this.loadStats();
    },
    center: async function (val) {
      await this.$store.dispatch("set_center", val);
      this.loadCandidats();
    }

  },
  computed: {},
  methods: {
    // notInvoiced(item) {
    //   console.log("item.wines", item);
    // },
    getStyleNbWines(item) {
      if (item.nb_wines != item.nb_winestotal) return "color:red;";
      return "";
    },
    getlabel(type) {
      let label = "Uniquement les ";
      if (type === "stop") label += this.nbStop + " stop";
      if (type === "valid") label += this.nbValid + " validés";
      if (type === "medaille") label += this.nbMedaille + " médaillés";
      return label;
    },
    // showInvoices(v) {
    //   // console.log("v", v);
    //   let txt = [];
    //   for (let i = 0; i < v.length; i++) {
    //     const row_in = v[i];
    //     let temp =
    //       "<span class='" + row_in.in_type === "FACTURE"
    //         ? ""
    //         : "text-success" + "'><b>N°" + row_in.in_num + "</b></span>";
    //     temp += " | " + row_in.in_typepaiement;
    //     if (row_in.in_auto) temp += ` ${row_in.in_auto}`;
    //     if (row_in.in_payed)
    //       temp += " | <span class='text-success'>Payé</span>";
    //     txt.push(temp);
    //   }
    //   return txt.join("<br>");
    // },
    showInvoice(row_in) {
      // console.log("v", v);
      let txt = [];
      let temp = `<span class='${row_in.in_type === "FACTURE" ? "" : "text-blue"
        }'><b>N° ${row_in.in_num}</b></span>`;
      if (row_in.in_typepaiement) temp += " | " + row_in.in_typepaiement;
      if (row_in.in_auto && row_in.in_auto != "0") temp += ` ${row_in.in_auto}`;
      let more = row_in.in_type == "AVOIR" ? "-" : "";
      temp += ` | ${more}<span class=''>${this.$options.filters.formatPriceDivisePar100(
        row_in.in_price_ht
      )}</span>`;
      if (row_in.in_payed) temp += " | <span class='text-success'>Payé</span>";
      // if (row_in.in_auto)
      //   temp +=
      //     " | <span class='text-success'>Banque : " +
      //     row_in.in_auto +
      //     "</span>";
      txt.push(temp);

      return txt.join("");
    },
    sortTable(fields) {
      if (
        fields.length &&
        this.sortFields.length &&
        fields[0] == this.sortFields[0]
      ) {
        this.sortOrder = this.sortOrder == "asc" ? "desc" : "asc";
      }
      this.sortFields = fields;
      this.loadCandidats(1);
    },
    goCandidat(row_pa) {
      this.candidatSelected = row_pa;
      this.$router.push("/candidats/" + row_pa.pa_id).catch(err => {});
    },
    /* editCandidat(row_pa) {
      this.candidatSelected = row_pa;
      this.$router.push("/candidats/" + row_pa.pa_id + "/edit");
      this.candidatWinEdit = true;
    }, */
    loadDelayCandidats() {
      if (this.delayFilter) {
        clearTimeout(this.delayFilter);
        this.delayFilter = null;
      }
      this.delayFilter = setTimeout(() => {
        this.loadCandidats();
      }, 300);
    },
    async loadCandidats(skip = 0) {
      // console.log("je  passe");
      let sort = this.sortFields.reduce((accumulator, field) => {
        if (accumulator) accumulator += ", ";
        accumulator += field + " " + this.sortOrder;
        return accumulator;
      }, "");
      let params = {
        text: this.filterText,
        skip: skip,
        limit: this.pagging ? this.nbPerPage : this.candidats_limit,
        sort: sort,
        pa_year: this.$store.state.year,
        ce_id: this.$store.state.center.ce_id,
        pa_candidat: true,
        moreInfos: true
      };
      if (this.filterValid) params.pa_candidat_valide = true;
      if (this.filterMedailles) params.medailles = true;
      if (this.filterStop) params.stop = true;
      this.cancelAxiosLoadCandidats && this.cancelAxiosLoadCandidats();
      let response = await this.$axios.get(
        this.$config.server_url + "/backoffice/1.0/participations",
        {
          params,
          cancelToken: new this.$axios.CancelToken(c => {
            this.cancelAxiosLoadCandidats = c;
          })
        }
      );
      if (!response || !response.data) return;
      this.candidats = response.data.data;
      this.candidats_total = response.data.meta.total;
      this.nbMedaille = response.data.meta.nbMedaille;
      this.nbValid = response.data.meta.nbValid;
      this.nbStop = response.data.meta.nbStop;

      //this.candidats_total = this.candidats.length;
      // this.nbPage = Math.ceil(this.candidats_total / this.nbPerPage);
      this.candidats_skip = skip;

      this.echantillon_price = response.data.meta.echantillon_price;
    },
    addCandidat() {
      this.$router.push("/candidats/-1/new").catch(err => {});
      this.candidatWinEdit = true;
    },
    candidatJureWinEditAction(what) {
      if (what.action === "saved") {
        this.$router.push("/candidats/" + what.row_pa.pa_id).catch(err => {});
      }
      if (what.action === "canceled") {
        this.$router.push("/candidats").catch(err => {});
      }
    },
    async sendAllFacture(item) {
      let response = await this.$axios.get(
        this.$config.server_url +
        "/backoffice/1.0/participations/" +
        item.pa_id +
        "/sendinvoices"
      );
      if (response.data.data.success) {
        this.$store.dispatch("showToast", {
          title: "Candidat " + response.data.data.pa_society,
          text: "Les factures ont bien été envoyées",
          color: "green"
        });
      } else {
        this.$store.dispatch("showToast", {
          title: "Candidat " + response.data.data.pa_society,
          text: response.data.data.error,
          color: "red"
        });
      }
    },
    printFacture(id) {
      window.open(
        `${this.$config.server_url}/backoffice/1.0/invoices/${id}/print?token=${this.$store.state.accesstoken}&origin=${this.$config.backoffice_url}`,
        "_blank"
      );
    },
    printAllFacture(item) {
      window.open(
        `${this.$config.server_url}/backoffice/1.0/participations/${item.pa_id}/printinvoices?token=${this.$store.state.accesstoken}&origin=${this.$config.backoffice_url}`,
        "_blank"
      );
    },
    printEtiquette(item) {
      window.open(
        `${this.$config.server_url}/backoffice/1.0/participations/${item.pa_id}/etiquette?token=${this.$store.state.accesstoken}&origin=${this.$config.backoffice_url}`,
        "_blank"
      );
    },
    async connectLike(item) {
      let data = {
        eventLog: "connectLike",
        comment: this.$store.state.year,
        info:
          item.pa_name + " " + item.pa_firstname + " (" + item.lo_login + ")"
      };
      await this.$axios.post(
        this.$config.server_url + "/backoffice/1.0/addlog/",
        data
      );

      window.open(
        `${this.$config.candidats_url}/autologin?login=${encodeURIComponent(
          item.lo_login
        )}&user=${this.$store.state.user.us_id}&password=${encodeURIComponent(
          "VOUsNePasserezP@@@s!"
        )}&year=${this.$store.state.year}`,
        "_blank"
      );
    }
    /*     deleteConfirmCandidat(item) {
      this.confirmdelete = true;
      this.candidatToDelete = item;
    }, 
    async deleteCandidat() {
      let response = await this.$axios.delete(
        this.$config.server_url +
          "/backoffice/1.0/participations/" +
          this.candidatToDelete.pa_id
      );
      this.confirmdelete = false;
      this.candidatToDelete = {};
      if (response.data.data.success) {
        this.$store.dispatch("showToast", {
          title: "Candidat",
          text:
            "Le candidat " +
            response.data.data.pa_society +
            " a bien été supprimé",
          color: "green"
        });
        this.loadCandidats();
      }
    } */
  }
};
</script>

<style lang="scss" scoped>
</style>
