<template>
  <!--
 remplacer invoice par user
 puis remplacer Invoice par User
 puis remplacer in par us
 -->
  <div class="modal"
    v-if="value"
    :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'
      "
    tabindex="-1"
    role="dialog"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable"
      role="document">
      <div class="modal-content">
        <div class="modal-header d-flex flex-row align-items-center">
          <div>
            <h3 class="modal-title"
              v-if="in_id > 0">
              Modification
              {{
                row_in.in_type === "FACTURE" ? "d'une facture" : "d'un avoir"
              }}
            </h3>
            <h3 class="modal-title"
              v-if="in_id === -1">
              Création d'une fiche
              {{ row_in.in_type === "FACTURE" ? " facture" : " avoir" }}
            </h3>
            <p>
              {{ row_in.in_type === "FACTURE" ? "Facture" : "Avoir" }} N°
              {{ row_in.in_num }}
            </p>
          </div>
        </div>
        <div class="modal-body">
          <div class="frame bg-gray">
            <div class="row">
              <div class="col-md-6">
                <choice-candidat :row_pa="row_pa"
                  @emitChangeCandidat="emitChangeCandidat"
                  @emitEditCandidatJure="emitEditCandidatJure"></choice-candidat>
              </div>
              <div class="col-md-6"></div>
            </div>
          </div>
          <form-invoice :row="row_in"
            prefix="in_"
            @changeInput="emitChangeInputInvoice"></form-invoice>
          <div class="frame">
            <frame-invoice :row_in="row_in"
              :row_pa="row_pa"
              @emitEditWine="editWine"></frame-invoice>
            <!-- <frame-avoir
              v-if="row_in.in_type === 'AVOIR'"
              :row_in="row_in"
              @emitEditWine="editWine"
            ></frame-avoir> -->
          </div>
        </div>
        <div class="modal-footer d-flex flex-row">
          <!--  <button
            type="button"
            class="btn btn-outline-danger"
            :disabled="row_in.in_id ? false : true"
            @click="deleteConfirmWin"
          >
            Supprimer
          </button> -->
          <div class="ms-auto"></div>

          <button type="button"
            class="btn btn-secondary "
            @click="cancelWin">
            Annuler
          </button>
          <button type="button"
            class="btn btn-primary ms-2"
            @click="tryToSaveWin">
            Enregistrer
          </button>
        </div>
      </div>
    </div>
    <m-confirm-dialog v-model="confirmdelete"
      :text="confirmdeleteTxt"
      title="Confirmation"
      @canceled="confirmdelete = false"
      @confirmed="deleteWin()"></m-confirm-dialog>
    <m-message-dialog v-model="dialogErr"
      title="Erreur"
      :text="dialogErrTxt"></m-message-dialog>
    <candidat-jure-win-edit v-model="candidatJureWinEdit"
      :pa_id="row_pa.pa_id"
      @WinEditAction="candidatJureWinEditAction"></candidat-jure-win-edit>
    <wine-win-edit v-model="wineWinEdit"
      :wi_id="wineToEdit.wi_id"
      @WinEditActions="WineWinEditActions">
    </wine-win-edit>
  </div>
</template>

<script>
import FrameInvoice from "./viewscomponents/FrameInvoice.vue";
export default {
  name: "InvoiceWinEdit",
  components: { FrameInvoice },
  props: {
    in_id: [Number, String],
    value: { default: false, type: Boolean },
    routeParent: String
  },
  data() {
    return {
      dialogErr: false,
      dialogErrTxt: "",
      confirmdelete: false,
      confirmdeleteTxt: "",
      row_in: {},
      row_pa: {},
      candidatJureWinEdit: false,
      wineWinEdit: false,
      wineToEdit: {}
    };
  },
  watch: {
    value(v) {
      if (v) {
        this.getInvoice();
      }
    }
  },
  computed: {},
  created() {},
  mounted() {
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },
    async getInvoice() {
      let params = {};
      if (this.in_id == -1) params = {};
      let response = await this.$axios.get(
        this.$config.server_url + "/backoffice/1.0/invoices/" + this.in_id,
        { params }
      );
      this.row_in = response.data.data;
      if (this.row_in.participation) this.row_pa = this.row_in.participation;
    },
    emitChangeCandidat(row_pa) {
      this.pa_id = row_pa.pa_id;
    },
    emitChangeInputInvoice(row_in) {
      this.row_in = row_in;
    },
    emitEditCandidatJure() {
      this.candidatJureWinEdit = true;
    },
    editWine(item) {
      this.wineWinEdit = true;
      this.wineToEdit = item;
    },
    WineWinEditActions(what) {
      if (what.action === "saved" || what.action === "deleted") {
        this.getInvoice();
      }
    },
    tryToSaveWin() {
      let err = [];
      let fieldRequired = [];
      for (let ifi = 0; ifi < fieldRequired.length; ifi++) {
        const field = fieldRequired[ifi];
        if (!this.row_in[field.field]) err.push(field);
      }

      if (err.length) {
        this.dialogErrTxt =
          "<span class='bis'>Les champs suivants sont obligatoires : </span><br>";
        for (let ierr = 0; ierr < err.length; ierr++) {
          const error = err[ierr];
          this.dialogErrTxt += error.text + " <br>";
        }
        this.dialogErr = true;
        return;
      }
      this.saveWin();
    },
    async saveWin() {
      let response;
      let data = {
        participation: this.row_in.participation,
        in_typepaiement: this.row_in.in_typepaiement,
        in_price_ht: this.row_in.in_price_ht,
        in_payed: this.row_in.in_payed,
        in_auto: this.row_in.in_auto,
        in_date: this.row_in.in_date
      };
      if (this.row_in.in_id) {
        response = await this.$axios.put(
          this.$config.server_url +
          "/backoffice/1.0/invoices/" +
          this.row_in.in_id,
          data
        );
      } /* else {
        this.row_in.in_cgu_date = "0000-00-00 00:00:00";
        response = await this.$axios.post(
          this.$config.server_url + "/backoffice/1.0/invoices",
          this.row_in
        );
      } */
      if (response.data.data.success) {
        this.$store.dispatch("showToast", {
          title: "Edition d'un facture",
          text: "La facture a bien été modifiée",
          color: "green"
        });
        this.$emit("emitInvoicesAction");
      }
      if (response.data.err) {
        this.$store.dispatch("showDialogError", response.data.err.message);
        return;
      }
      let row_in = response.data.data;
      this.$emit("input", false);
      this.$emit("winEditAction", { action: "saved", data: row_in });
    },
    cancelWin() {
      this.$emit("input", false);
      this.$emit("winEditAction", { action: "canceled" });
    },
    /*     deleteConfirmWin() {
      this.confirmdelete = true;
      let txt =
        this.row_in.in_type === "FACTURE"
          ? "cette facture ?"
          : "cet avoir ? Cela va revalider les vins de l'avoir.";
      this.confirmdeleteTxt = "Voulez-vous suprimer " + txt;
    }, */
    /*     async deleteWin() {
      await this.$axios.delete(
        this.$config.server_url + "/backoffice/1.0/invoices/" + this.row_in.in_id
      );
      this.confirmdelete = false;
      this.confirmdeleteTxt = "";
      // this.dialog = false;
      this.$emit("input", false);
      this.$emit("winEditAction", { action: "deleted", data: this.row_in });
    }, */
    candidatJureWinEditAction(data) {
      this.getInvoice();
      if (data.action === "saved") {
        this.row_pa = data.row_pa;
        this.row_in.participation = data.row_pa;
      }
    }
  }
};
</script>

<style scoped lang="scss">// .modal {
//   transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
// }
// .active {
//   //min-width: 150px;
//   input {
//     width: auto;
//   }
// }
</style>
