<template>
  <div ref="container">
    <div class="row mb-3">
      <h3 class="bis d-flex align-items-center">
        <div class="icon-title d-flex align-items-center justify-content-center">
          <icon width="30"
            height="30"
            name="cog"></icon>
        </div>
        <div>
          <div class="d-flex">
            <div>
              Préférences pour l'année
            </div>
            <m-form-select :name="$Utils.randomstring('items_years')"
              :items="$store.state.items_years"
              style="width:110px"
              class="ms-2 input-year"
              :clearable="false"
              v-model="year"></m-form-select>
            <!--  <m-form-date
              class="ms-2"
              style="width:100px"
              :name="$Utils.randomstring('year')"
              formatInput="YYYY"
              v-model="year"
              :year="true"
              classInput="year-concours"
            ></m-form-date> -->
          </div>
          <div class="fs-6 fw-light">
            Tout pour l'année du concours
          </div>
        </div>
      </h3>
    </div>
    <div class="frame">
      <div class="row">
        <div class="col-md-4">
          <h4>Dates</h4>
          <m-form-date class="mb-2"
            label="Date ouverture des inscriptions"
            :name="$Utils.randomstring('yp_start_inscription_date')"
            formatInput="DD/MM/YYYY"
            v-model="row_yp.yp_start_inscription_date"></m-form-date>
          <m-form-date class="mb-2"
            label="Date limite inscriptions candidats (jour inclu)"
            :name="$Utils.randomstring('yp_end_inscription_date_candidate')"
            formatInput="DD/MM/YYYY"
            v-model="row_yp.yp_end_inscription_date_candidate"></m-form-date>
          <m-form-date class="mb-2"
            label="Date limite inscriptions jurés (jour inclu)"
            :name="$Utils.randomstring('yp_end_inscription_date_jure')"
            formatInput="DD/MM/YYYY"
            v-model="row_yp.yp_end_inscription_date_jure"></m-form-date>
          <m-form-date class="mb-2"
            label="Date limite envoi échantillons (jour inclu)"
            :name="$Utils.randomstring('yp_end_send_echantillons_date')"
            formatInput="DD/MM/YYYY"
            v-model="row_yp.yp_end_send_echantillons_date"></m-form-date>
          <m-form-date class="mb-2"
            label="Date du concours"
            :name="$Utils.randomstring('yp_start_concours_date')"
            formatInput="DD/MM/YYYY"
            v-model="row_yp.yp_start_concours_date"></m-form-date>
          <m-form-date label="Date résultats"
            :name="$Utils.randomstring('yp_results_date')"
            formatInput="DD/MM/YYYY"
            v-model="row_yp.yp_results_date"></m-form-date>
        </div>
        <div class="col-md-4 offset-md-2">
          <h4>Données</h4>
          <m-form-text class="mb-2"
            label="Tarif échantillon € HT (écrire 5000 pour 50€)"
            type="number"
            :name="$Utils.randomstring('yp_echantillons_price')"
            v-model="row_yp.yp_echantillons_price"></m-form-text>
          <m-form-text class="mb-2"
            label="Tarif macarons (le lot de 10.000 en € HT) (écrire 700 pour 7€)"
            type="number"
            :name="$Utils.randomstring('yp_macarons_price')"
            v-model="row_yp.yp_macarons_price"></m-form-text>
          <m-form-text class="mb-2"
            label="Tva (en %)"
            type="number"
            :name="$Utils.randomstring('yp_echantillons_tva')"
            v-model="row_yp.yp_echantillons_tva"></m-form-text>
          <m-form-text class="mb-2"
            label="Code analytique"
            :name="$Utils.randomstring('yp_analytique_code')"
            v-model="row_yp.yp_analytique_code"></m-form-text>
        </div>
      </div>
    </div>
    <div class="frame">
      <h4>Fichiers</h4>
      <div class="row mb-3 d-flex align-items-end">
        <div class="col-md-5">
          <label class="label">Logo pour l'email (format png)</label>
          <m-form-file class="input-avatar"
            ref="inputFile1"
            id="file_1"
            @inputfile="fileJusteSelected1" />
        </div>

        <div class="col-md-2 d-flex justify-content-center container-file"
          :style="`background-image:url('${filesSelected.file1.binary
            ? filesSelected.file1.binary
            : getFileUrl('yp_file_logo')
            }');`
            ">
          <!--  <img
            class="img-fluid"
            :src="
              filesSelected.file1.binary
                ? filesSelected.file1.binary
                : files.file2.url
            "
            alt="file1"
          /> -->
        </div>
        <div class="col-md-1">
          <m-form-checkbox label="Effacer"
            :name="$Utils.randomstring('deleteFile1')"
            v-model="deleteFile1"></m-form-checkbox>
        </div>
        <div class="col-md-1">
          <div class="label pointer"
            @click="downloadFile('yp_file_logo')">
            Télécharger
          </div>
        </div>
      </div>
      <div class="row mb-3 d-flex align-items-end">
        <div class="col-md-5">
          <label class="label">Macaron Or (format png)</label>
          <m-form-file class="input-avatar"
            ref="inputFile2"
            type="image"
            @inputfile="fileJusteSelected2" />
        </div>
        <div class="col-md-2 d-flex justify-content-center container-file"
          :style="`background-image:url('${filesSelected.file2.binary
            ? filesSelected.file2.binary
            : getFileUrl('yp_file_macaron_or')
            }');`
            "></div>
        <div class="col-md-1">
          <m-form-checkbox label="Effacer"
            :name="$Utils.randomstring('deleteFile2')"
            v-model="deleteFile2"></m-form-checkbox>
        </div>
        <div class="col-md-1">
          <div class="label pointer"
            @click="downloadFile('yp_file_macaron_or')">
            Télécharger
          </div>
        </div>
      </div>
      <div class="row mb-3 d-flex align-items-end">
        <div class="col-md-5">
          <label class="label">Macaron Argent (format png)</label>
          <m-form-file class="input-avatar"
            ref="inputFile3"
            type="image"
            @inputfile="fileJusteSelected3" />
        </div>
        <div class="col-md-2 d-flex justify-content-center container-file"
          :style="`background-image:url('${filesSelected.file3.binary
            ? filesSelected.file3.binary
            : getFileUrl('yp_file_macaron_argent')
            }');`
            "></div>
        <div class="col-md-1">
          <m-form-checkbox label="Effacer"
            :name="$Utils.randomstring('deleteFile3')"
            v-model="deleteFile3"></m-form-checkbox>
        </div>
        <div class="col-md-1">
          <div class="label pointer"
            @click="downloadFile('yp_file_macaron_argent')">
            Télécharger
          </div>
        </div>
      </div>
      <div class="row mb-3 d-flex align-items-end">
        <div class="col-md-5">
          <label class="label">Macaron Excellence / Bronze (format png)</label>
          <m-form-file class="input-avatar"
            ref="inputFile4"
            type="image"
            @inputfile="fileJusteSelected4" />
        </div>
        <div class="col-md-2 d-flex justify-content-center container-file"
          :style="`background-image:url('${filesSelected.file4.binary
            ? filesSelected.file4.binary
            : getFileUrl('yp_file_macaron_excellence')
            }');`
            "></div>
        <div class="col-md-1">
          <m-form-checkbox label="Effacer"
            :name="$Utils.randomstring('deleteFile4')"
            v-model="deleteFile4"></m-form-checkbox>
        </div>
        <div class="col-md-1">
          <div class="label pointer"
            @click="downloadFile('yp_file_macaron_excellence')">
            Télécharger
          </div>
        </div>
      </div>
      <div class="row mb-3 d-flex align-items-end">
        <div class="col-md-5">
          <label class="label">Impression affiche (format pdf)</label>
          <m-form-file class="input-avatar"
            ref="inputFile5"
            type="image"
            @inputfile="fileJusteSelected5" />
        </div>
        <div class="col-md-2 d-flex justify-content-center container-file"
          :style="`background-image:url('${filesSelected.file5.binary
            ? filesSelected.file5.binary
            : getFileUrl('yp_file_affiche')
            }');`
            "></div>
        <div class="col-md-1">
          <m-form-checkbox label="Effacer"
            :name="$Utils.randomstring('deleteFile5')"
            v-model="deleteFile5"></m-form-checkbox>
        </div>
        <div class="col-md-1">
          <div class="label pointer"
            @click="downloadFile('yp_file_affiche')">
            Télécharger
          </div>
        </div>
      </div>
      <!-- certificat -->
      <div class="row mb-3 d-flex align-items-end">
        <div class="col-md-5">
          <label class="label">Certificat (format pdf)</label>
          <m-form-file class="input-avatar"
            ref="inputFile6"
            type="image"
            @inputfile="fileJusteSelected6" />
        </div>
        <div class="col-md-2 d-flex justify-content-center container-file"
          :style="`background-image:url('${filesSelected.file6.binary
            ? filesSelected.file6.binary
            : getFileUrl('yp_file_certificat')
            }');`
            "></div>
        <div class="col-md-1">
          <m-form-checkbox label="Effacer"
            :name="$Utils.randomstring('deleteFile6')"
            v-model="deleteFile6"></m-form-checkbox>
        </div>
        <div class="col-md-1">
          <div class="label pointer"
            @click="downloadFile('yp_file_certificat')">
            Télécharger
          </div>
        </div>
      </div>
      <!-- Fichier d'impression -->
      <div class="row mb-3 d-flex align-items-end">
        <div class="col-md-5">
          <label class="label">Fichier d'impression des macarons (format zip)</label>
          <m-form-file class="input-avatar"
            ref="inputFile7"
            type="image"
            @inputfile="fileJusteSelected7" />
        </div>
        <div class="col-md-2 d-flex justify-content-center container-file"
          :style="`background-image:url('${filesSelected.file7.binary
            ? filesSelected.file7.binary
            : getFileUrl('yp_file_macaron_impression')
            }');`
            "></div>
        <div class="col-md-1">
          <m-form-checkbox label="Effacer"
            :name="$Utils.randomstring('deleteFile7')"
            v-model="deleteFile7"></m-form-checkbox>
        </div>
        <div class="col-md-1">
          <div class="label pointer"
            @click="downloadFile('yp_file_macaron_impression')">
            Télécharger
          </div>
        </div>
      </div>
      <!-- Exemple de communiqué de presse -->
      <div class="row mb-3 d-flex align-items-end">
        <div class="col-md-5">
          <label class="label">Exemple de communiqué de presse (format doc)</label>
          <m-form-file class="input-avatar"
            ref="inputFile8"
            type="image"
            @inputfile="fileJusteSelected8" />
        </div>
        <div class="col-md-2 d-flex justify-content-center container-file"
          :style="`background-image:url('${filesSelected.file8.binary
            ? filesSelected.file8.binary
            : getFileUrl('yp_file_communique_presse')
            }');`
            "></div>
        <div class="col-md-1">
          <m-form-checkbox label="Effacer"
            :name="$Utils.randomstring('deleteFile8')"
            v-model="deleteFile8"></m-form-checkbox>
        </div>
        <div class="col-md-1">
          <div class="label pointer"
            @click="downloadFile('yp_file_communique_presse')">
            Télécharger
          </div>
        </div>
      </div>

      <!-- Règlement du concours -->
      <div class="row mb-3 d-flex align-items-end">
        <div class="col-md-5">
          <label class="label">Règlement du concours (format pdf)</label>
          <m-form-file class="input-avatar"
            ref="inputFile9"
            type="image"
            @inputfile="fileJusteSelected9" />
        </div>
        <div class="col-md-2 d-flex justify-content-center container-file"
          :style="`background-image:url('${filesSelected.file9.binary
            ? filesSelected.file9.binary
            : getFileUrl('yp_file_reglement')
            }');`
            "></div>
        <div class="col-md-1">
          <m-form-checkbox label="Effacer"
            :name="$Utils.randomstring('deleteFile9')"
            v-model="deleteFile9"></m-form-checkbox>
        </div>
        <div class="col-md-1">
          <div class="label pointer"
            @click="downloadFile('yp_file_reglement')">
            Télécharger
          </div>
        </div>
      </div>

      <!-- Palmarès -->
      <div class="row mb-3 d-flex align-items-end">
        <div class="col-md-5">
          <label class="label">Palmarès - résultats (format pdf)</label>
          <m-form-file class="input-avatar"
            ref="inputFile10"
            type="image"
            @inputfile="fileJusteSelected10" />
        </div>
        <div class="col-md-2 d-flex justify-content-center container-file"
          :style="`background-image:url('${filesSelected.file10.binary
            ? filesSelected.file10.binary
            : getFileUrl('yp_file_palmares')
            }');`
            "></div>
        <div class="col-md-1">
          <m-form-checkbox label="Effacer"
            :name="$Utils.randomstring('deleteFile10')"
            v-model="deleteFile10"></m-form-checkbox>
        </div>
        <div class="col-md-1">
          <div class="label pointer"
            @click="downloadFile('yp_file_palmares')">
            Télécharger
          </div>
        </div>
      </div>
    </div>
    <div class="frame">
      <h4>Emails automatiques</h4>
      <div class="row mt-3">
        <div class="col-md-6">
          <m-form-text class="mb-2"
            label="FROM - Uniquement le nom (pas l'email)"
            :name="$Utils.randomstring('yp_email_from')"
            v-model="row_yp.yp_email_from"></m-form-text>
          <m-form-text class="mb-2"
            label="Email d'inscription candidat"
            :name="$Utils.randomstring('yp_email_inscription_canditate')"
            v-model="row_yp.yp_email_inscription_canditate"
            type="textarea"
            :rows="4"></m-form-text>
          <m-form-text class="mb-2"
            label="Email de facture candidat"
            :name="$Utils.randomstring('yp_email_invoice')"
            v-model="row_yp.yp_email_invoice"
            type="textarea"></m-form-text>
          <m-form-text class="mb-2"
            label="Email d'avoir candidat"
            :name="$Utils.randomstring('yp_email_avoir')"
            v-model="row_yp.yp_email_avoir"
            type="textarea"></m-form-text>
          <m-form-text class="mb-2"
            label="Email d'inscription juré"
            :name="$Utils.randomstring('yp_email_inscription_jure')"
            v-model="row_yp.yp_email_inscription_jure"
            type="textarea"></m-form-text>
          <m-form-text class="mb-2"
            label="Email de validation juré"
            :name="$Utils.randomstring('yp_email_validation_jure')"
            v-model="row_yp.yp_email_validation_jure"
            type="textarea"></m-form-text>
        </div>
        <div class="col-md-6">
          <!-- <m-form-text
            class="mb-2"
            label="FROM commande macarons"
            :name="$Utils.randomstring('yp_email_from_macaron')"
            v-model="row_yp.yp_email_from_macaron"
            disabled
          ></m-form-text> -->
          <m-form-text label="COPIE factures"
            :name="$Utils.randomstring('yp_email_copy')"
            v-model="row_yp.yp_email_copy"
            subText="Séparez les emails par des virgules sans espaces"></m-form-text>
          <m-form-text label="COPIE commande macarons"
            :name="$Utils.randomstring('yp_email_copy_macaron')"
            v-model="row_yp.yp_email_copy_macaron"
            subText="Séparez les emails par des virgules sans espaces"></m-form-text>
          <m-form-text class="mb-2"
            label="Email de création de la commande de macarons"
            :name="$Utils.randomstring('yp_email_creation_commande_macaron')"
            v-model="row_yp.yp_email_creation_commande_macaron"
            type="textarea"></m-form-text>
          <m-form-text label="Email de réception de la commande de macarons"
            :name="$Utils.randomstring('yp_email_reception_commande_macaron')"
            v-model="row_yp.yp_email_reception_commande_macaron"
            type="textarea"></m-form-text>
          <m-form-text label="Email d'envoi des macarons"
            :name="$Utils.randomstring('yp_email_envoie_macaron')"
            v-model="row_yp.yp_email_envoie_macaron"
            type="textarea"></m-form-text>
        </div>
        <div class="col-md-12">
          <help-variables></help-variables>
        </div>
      </div>
    </div>
    <div class="frame">
      <div class="d-flex justify-content-between">
        <h4>Tableau des millesimes {{ year }}</h4>
        <div class="d-flex">
          <m-form-file class="input-avatar"
            ref="inputFile100"
            @inputfile="fileJusteSelectedMilesime" />
          <button class="btn btn-primary ms-2"
            @click="saveMilesimes('temp')">
            Visualiser
          </button>
          <button class="btn btn-primary ms-2"
            @click="exportMilesimes">
            Exporter
          </button>
        </div>
      </div>
      <div class="mt-3">
        <m-list-simple tableClass="table-striped"
          ref="milesimeslist"
          :dynamic="true"
          :items="milesimes"
          item-value="de_id"
          :item-height="30"
          :limit="1000"
          :skip="0"
          :total="milesimes_total">
          <template v-slot:ths="{ }">
            <th @click="sortTable(['de_name'])">
              <span class="pointer">Dénomination</span>
            </th>

            <th v-for="color in $store.state.items_winesColors"
              :key="color.value">
              <span> {{ color.text }}</span>
            </th>

            <th></th>
          </template>
          <template v-slot:tds="{ item }">
            <td>{{ item.de_name }}</td>
            <td v-for="color in $store.state.items_winesColors"
              :key="color.value">
              <!-- {{ item["de_" + color.value].yearsTxt }} -->
              {{
                item["de_" + color.value] && item["de_" + color.value].years
                ? item["de_" + color.value].years.join(", ")
                : ""
              }}
            </td>
          </template>
        </m-list-simple>
      </div>
    </div>
    <div class="frame">
      <h4>Informations de paiement</h4>
      <div class="row mt-3">
        <div class="col-md-6">
          <m-form-text-markdown label="Informations de paiement par virement"
            :name="$Utils.randomstring('yp_infos_payment_virement')"
            v-model="row_yp.yp_infos_payment_virement"></m-form-text-markdown>
        </div>
        <div class="col-md-6">
          <m-form-text-markdown label="Informations de paiement par chèque"
            :name="$Utils.randomstring('yp_infos_payment_cheque')"
            v-model="row_yp.yp_infos_payment_cheque"></m-form-text-markdown>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-6">
          <m-form-text-markdown label="Paiement par CB : retour banque accepté"
            :name="$Utils.randomstring('yp_infos_payment_cb_ok')"
            v-model="row_yp.yp_infos_payment_cb_ok"></m-form-text-markdown>
        </div>
        <div class="col-md-6">
          <m-form-text-markdown label="Paiement par CB : retour banque annulé ou erreur"
            :name="$Utils.randomstring('yp_infos_payment_cb_ko')"
            v-model="row_yp.yp_infos_payment_cb_ko"></m-form-text-markdown>
        </div>
      </div>
    </div>
    <div class="frame">
      <h4>Adresse de livraison et colisage suivant la dénomination</h4>
    </div>
    <div class="frame">
      <div @click="showScript = !showScript">
        <h4>Contenances minimum des vins suivant la dénomination</h4>
      </div>
      <div v-if="showScript">
        <m-form-text type="textarea"
          :rows="10"
          label="script"
          style="font-family: monospace;"
          :name="$Utils.randomstring('yp_script_contenance_min')"
          v-model="row_yp.yp_script_contenance_min"></m-form-text>
      </div>
    </div>
    <!-- footer -->
    <div class="row mt-5">
      <div class="col-md-12 d-flex justify-content-end">
        <div>
          <button @click="cancelPreferences"
            class="btn btn-secondary">
            Annuler
          </button>
          <button @click="savePreferences"
            class="btn btn-primary ms-2">
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "PreferenceYear",
  components: {},
  data() {
    return {
      year: this.$store.state.year,
      row_yp: {},
      filesSelected: {
        file1: { image: null, binary: null },
        file2: { image: null, binary: null },
        file3: { image: null, binary: null },
        file4: { image: null, binary: null },
        file5: { image: null, binary: null },
        file6: { image: null, binary: null },
        file7: { image: null, binary: null },
        file8: { image: null, binary: null },
        file9: { image: null, binary: null },
        file10: { image: null, binary: null }
      },
      deleteFile1: false,
      deleteFile2: false,
      deleteFile3: false,
      deleteFile4: false,
      deleteFile5: false,
      deleteFile6: false,
      deleteFile7: false,
      deleteFile8: false,
      deleteFile9: false,
      deleteFile10: false,
      correspondanceNumFiles: {
        1: "yp_file_logo",
        2: "yp_file_macaron_or",
        3: "yp_file_macaron_argent",
        4: "yp_file_macaron_excellence",
        5: "yp_file_affiche",
        6: "yp_file_certificat",
        7: "yp_file_macaron_impression",
        8: "yp_file_communique_presse",
        9: "yp_file_reglement",
        10: "yp_file_palmares"
      },
      forcereload: 0,
      /// milesimes
      fileMilesime: {},
      milesimes: [],
      milesimes_total: 0,
      showScript: false
    };
  },
  watch: {
    year: async function (val) {
      await this.$store.dispatch("set_year", val);
      this.resetFiles();
      this.loadPreferences();
      this.loadMilesimes();
    }
  },
  async mounted() {
    if (!this.$Utils.hasRight("canPreferencesAccess")) {
      this.$router.push("/");
    }
    this.loadPreferences();
    this.loadMilesimes();
  },
  methods: {
    async loadPreferences() {
      let response = await this.$axios.get(
        this.$config.server_url +
        "/backoffice/1.0/yearpreferences/" +
        this.$store.state.year
      );
      if (!response || !response.data) return;
      this.row_yp = response.data.data;
      // console.log("this.row_yp", this.row_yp);
      if (!this.row_yp.yp_colisage) this.row_yp.yp_colisage = [];
    },
    async loadMilesimes(save = "save") {
      this.cancelAxios && this.cancelAxios();
      let params = {
        de_year: this.$store.state.year,
        de_save: save === "temp" ? 0 : 1
      };
      let response = await this.$axios.get(
        this.$config.server_url + "/backoffice/1.0/Denominations/",
        { params },
        {
          cancelToken: new this.$axios.CancelToken(c => {
            this.cancelAxios = c;
          })
        }
      );
      if (!response || !response.data) return;
      this.milesimes = response.data.data;
      this.milesimes_total = response.data.meta.total;
    },
    exportMilesimes() {
      window.open(
        `${this.$config.server_url}/backoffice/1.0/denominations/export/millesime/${this.$store.state.year}?token=${this.$store.state.accesstoken}&origin=${this.$config.backoffice_url}`,
        "_blank"
      );
    },
    async downloadFile(keyfile) {
      window.open(
        `${this.$config.server_url}/commons/1.0/files/${keyfile}/${this.$store.state.year}?origin=${this.$config.backoffice_url}&user=${this.$store.state.user.us_id}`
      );
    },
    fileJusteSelected1(files) {
      this.fileJusteSelected(files, "file1");
    },
    fileJusteSelected2(files) {
      this.fileJusteSelected(files, "file2");
    },
    fileJusteSelected3(files) {
      this.fileJusteSelected(files, "file3");
    },
    fileJusteSelected4(files) {
      this.fileJusteSelected(files, "file4");
    },
    fileJusteSelected5(files) {
      this.fileJusteSelected(files, "file5");
    },
    fileJusteSelected6(files) {
      this.fileJusteSelected(files, "file6");
    },
    fileJusteSelected7(files) {
      this.fileJusteSelected(files, "file7");
    },
    fileJusteSelected8(files) {
      this.fileJusteSelected(files, "file8");
    },
    fileJusteSelected9(files) {
      this.fileJusteSelected(files, "file9");
    },
    fileJusteSelected10(files) {
      this.fileJusteSelected(files, "file10");
    },
    fileJusteSelectedMilesime(files) {
      this.fileMilesime = files[0];
    },
    fileJusteSelected(files, num) {
      // console.log("files, num", files, num);
      if (!files.length) return;
      this.filesSelected[num].image = files[0];
      let reader = new FileReader();
      let that = this;
      reader.onload = (function (theFile) {
        return function (e) {
          that.filesSelected[num].binary = e.target.result;
          /* that.files[num].url = e.target.result; */
        };
      })(files[0]);
      reader.readAsDataURL(files[0]);
    },
    async saveMilesimes(save) {
      if (!this.fileMilesime || !this.fileMilesime.name) return;
      // console.log('this.fileMilesime', this.fileMilesime);
      let formData = new FormData();
      formData.append("file", this.fileMilesime, this.fileMilesime.name);
      await this.$axios.post(
        this.$config.server_url +
        "/backoffice/1.0/denominations/import/milesime/" +
        save +
        "/" +
        this.year,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
      await this.loadMilesimes(save);
    },
    async savePreferences() {
      for (let i = 1; i <= 10; i++) {
        if (this.filesSelected["file" + i].image) {
          // console.log("file name", this.filesSelected["file" + i].image.name);
          this.row_yp[this.correspondanceNumFiles[i]] = this.filesSelected[
            "file" + i
          ].image.name;
        }
        if (this["deleteFile" + i]) {
          this.row_yp[this.correspondanceNumFiles[i]] = "";
          this["deleteFile" + i] = false;
        }
      }

      let response = await this.$axios.put(
        this.$config.server_url +
        "/backoffice/1.0/yearpreferences/" +
        this.row_yp.yp_id,
        this.row_yp
      );
      if (response.data.err) {
        this.$store.dispatch("showDialogError", response.data.err.message);
        return;
      }
      // vérifier s'il faut enregistrer ou supprimer des fichiers (files et millesime)
      this.forcereload++;
      for (let i = 1; i <= 10; i++) {
        if (this.filesSelected["file" + i].image && !this["deleteFile" + i]) {
          await this.saveFiles(this.filesSelected["file" + i].image, i);
          this.getFileUrl(this.correspondanceNumFiles[i]);
        }
      }
      if (this.fileMilesime) await this.saveMilesimes("save");

      this.$store.dispatch("showToast", {
        title: this.row_yp.yp_year,
        text: "Les préférences ont bien été enregistrées",
        color: "#2aaa2a"
      });
      this.$store.dispatch("loadPreferences");
      //this.resetFiles();
      //this.loadPreferences();
    },
    async saveFiles(file, num) {
      if (!file) return;
      let formData = new FormData();
      formData.append("file", file, file.name);
      await this.$axios.post(
        this.$config.server_url +
        "/backoffice/1.0/files/" +
        this.correspondanceNumFiles[num] +
        "/" +
        this.year,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
      this.$refs["inputFile" + num].reset();
      this.filesSelected["file" + num] = { image: null, binary: null };
    },
    getFileUrl(key) {
      // this.forcereload++;
      let res = `${this.$config.server_url}/commons/1.0/files/${key}/${this.year}?icon=true&forcereload=${this.forcereload}`;
      return res;
    },

    editColisage() {},
    cancelPreferences() {
      this.resetFiles();
      this.loadPreferences();
      window.scrollTo(0, 0);
    },
    resetFiles() {
      this.filesSelected = {
        file1: { image: null, binary: null },
        file2: { image: null, binary: null },
        file3: { image: null, binary: null },
        file4: { image: null, binary: null },
        file5: { image: null, binary: null },
        file6: { image: null, binary: null },
        file7: { image: null, binary: null },
        file8: { image: null, binary: null },
        file9: { image: null, binary: null },
        file10: { image: null, binary: null }
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.container-file {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 60px;
}
</style>
