<template>
  <div class="help">
    <button class="btn btn-primary btn-sm"
      @click="showHelpVariables = !showHelpVariables">
      Ajouter des variables dans l'email
    </button>
    <div v-if="showHelpVariables"
      class="mt-2">
      <table class="table table-bordered table-striped table-small">
        <thead class="thead-light">
          <tr>
            <th class="text-center">Texte à insérer</th>
            <th class="text-center">Affichage</th>
            <th class="text-center">Résultat (exemple)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ openMustache }}completeName{{ closeMustache }}</td>
            <td>Nom complet du destinataire</td>
            <td>Mme DUPONT Marie</td>
          </tr>
          <tr>
            <td>{{ openMustache }}name{{ closeMustache }}</td>
            <td>Nom du destinataire en majuscule</td>
            <td>DUPONT</td>
          </tr>
          <tr>
            <td>{{ openMustache }}firstname{{ closeMustache }}</td>
            <td>Prénom du destinataire</td>
            <td>Marie</td>
          </tr>
          <tr>
            <td>{{ openMustache }}civility{{ closeMustache }}</td>
            <td>Civilité du destinataire</td>
            <td>Mme</td>
          </tr>
          <tr>
            <td>{{ openMustache }}email{{ closeMustache }}</td>
            <td>Email et login du candidat</td>
            <td>marie956@dupont.fr</td>
          </tr>
          <!--  <tr>
            <td>{{ openMustache }}echantillons_price{{ closeMustache }}</td>
            <td>prix d'un échantillon</td>
            <td>50€</td>
          </tr> -->
          <tr>
            <td>{{ openMustache }}society{{ closeMustache }}</td>
            <td>Société du destinataire</td>
            <td>Vignoble du sud-ouest</td>
          </tr>
          <tr>
            <td>{{ openMustache }}phones{{ closeMustache }}</td>
            <td>téléphone mobile et fixe du destinataire</td>
            <td>tel mobile : 0600000000 tel fixe : 0494000000</td>
          </tr>
          <tr>
            <td>
              {{ openTripleMustache }}adresse_livraison{{ closeTripleMustache }}
            </td>
            <td>
              Adresse de livraison du destinataire (que pour les emails
              concernant une commande macarons)
            </td>
            <td>
              1 rue de la paix <br />
              Bât 1 <br />
              01000 Paris
            </td>
          </tr>
          <tr>
            <td>
              {{ openTripleMustache }}adresse_facturation{{
                closeTripleMustache
              }}
            </td>
            <td>
              Adresse de facturation du destinataire (que pour les emails
              concernant une commande macarons)
            </td>
            <td>
              23 rue de Victor Hugo <br />
              Bât 4 <br />
              01000 Toulouse
            </td>
          </tr>
          <tr>
            <td>{{ openMustache }}date_start_inscription{{ closeMustache }}</td>
            <td>Date de début des inscriptions</td>
            <td>15/12/2020</td>
          </tr>
          <tr>
            <td>
              {{ openMustache }}date_end_inscription_candidat{{ closeMustache }}
            </td>
            <td>Date de fin des inscriptions candidats</td>
            <td>15/01/2021</td>
          </tr>
          <tr>
            <td>
              {{ openMustache }}date_end_inscription_jure{{ closeMustache }}
            </td>
            <td>Date de fin des inscriptions jurés</td>
            <td>20/01/2021</td>
          </tr>
          <tr>
            <td>{{ openMustache }}date_concours{{ closeMustache }}</td>
            <td>Date du concours</td>
            <td>02/02/2021</td>
          </tr>
          <!--           <tr>
            <td>{{ openMustache }}date_end{{ closeMustache }}</td>
            <td>Date de fin du concours</td>
            <td>06/02/2021</td>
          </tr> -->
          <tr>
            <td>{{ openMustache }}date_result{{ closeMustache }}</td>
            <td>Date des résultats</td>
            <td>10/02/2021</td>
          </tr>
          <tr>
            <td>{{ openMustache }}macarons_price{{ closeMustache }}</td>
            <td>Prix du macaron</td>
            <td>10€</td>
          </tr>
          <tr>
            <td>
              {{ openTripleMustache }}wines{{ closeTripleMustache }} <br />
              (trois acolades)
            </td>
            <td>
              La liste des vins présentés par le candidat suivi de la liste des
              factures et des avoirs
            </td>
            <td>
              <div>Rappel de votre inscription :</div>
              <ul class="mt-2">
                <li>
                  Nom : Nom du vin <br />
                  Dénomination : Gaillac <br />
                  Couleur : rouge <br />
                  Millesime : 2019 <br />
                  Cépages : cépages <br />
                  Référence du contenant : blabbla <br />
                  Volume total du lot : 500 hl <br />
                </li>
              </ul>
              <div>Vos factures et avoirs :</div>
              <ul class="mt-2">
                <li>
                  FACTURE numéro : 2100137<br />
                  Nombre de vin(s) présenté(s) : 1<br />
                  Prix total HT : 50€<br />
                  TVA : 10€<br />
                  Total TTC : 60€<br />
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              {{ openTripleMustache }}wines_avoir{{ closeTripleMustache }}
              <br />
              (trois acolades)
            </td>
            <td>
              La liste des avoirs avec la liste des vins dans chaque avoir
            </td>
            <td>
              <div>Liste des vins en avoir :</div>
              <ul class="mt-2">
                <li>
                  AVOIR numéro : 2100137<br />
                  <ul class="mt-2">
                    <li>
                      Nom : Nom du vin <br />
                      Dénomination : Gaillac <br />
                      Couleur : rouge <br />
                      Millesime : 2019 <br />
                    </li>
                  </ul>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              {{ openTripleMustache }}order{{ closeTripleMustache }} <br />
              (trois acolades)
            </td>
            <td>
              détail de la commande avec le nombre de macaron commandé dans
              chaque couleur de médaille, le sens d'enroulement et le mandrin et
              les prix.
            </td>
            <td>
              <div>Rappel de votre commande :</div>
              <br />
              <div>Macarons or : 20000<br /></div>
              <br />
              <div>
                Sens d'enroulement : droite<br />
                Mandrin : 56mm<br />
              </div>
              <br />
              <div>
                Prix des 1000 macarons : 10€<br />
                Prix total HT : 200€ <br />
                Prix de la TVA : 20€ <br />
                Prix total TTC : 220€ <br />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "helpVariables",
  components: {},
  props: {},
  data() {
    return {
      showHelpVariables: false,
      openMustache: "{{",
      closeMustache: "}}",
      openTripleMustache: "{{{",
      closeTripleMustache: "}}}"
    };
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {},
  destroyed() {},
  methods: {}
};
</script>

<style scoped lang="scss">
.help {
  border: 1px solid #eaeaea;
  padding: 5px;
}
</style>
