<template>
  <section v-if="value">
    <div class="row">
      <div class="col-md-6">
        <m-form-text label="Ville"
          type="text"
          v-model="search.co_city"
          :name="$Utils.randomstring('city')"></m-form-text>
      </div>
      <div class="col-md-6">
        <m-form-text label="Ville"
          type="text"
          v-model="search.co_city"
          :name="$Utils.randomstring('city')"></m-form-text>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-6">
        <m-form-text label="Ville"
          type="text"
          v-model="search.co_city"
          :name="$Utils.randomstring('city')"></m-form-text>
      </div>
      <div class="col-md-6">
        <m-form-text label="Ville"
          type="text"
          v-model="search.co_city"
          :name="$Utils.randomstring('city')"></m-form-text>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12 d-flex justify-content-end">
        <button class="btn btn-secondary"
          @click="cancel">Annuler</button>
        <button class="btn btn-primary ms-2"
          @click="searchGo">
          Rechercher
        </button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "contactadvancedsearch",
  components: {},
  props: {
    value: Boolean,
    gr_id: [Number, String]
  },
  data() {
    return {
      search: {},
      results: []
    };
  },
  async mounted() {},
  watch: {
    /* value: function(val) {
      if (val) {
      } 
    }*/
  },
  computed: {},
  methods: {
    async searchGo() {
      this.search.saveInGroup = this.gr_id;
      let response = await this.$axios.get(
        this.$config.server_url + "/backoffice/1.0/contacts",
        { params: this.search }
      );
      if (!response || !response.data) return;
      this.results = response.data.data;
      this.$emit("searchLoaded");
    },
    cancel() {
      this.$emit("canceled");
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
