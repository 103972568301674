<template>
  <div>
    <m-list-simple tableClass="table-hover table-striped"
      ref="centerslist"
      :dynamic="true"
      :items="centers"
      item-value="ce_id"
      :item-height="30"
      :limit="centers_limit"
      :skip="centers_skip"
      :total="centers_total"
      :pagging="pagging"
      :nbPage="nbPage"
      :nbPerPage="nbPerPage"
      :currentPage="currentPage"
      :cursorOnRows="true"
      :urlExportCSV="`${this.$config.server_url}/backoffice/1.0/centers/exportcsv?token=${this.$store.state.accesstoken}&origin=${this.$config.backoffice_url}`
        "
      @itemclick="editCenter"
      @changePage="changePage">
      <template v-slot:ths="{ }">
        <th style="min-width:200px;"
          @click="sortCenters(['ce_name'])">
          <span class="pointer">Nom</span>
        </th>
        <th @click="sortCenters(['ce_address1'])">
          <span class="pointer">Adresse</span>
        </th>
        <th @click="sortCenters(['ce_zip'])">
          <span class="pointer">Ville</span>
        </th>
        <th style="min-width:130px;"
          @click="sortCenters(['ce_active'])">
          <span class="pointer">Statut</span>
        </th>
      </template>
      <template v-slot:tds="{ item }">
        <td>
          {{ item.ce_name }}
        </td>
        <td>{{ item.ce_address1 }} - {{ item.ce_address2 }}</td>
        <td>
          {{ item.ce_zip }}
        </td>
        <td>
          <span :class="item.ce_active ? 'color-green' : 'color-red'">{{ item.ce_active ? "Actif" : "Non-actif"
          }}<br /></span>
        </td>
      </template>
    </m-list-simple>
    <center-win-edit v-model="centerWinEdit"
      :centerType="centersType"
      :ce_id="centerSelected.ce_id"
      @saved="centerWinEditAction"
      @deleted="centerWinEditAction"
      @canceled="centerWinEditAction">
    </center-win-edit>
  </div>
</template>
<script>
export default {
  name: "center-list",
  components: {},
  props: {
    filterText: String,
    loadCentersGo: Number,
    showNoActive: Boolean
  },
  data() {
    return {
      centersType: this.$route.meta.type,
      centers_limit: 100,
      centers_skip: 0,
      centers_total: 0,
      centers: [],
      reloadkey_avatar_list: 0,
      centerWinEdit: false,
      centerSelected: {},
      sortOrder: "asc",
      sortFields: ["ce_name"],
      nbPage: 0,
      nbPerPage: 100,
      pagging: true,
      currentPage: 1
    };
  },
  async mounted() {
    if (this.$route.name == "adminsedit") {
      this.editCenter({ ce_id: this.$route.params.id * 1 });
    }

    if (this.$route.params.page) {
      this.currentPage = parseInt(this.$route.params.page);
      this.centers_skip = (this.currentPage - 1) * this.nbPerPage;
    }
    //this.loadCenters(this.centers_skip);
  },
  watch: {
    $route(to, from) {
      this.centersType = this.$route.meta.type;
      /* if (this.$route.params.page) {
        this.currentPage = parseInt(this.$route.params.page);
        this.centers_skip = (this.currentPage - 1) * this.nbPerPage;
      } */
    },
    filterText() {
      if (this.delayFilterText) window.clearTimeout(this.delayFilterText);
      this.delayFilterText = window.setTimeout(() => {
        this.loadCenters(0);
      }, 300);
    },
    loadCentersGo(v) {
      this.loadCenters(this.centers_skip);
    }
  },
  methods: {
    sortCenters(fields) {
      if (
        fields.length &&
        this.sortFields.length &&
        fields[0] == this.sortFields[0]
      ) {
        this.sortOrder = this.sortOrder == "asc" ? "desc" : "asc";
      }
      this.sortFields = fields;
      if (fields[0] !== "ce_connexion_date") {
        this.changePage(1);
      } else {
        this.centers.sort((a, b) => {
          if (this.sortOrder === "asc") {
            if (
              (!a.signin_lastdate || !a.signin_lastdate.ac_date) &&
              (b.signin_lastdate || b.signin_lastdate.ac_date)
            )
              return -1;
            if (
              (a.signin_lastdate || a.signin_lastdate.ac_date) &&
              (!b.signin_lastdate || !b.signin_lastdate.ac_date)
            )
              return 1;
            if (
              a.signin_lastdate.ac_date === "0000-00-00" ||
              this.$dayjs(a.signin_lastdate.ac_date).isBefore(
                b.signin_lastdate.ac_date
              )
            )
              return -1;
            if (
              b.signin_lastdate.ac_date === "0000-00-00" ||
              this.$dayjs(a.signin_lastdate.ac_date).isAfter(
                b.signin_lastdate.ac_date
              )
            )
              return 1;
            if (
              (a.signin_lastdate.ac_date === "0000-00-00" &&
                b.signin_lastdate.ac_date === "0000-00-00") ||
              this.$dayjs(a.signin_lastdate.ac_date)
                .startOf("day")
                .isSame(b.signin_lastdate.ac_date)
            )
              return 0;
          } else {
            if (
              (a.signin_lastdate || a.signin_lastdate.ac_date) &&
              (!b.signin_lastdate || !b.signin_lastdate.ac_date)
            )
              return -1;
            if (
              (!a.signin_lastdate || !a.signin_lastdate.ac_date) &&
              (b.signin_lastdate || b.signin_lastdate.ac_date)
            )
              return 1;
            if (
              a.signin_lastdate.ac_date === "0000-00-00" ||
              this.$dayjs(a.signin_lastdate.ac_date).isBefore(
                b.signin_lastdate.ac_date
              )
            )
              return 1;
            if (
              b.signin_lastdate.ac_date === "0000-00-00" ||
              this.$dayjs(a.signin_lastdate.ac_date).isAfter(
                b.signin_lastdate.ac_date
              )
            )
              return -1;
            if (
              (a.signin_lastdate.ac_date === "0000-00-00" &&
                b.signin_lastdate.ac_date === "0000-00-00") ||
              this.$dayjs(a.signin_lastdate.ac_date)
                .startOf("day")
                .isSame(b.signin_lastdate.ac_date)
            )
              return 0;
          }
        });
      }
    },

    async loadCenters(skip) {
      this.reloadkey_avatar_list++;
      // let types = this.$store.state.centersTypes[this.centersType].types;
      let sort = this.sortFields.reduce((accumulator, field) => {
        if (accumulator) accumulator += ", ";
        accumulator += field + " " + this.sortOrder;
        return accumulator;
      }, "");
      let params = {
        text: this.filterText,
        // types: types,
        skip: skip,
        limit: this.pagging ? this.nbPerPage : this.centers_limit,
        // archive: this.showNoActive,
        showall: true,
        sort: sort,
        signindate: true
      };
      this.cancelAxiosLoadCenters && this.cancelAxiosLoadCenters();
      let response = await this.$axios.get(
        this.$config.server_url + "/backoffice/1.0/centers",
        {
          params,
          cancelToken: new this.$axios.CancelToken(c => {
            this.cancelAxiosLoadCenters = c;
          })
        }
      );
      if (!response || !response.data) return;
      this.centers = response.data.data;
      this.centers_total = response.data.meta.total;
      this.nbPage = Math.ceil(this.centers_total / this.nbPerPage);
      this.centers_skip = skip;
      if (this.$refs.centerslist) {
        // this.$refs.centerslist.update();
        // if (skip == 0) this.$refs.centerslist.scrollToTop();
      }
      this.$emit("centerLoaded", this.centers_total);
    },
    changePage(currentPage) {
      this.currentPage = currentPage;
      this.$router
        .push("/" + this.centersType + "/page/" + this.currentPage)
        .catch(err => {});
      let newSkip = (currentPage - 1) * this.nbPerPage;
      this.loadCenters(newSkip);
    },
    editCenter(row_ce) {
      this.centerSelected = row_ce;
      this.$router
        .push("/" + this.centersType + "/" + row_ce.ce_id + "/edit")
        .catch(err => {});
      this.centerWinEdit = true;
    },
    centerWinEditAction(ce_id) {
      this.$router.push("/" + this.centersType).catch(err => {});
      //this.loadCenters(0);
      this.changePage(this.currentPage);
    }
  }
};
</script>
<style lang="scss" scoped>
.color-green {
  color: #52c131;
}

.color-red {
  color: red;
}
</style>
