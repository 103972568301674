<template>
  <div>
    <div class="frame">
      <h4>Paiement</h4>
      <div class="d-flex align-items-end">
        <m-form-select style="width:260px;"
          label="Moyen de paiement"
          :name="$Utils.randomstring('in_payment')"
          v-model="row[prefix + 'typepaiement']"
          :items="$store.state.items_paiement_way"
          @input="changeInput2"></m-form-select>
        <m-form-text class="ms-2"
          type="number"
          label="Somme HT"
          :name="$Utils.randomstring('in_price_ht')"
          v-model="totalHT"
          @input="changeInput2"></m-form-text>
        <m-form-date class="ms-2"
          label="Le (JJ/MM/AAAA)"
          :name="$Utils.randomstring('in_payed_date')"
          formatInput="DD/MM/YYYY"
          v-model="row.in_payed_date"
          @input="changeInput2"
          :disabled="false"></m-form-date>
        <m-form-checkbox class="ms-4 me-2 mb-1"
          label="Payé"
          :name="$Utils.randomstring('in_payed')"
          v-model="row[prefix + 'payed']"
          @input="changeInput2"></m-form-checkbox>
        <m-form-text class="ms-2"
          label="N° banque"
          name="in_auto"
          v-model="row[prefix + 'auto']"
          @input="changeInput2"></m-form-text>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormInvoice",
  components: {},
  props: {
    row: Object,
    prefix: String,
    keyload: Number
  },
  data() {
    return {
      totalHT: 0
    };
  },
  watch: {
    keyload(val) {},
    row() {
      this.totalHT = this.row[this.prefix + "price_ht"] / 100;
    }
  },
  async mounted() {
    this.totalHT = this.row[this.prefix + "price_ht"] / 100;
  },
  methods: {
    changeInput2(val) {
      this.row[this.prefix + "price_ht"] = this.totalHT * 100;
      this.$emit("changeInput", this.row);
    }
  }
};

// function calculContenanceMin(denominations, row_wi) {
//   let contenance_min = 50000000;
//   let denomination = denominations.find(de => {
//     return de.de_id === row_wi.de_id;
//   });
//   if (!denomination) return contenance_min;

//   if (denomination.de_name.indexOf("IGP") > -1) {
//     if (row_wi.wi_couleur !== 5 && row_wi.wi_couleur !== 8)
//       contenance_min = 75000000;
//     if (row_wi.wi_couleur === 5) contenance_min = 20000000;
//     if (
//       denomination.de_name.indexOf("Gascogne") > -1 &&
//       row_wi.wi_couleur === 5
//     )
//       contenance_min = 20000000;
//   }
//   if (denomination.de_name.indexOf("IGP") < 0) {
//     if (denomination.de_name.indexOf("Gascogne") < 0 && row_wi.wi_couleur === 5)
//       contenance_min = 20000000; // AOP blanc doux
//     if (denomination.de_name.indexOf("Gascogne") < 0 && row_wi.wi_couleur === 7)
//       contenance_min = 10000000; // AOP mousseux
//     if (denomination.de_name.indexOf("Gascogne") < 0 && row_wi.wi_couleur === 8)
//       contenance_min = 10000000; // AOP vendange tardive
//     if (denomination.de_name.indexOf("Gascogne") > -1)
//       contenance_min = 10000000; // AOP floc de Gascogne
//   }

//   return contenance_min;
// }
</script>

<style lang="scss" scoped>
</style>
