<template>
  <div class="modal"
    v-if="value"
    :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'
      "
    tabindex="-1"
    role="dialog"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable"
      role="document">
      <div class="modal-content">
        <div class="modal-header d-flex flex-row align-items-center">
          <div>
            <h3 class="modal-title"
              v-if="pa_id > 0">
              Modification fiche jure
            </h3>
            <h3 class="modal-title"
              v-if="pa_id === -1">
              Création d'une fiche jure
            </h3>
            <p>Seuls les champs avec une astérisque sont obligatoires</p>
          </div>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12">
                  <h5 class="bis d-flex align-items-center">
                    <span class="number-title">1</span>Utilisateur
                  </h5>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <m-form-text label="Nom *"
                    type="text"
                    v-model="row_pa.pa_name"
                    :name="$Utils.randomstring('pa_name')"
                    :rules="[$Validation.mandatory]"></m-form-text>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex flex-row">
          <button type="button"
            class="btn btn-outline-danger"
            :disabled="row_pa.pa_id ? false : true"
            @click="deleteConfirmWin">
            Supprimer
          </button>
          <div class="ms-auto"></div>

          <div class="form-check">
            <label class="form-check-label"
              for="active">Actif</label>
            <input class="form-check-input"
              type="checkbox"
              name="pa_active"
              v-model="row_pa.pa_active" />
          </div>

          <button type="button"
            class="btn btn-secondary "
            @click="cancelWin">
            Annuler
          </button>
          <button type="button"
            class="btn btn-primary ms-2"
            @click="tryToSaveWin">
            Enregistrer
          </button>
        </div>
      </div>
    </div>
    <m-confirm-dialog v-model="confirmdelete"
      text="Souhaitez-vous effacer ce jure ?"
      title="Confirmation"
      @canceled="confirmdelete = false"
      @confirmed="deleteWin()"></m-confirm-dialog>
    <m-message-dialog v-model="dialogErr"
      title="Erreur"
      :text="dialogErrTxt"></m-message-dialog>
  </div>
</template>

<script>
export default {
  name: "XxWinEdit",
  components: {},
  props: {
    pa_id: Number,
    value: { default: false, type: Boolean }
  },
  data() {
    return {
      dialogErr: false,
      dialogErrTxt: "",
      rulesPassword: {
        nbCharacteres: 8,
        specialCharactere: true,
        uppercase: true,
        number: true
      },
      confirmdelete: false,
      row_pa: { pa_rights: {}, pa_type: "" },
      password1: "",
      isValidPassword: false,
      errPassword: []
    };
  },
  watch: {
    value(v) {
      if (v) {
        this.getJure();
      }
    }
  },
  computed: {},
  created() {},
  mounted() {
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },
    async getJure() {
      let params = { getacl: true };
      if (this.pa_id == -1) params = {};
      let response = await this.$axios.get(
        this.$config.server_url + "/backoffice/1.0/jures/" + this.pa_id,
        { params }
      );
      let row_pa = response.data.data;
      // console.log("response.data", response.data);
      // console.log("row_pa", row_pa);
      this.row_pa = row_pa;
    },

    tryToSaveWin() {
      let err = [];
      let fieldRequired = [{ field: "pa_name", text: "Nom" }];
      for (let ifi = 0; ifi < fieldRequired.length; ifi++) {
        const field = fieldRequired[ifi];
        if (!this.row_pa[field.field]) err.push(field);
      }

      if (err.length) {
        this.dialogErrTxt =
          "<span class='bis'>Les champs suivants sont obligatoires : </span><br>";
        for (let ierr = 0; ierr < err.length; ierr++) {
          const error = err[ierr];
          this.dialogErrTxt += error.text + " <br>";
        }
        this.dialogErr = true;
        return;
      }
      this.saveWin();
    },
    async saveWin() {
      let response;
      if (this.row_pa.pa_id) {
        response = await this.$axios.put(
          this.$config.server_url +
          "/backoffice/1.0/jures/" +
          this.row_pa.pa_id,
          this.row_pa
        );
      } else {
        this.row_pa.pa_cgu_date = "0000-00-00 00:00:00";
        response = await this.$axios.post(
          this.$config.server_url + "/backoffice/1.0/jures",
          this.row_pa
        );
      }
      if (response.data.err) {
        this.$store.dispatch("showDialogError", response.data.err.message);
        return;
      }
      let row_pa = response.data.data;
      this.$emit("input", false);
      this.$emit("saved", row_pa);
    },
    cancelWin() {
      this.$emit("input", false);
      this.$emit("canceled", this.row_pa);
    },
    deleteConfirmWin() {
      this.confirmdelete = true;
    },
    async deleteWin() {
      await this.$axios.delete(
        this.$config.server_url + "/backoffice/1.0/jures/" + this.row_pa.pa_id
      );
      this.confirmdelete = false;
      // this.dialog = false;
      this.$emit("input", false);
      this.$emit("deleted", this.row_pa);
    }
  }
};
</script>

<style scoped lang="scss">// .modal {
//   transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
// }
// .active {
//   //min-width: 150px;
//   input {
//     width: auto;
//   }
// }
</style>
