<template>
  <section>
    <div class="row mb-3">
      <h3 class="bis d-flex align-items-center">
        <div class="icon-title d-flex align-items-center justify-content-center">
          <icon width="30"
            height="30"
            name="bolt"></icon>
        </div>
        <div>
          <div>Liste des {{ center_total }} interprofessions</div>
          <div class="fs-6 fw-light">
            Les interprofessions
          </div>
        </div>
      </h3>
    </div>
    <div class="d-flex align-items-end justify-content-between mb-2">
      <div>
        <m-form-text label="Recherche textuelle"
          v-model="filterText"
          :name="$Utils.randomstring('search')"
          autocomplete
          @input="loadCenters()"></m-form-text>
      </div>
      <div>
        <button class="btn btn-primary"
          type="button"
          @click="addCenters">
          Nouveau
        </button>
      </div>
    </div>
    <div class="frame">
      <centers-list ref="centersList"
        :filterText="filterText"
        :showNoActive="showNoActive"
        :loadCentersGo="loadCentersGo"
        @centerLoaded="centerLoaded" />
    </div>

    <center-win-edit v-model="centerWinNew"
      :ce_id="-1"
      @close="centerWinNew = false"
      @saved="newcenterWinEditAction"
      @deleted="newcenterWinEditAction"
      @canceled="newcenterWinEditAction">
    </center-win-edit>
  </section>
</template>

<script>
export default {
  name: "centers",
  components: {},
  data() {
    return {
      center: null,
      filterText: "",
      center_total: 0,
      loadCentersGo: 0,
      centersType: this.$route.meta.type,
      centerWinNew: false,
      itemsTypes: [],
      showNoActive: false
    };
  },
  async mounted() {
    if (!this.$Utils.hasRight("canCentersAccess")) {
      this.$router.push("/");
    }
    this.center = this.$store.state.center;
    await this.loadCenters(0);
  },
  watch: {
    $route(to, from) {
      this.centersType = this.$route.meta.type;
    }
  },
  computed: {
    titre() {
      if (!this.$store.state.centersTypes[this.centersType]) return;
      let titre = this.center_total + " ";
      titre += this.$store.state.centersTypes[this.centersType].title;
      if (this.center_total > 1) titre += "s";
      return titre;
    }
  },
  methods: {
    loadCenters() {
      this.loadCentersGo++;
    },
    centerLoaded(total) {
      this.center_total = total;
    },
    addCenters() {
      this.$router.push("/" + this.centersType + "/-1/edit").catch(err => {});
      this.centerWinNew = true;
    },
    newcenterWinEditAction(co_id) {
      this.$router.push("/" + this.centersType).catch(err => {});
      this.loadCenters();
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
